import { PaginationQuery } from "../pagination/pagination-query"

export type DesignatedFacilityQuery = PaginationQuery & {
    FacilityNameTerm: string,
    CityVersionIdTerm: number,
    HealthAuthorityId: number,
    HealthRegionId: number,
    Status: string,
}

export function defaultDesignatedFacilityFilterValues(): DesignatedFacilityQuery {
    return {
        FacilityNameTerm: "", 
        CityVersionIdTerm: 0, 
        Status: "TRUE", 
        HealthAuthorityId: 0,
        HealthRegionId: 0, 
        OrderByDirection: "", 
        OrderByProperty: "",
        PageNumber: 0,
        PageSize: 25
    }
}