import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { IntakeFormComponent } from './app/components/intake-form/intake-form.component';

// bootstrapApplication(IntakeFormComponent,{
//   providers: [
//     provideAnimations()
//   ]
// })
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
