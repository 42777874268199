import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmailMessage } from '../components/email-queue/email-message';
import { EmailQueueQuery } from '../components/email-queue/email-queue-query';
import { AuthService } from './auth.service';
import { CaseFileService } from './case-file.service';

@Injectable({
  providedIn: 'root'
})
export class EmailQueueService {
  private emailQueueUrl = environment.apiUrl + 'EmailQueue/';
  private emailQueueViewUrl = this.emailQueueUrl + 'View/';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private _authservice: AuthService,private caseFileService:CaseFileService) { }

  getEmailQueryParams(filter: EmailQueueQuery): HttpParams {
    return new HttpParams({ fromObject: filter })
  }

  getAllEmailQueueList(qryFilter: EmailQueueQuery): Observable<any> {
    const token = this._authservice.getAuthToken();
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    let param = this.getEmailQueryParams(qryFilter)
    param=param.append("timeZone",this.caseFileService.timeZoneName);
    return this.http.get<EmailMessage[]>(this.emailQueueUrl, { headers: headers, params: param, observe: "response" });
  }

  getEmailQueueView(emailMessageId: number): Observable<EmailMessage> {
    const token = this._authservice.getAuthToken();
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    let param = new HttpParams();
    param = param.append("emailMessageId",emailMessageId);
    param = param.append("timeZone",this.caseFileService.timeZoneName)
    return this.http.get<EmailMessage>(this.emailQueueViewUrl, { headers: headers,params:param });
  }

}