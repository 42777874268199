<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container">
    <h1>Facilities Administration</h1>
  </div>
</div>
<div class="container">
  <div class="content" role="main">

    <div class="createButton form-buttons">
      <h2 class="admin-subheading">Facilities</h2>
      <a routerLink="/addfacility" class="submit-form-button white-bg"><strong>+</strong> Add Facility</a>
    </div>
    <app-designated-facility-list></app-designated-facility-list>
  </div>
</div>

<app-footer></app-footer>