import { Injectable } from "@angular/core";
import { ApplicationRoles } from "../users/user-roles";

export enum CaseFileStatus {
  Create = 0,
  ForReview = 1,
  RAAssigned = 2,
  ClosedCaseIntake = 3,
  ClosedCaseRA = 4
}

@Injectable({
  providedIn: 'root'
})

export class CaseFileStatusEnumDescriptionService {
  private descriptions: { [key: number]: string } = {
    [CaseFileStatus.Create]: 'Select',
    [CaseFileStatus.ForReview]: 'For Review',
    [CaseFileStatus.RAAssigned]: 'RA Assigned',
    [CaseFileStatus.ClosedCaseIntake]: 'Closed-CMHA',
    [CaseFileStatus.ClosedCaseRA]: 'Closed-RA'
  };

  getDescription(enumValue: number): string {
    return this.descriptions[enumValue] || 'Unknown';
  }

  getDescriptionForFilters(enumValue: any, userrole: ApplicationRoles): string {
    return this.descriptions[enumValue] || 'Unknown';
  }
}