import { UserDetails, defaultUserDetails } from "../users/userdetail";
export interface RightsAdvisor {
    rightsAdvisorVersionId: number;
    advisorName: string;
    advisorEmail: string;
    advisorHealthRegionVersionId: number;
    healthRegionName: string;
    isActive: boolean;
    userDetails: UserDetails;
    status:string;
    openCaseCount:number;
    closeCaseCount:number;
}

export function defaultValues(): RightsAdvisor {
    const defaultUserInfo: UserDetails = defaultUserDetails();
    const defaultRightsAdvisor: RightsAdvisor = {
        rightsAdvisorVersionId: 0, advisorName: "", advisorEmail: "", advisorHealthRegionVersionId: 0, healthRegionName: "", userDetails: defaultUserInfo, isActive: true,status:'',
        openCaseCount:0,closeCaseCount:0
    }

    return defaultRightsAdvisor;
}