<div class="container">
    <div class="issuenewlink">
        <span>
            In certain circumstances, an intake coordinator can issue a new secure link to a mental health facility in
            order for them to access
            the completed Request for Rights Advice in Facility form in PDF file format.
        </span>
        <div class="form-buttons">
            <div class="container">
                <button type="button" class="submit-form-button" (click)="issueNewPDF()">Issue new link</button>
            </div>
            
        </div>
    </div>
</div>>