import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailMessage } from 'src/app/components/email-queue/email-message';
import { EmailQueueService } from 'src/app/services/email-queue-service';

@Component({
  selector: 'app-email-queue-view',
  templateUrl: './email-queue-view.component.html',
  styleUrls: ['./email-queue-view.component.scss']
})

export class EmailQueueViewComponent implements OnInit {
  constructor(
    private emailQueueService: EmailQueueService,
    private route: ActivatedRoute
  ) { }

  emailMessageId: number = 0;
  emailMessage: EmailMessage | undefined;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.emailMessageId = params['id'];
    });
    this.emailQueueService.getEmailQueueView(this.emailMessageId).subscribe({
      next: (response) => {
        this.emailMessage = response;
      },
      error: (errorMessage) => {
        console.log('Error:' + errorMessage)
      },
      complete: () => { }
    });
  }
}
