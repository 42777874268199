import { Component, Input } from '@angular/core';
import { DesignatedFacility, defaultValues } from '../designated-facility';
import { DesignatedFacilityAuditLog } from '../designated-facility-audit-logs';
import { AuditEventTypeEnumDescriptionService } from '../../case-file/case-file-audit-log-list/audti-event-type';
import { DatePipe } from '@angular/common';
import { DesignatedFacilityService } from 'src/app/services/designated-facility.service';

@Component({
  selector: 'app-designated-facility-audit-log-list',
  templateUrl: './designated-facility-audit-log-list.component.html',
  styleUrls: ['./designated-facility-audit-log-list.component.scss']
})
export class DesignatedFacilityAuditLogListComponent {
  @Input() facilityVersionId: number = 0;

  auditlogVisible: boolean = false;
  dfAuditLogs: DesignatedFacilityAuditLog[] = [];
  
  columnsToDisplay = ['User', 'Date','Event', 'Details']

  constructor(public enumdescservice:AuditEventTypeEnumDescriptionService,public datePipe:DatePipe,public _dfService:DesignatedFacilityService){}

  ngOnInit(): void {
    this._dfService.getDFAuditLogs(this.facilityVersionId).subscribe({
      next: (res) => {
        this.dfAuditLogs = res;
        this.auditlogVisible = res.length>0
      }
    })
  }
  checkIfValidDate(inputString: string): string {
    const date = new Date(inputString);
    // Check if the date is valid and inputString does not contain non-numeric characters
    if(!isNaN(date.getTime()) && /^\d/.test(inputString))
    {
      return this.datePipe.transform(inputString,'dd/MM/yyyy hh:mm')?.toString()!
    }
    else
    {
return inputString;
    }
  }

}
