export interface Login {
    userDbId: number;
    username: string,
    password: string,
    email: string,
    agentName: String,
}

export function defaultLoginValues(): Login {
    return <Login>{
        userDbId: 0,
        username: '',
        password: '',
        email: '',
        agentName: ''
    }
}
