<div class="form-section-header">
  <div class="container">
    <h3><em>Section 4 -</em>ADDITIONAL INFORMATION TO BE COMPLETED BY RIGHTS ADVISOR</h3>
  </div>
</div>
<div class="form-subsection-header">
  <div class="container">
    <h4>Additional Information</h4>
  </div>
</div>
<div class="form-subsection-content">
  <div class="container">
    <fieldset>
      <label id="label-raaddinfomeetingstatus">Meeting Status</label>
    </fieldset>
    <hr />

    <fieldset>
      <mat-radio-group name="raaddinfomeetingstatusGroup" aria-labelledby="label-raaddinfomeetingstatus"
        class="intake-form-radio-group" [(ngModel)]="caseFile.raAdditionalInformationMeetingStatusVersionId"
        [disabled]="isDisabled" (change)="rightsAdvisorMeetingStatusChange()">
        <mat-radio-button name="raaddinfomeetingstatus" class="intake-form-radio-button"
          *ngFor="let ms of raAdditionalInfomeetingStatus" [value]="ms.id">
          {{ms.raAdditionalInfoMeetingStatusName}}
        </mat-radio-button>
        <mat-error
					*ngIf="parentSubmitted && caseFile.raAdditionalInformationMeetingStatusVersionId == null">Please
					select one</mat-error>
      </mat-radio-group>
    </fieldset>

    <div [hidden]="!this.collectAllTheMeetingInformation">
      <fieldset>
        <label id="label-appointment-length">Appointment Length</label>
      </fieldset>
      <hr />
      <fieldset ngForm #appointmentLengthForm="ngForm">
        <mat-radio-group name="radAppointmentLenthGroup" aria-labelledby="label-appointment-length"
          class="intake-form-radio-group" [(ngModel)]="caseFile.appointmentLengthVersionId" [disabled]="isDisabled">
          <mat-radio-button name="radAppointmentLenth" class="intake-form-radio-button"
            *ngFor="let al of appointmentLength" [value]="al.id">
            {{al.appointmentLengthTime}}
          </mat-radio-button>
          <mat-error
					*ngIf="parentSubmitted && collectAllTheMeetingInformation && caseFile.appointmentLengthVersionId == null">Please
					select one</mat-error>
        </mat-radio-group>
      </fieldset>

      <fieldset>
        <label id="form-of-meeting">Form of meeting</label>
      </fieldset>
      <hr />
      <fieldset>
        <mat-radio-group name="rightsAdvisorFormOfMeeting" aria-labelledby="form-of-meeting"
          class="intake-form-radio-group" [(ngModel)]="caseFile.rightsAdvisorAdditionalInformationMeetingFormatVersionId" [disabled]="isDisabled">
          <mat-radio-button name="rightsAdvisorFormOfMeeting" class="intake-form-radio-button"
            *ngFor="let mf of meetingFormats" [value]="mf.id">
            {{ reformatMeetingName(mf.meetingFormatName) }}
          </mat-radio-button>
          <mat-error
					*ngIf="parentSubmitted && collectAllTheMeetingInformation && caseFile.rightsAdvisorAdditionalInformationMeetingFormatVersionId == null">Please
					select one</mat-error>
        </mat-radio-group>
      </fieldset>

      <fieldset>
        <label id="label-meetingAccompaniments">Accompaniment. Select all that apply:</label>
      </fieldset>
      <hr />
      <fieldset class="display-vertical">
        <div class="rfi-section-3-checkbox-display-vertical">
        <div *ngFor="let ma of meetingAccompaniments" class="with-width">
          <label>
            <input type="checkbox" [value]="ma" (change)="onMACheckboxChange(ma)" class="intake-form-radio-button"
              [checked]="isItemChecked(ma,caseFile.meetingAccompaniments)" [disabled]="isDisabled">
            {{ ma.meetingAccompanimentsName }}
          </label>
        </div>
      </div>
      </fieldset>
      <div class="clear"></div>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>If Other, Please specify</mat-label>
          <input matInput [(ngModel)]="caseFile.meetingAccompanimentsOther" class="form-control-textbox display-vertical"
            [disabled]="maOthersDisabled" name="maothers" />
        </mat-form-field>
      </fieldset>

      <fieldset>
        Interpreter or communication services provided as requested. Select all that apply: (optional)
      </fieldset>
      <hr />
      <fieldset class="full-length">
        
        <div *ngFor="let interpreterService of providedInterpreterServices">
          <label>
            <input type="checkbox" [value]="interpreterService" (change)="onProvidedInterpreterCheckboxChange(interpreterService)" class="intake-form-radio-button"
              [checked]="isItemChecked(interpreterService, caseFile.providedInterpreterServices)" [disabled]="isDisabled">
            {{ interpreterService.name }}
          </label>
          
          <div *ngIf="interpreterService.name === 'Interpreter' && providedInterpreterServicesShowLanguagePicker" style="margin-top: 1em; margin-left: 1em;" class="with-width">
            <mat-form-field>
              <mat-label>Select Language</mat-label>
              <mat-select name="providedInterpreterInterpretationLanguage" [(ngModel)]="caseFile.providedInterpretationLanguageVersionId"
                placeholder="Select One" [disabled]="isDisabled">
              <mat-option *ngFor="let il of interpretationLanguages" [value]="il.id">{{ il.interpretationLanguage }}</mat-option>
            </mat-select>
            </mat-form-field>
          </div>
        </div>
     
      </fieldset>
      <div class="clear"></div>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>If Other, Please specify</mat-label>
          <input matInput [(ngModel)]="caseFile.providedInterpreterServicesOther" class="form-control-textbox display-vertical"
            [disabled]="providedInterpreterServicesOtherDisabled" name="providedInterpreterServiceOther" />
        </mat-form-field>
      </fieldset>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Notes (optional)</mat-label>
          <textarea matInput class="form-control-textbox w-100" name="providedInterpreterServiceNotes"
            [(ngModel)]="caseFile.providedInterpreterServicesNotes" [disabled]="isDisabled"
            placeholder="Please provide some notes"></textarea>
        </mat-form-field>
      </fieldset>

      <fieldset>
        Did the person experience difficulty in accessing Rights Advice?
      </fieldset>
      <hr />
      <fieldset>
        <mat-radio-group name="radRAAdviceDifficulty" aria-labelledby="label-appointment-length"
          class="intake-form-radio-group" [(ngModel)]="caseFile.difficultyAccessingRightsAdvice"
          [disabled]="isDisabled">
          <mat-radio-button name="radRAAdviceDifficultyYes" class="intake-form-radio-button" [value]="true">
            Yes
          </mat-radio-button>
          <mat-radio-button name="radRAAdviceDifficultyNo" class="intake-form-radio-button" [value]="false">
            No
          </mat-radio-button>
          <mat-error
					*ngIf="parentSubmitted && collectAllTheMeetingInformation && caseFile.difficultyAccessingRightsAdvice == null">Please
					select one</mat-error>
        </mat-radio-group>
      </fieldset>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Notes (optional)</mat-label>
          <textarea matInput class="form-control-textbox w-100" name="daranotes"
            [(ngModel)]="caseFile.difficultyAccessingRightsAdviceText" [disabled]="isDisabled"></textarea>
        </mat-form-field>
      </fieldset>

      <fieldset>
        Were there unmet accommodation needs (ex. meeting accompaniment, interpreter, increased length of appointment)?<br />
        Select all that apply: (optional)
      </fieldset>
      <hr />
      <fieldset class="full-length">
        <div *ngFor="let unmetNeed of unmetAccommodationNeeds">
          <label>
            <input type="checkbox" [value]="unmetNeed" (change)="onUnmetAccommodationCheckboxChange(unmetNeed)" class="intake-form-radio-button"
              [checked]="isItemChecked(unmetNeed, caseFile.unmetAccommodationNeeds)" [disabled]="isDisabled">
            {{ unmetNeed.name }}
          </label>
        </div>
      </fieldset>
      <div class="clear"></div>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Notes (optional)</mat-label>
          <textarea matInput class="form-control-textbox w-100" name="unmetAccommodationNeedNotes"
            [(ngModel)]="caseFile.unmetAccommodationNeedNotes" [disabled]="isDisabled"
            placeholder="Please provide some notes"></textarea>
        </mat-form-field>
      </fieldset>

      <fieldset>
        Was there an appointment connection issue? Select all that apply: (optional)
      </fieldset>
      <hr />
      <fieldset class="full-length">
        <div *ngFor="let connectionIssue of appointmentConnectionIssues">
          <label>
            <input type="checkbox" [value]="connectionIssue" (change)="onAppointmentConnectionCheckboxChange(connectionIssue)" class="intake-form-radio-button"
              [checked]="isItemChecked(connectionIssue, caseFile.appointmentConnectionIssues)" [disabled]="isDisabled">
            {{ connectionIssue.name }}
          </label>
        </div>
      </fieldset>
      <div class="clear"></div>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>If Other, Please specify</mat-label>
          <input matInput [(ngModel)]="caseFile.appointmentConnectionIssueOther" class="form-control-textbox"
            [disabled]="appointmentConnectionIssueOtherDisabled" name="appointmentConnectionIssueOthersection4ra" />
        </mat-form-field>
      </fieldset>

      <fieldset>
        Service user restraints/seclusion (seclusion, mechanical restraints, chemical restraint). Select all that apply (optional)
      </fieldset>
      <hr />
      <fieldset class="full-length">
        <div *ngFor="let restraint of restraintOrSeclusions">
          <label>
            <input type="checkbox" [value]="restraint" (change)="onRestraintCheckboxChange(restraint)" class="intake-form-radio-button"
              [checked]="isItemChecked(restraint, caseFile.restraintOrSeclusions)" 
              [disabled]="isDisabled">
            {{ restraint.name }}
          </label>
        </div>
      </fieldset>
      <div class="clear"></div>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>If Other, Please specify</mat-label>
          <input matInput [(ngModel)]="caseFile.restraintOrSeclusionsOther" class="form-control-textbox"
            [disabled]="restraintOthersDisabled" name="restraintOtherssection4ra" />
        </mat-form-field>
      </fieldset>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Notes (optional)</mat-label>
          <textarea matInput class="form-control-textbox w-100" name="restraintOrSeclusionsNotessection4ra"
            [(ngModel)]="caseFile.restraintOrSeclusionsNotes" [disabled]="isDisabled"
            placeholder="Please provide some notes"></textarea>
        </mat-form-field>
      </fieldset>

      <fieldset>
        Was the RA able to provide the rights advice required?
      </fieldset>
      <hr />
      <fieldset>
        <mat-radio-group name="desiredAdviceProvidedsection4ra" aria-labelledby="label-appointment-length"
          class="intake-form-radio-group" [(ngModel)]="caseFile.desiredAdviceProvided"
          [disabled]="isDisabled">
          <mat-radio-button name="desiredAdviceProvidedYes" class="intake-form-radio-button" [value]="true">
            Yes
          </mat-radio-button>
          <mat-radio-button name="desiredAdviceProvidedNo" class="intake-form-radio-button" [value]="false">
            No
          </mat-radio-button>
          <mat-error
					*ngIf="parentSubmitted && collectAllTheMeetingInformation && caseFile.desiredAdviceProvided == null">Please
					select one</mat-error>
        </mat-radio-group>
      </fieldset>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Notes (optional)</mat-label>
          <textarea matInput class="form-control-textbox w-100" name="daranotessection4ra"
            [(ngModel)]="caseFile.desiredAdviceProvidedNotes" [disabled]="isDisabled"></textarea>
        </mat-form-field>
      </fieldset>

      <fieldset>
        What assistance was provided related to rights under the Mental Health Act? Select all that apply: (optional)
      </fieldset>
      <hr />
      <div class="clear"></div>
      <fieldset class="full-length">
        <div class="rfi-section-3-checkbox-display-vertical">
        <div *ngFor="let ap of assistanceProvided" class="with-width">
          <label>
            <input type="checkbox" [value]="ap" [checked]="isItemChecked(ap,caseFile.assistanceProvided)"
              (change)="onAPCheckboxChange(ap)" class="intake-form-radio-button" [disabled]="isDisabled">
            {{ ap.assistanceProvidedName }}
          </label>
        </div>
      </div>
      </fieldset>
      <div class="clear"></div>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>If Other, Please specify</mat-label>
          <input matInput [(ngModel)]="caseFile.assistanceProvidedOther" class="form-control-textbox"
            [disabled]="apOthersDisabled" name="apothers" />
        </mat-form-field>
      </fieldset>

      <fieldset>
        Were other referrals to community resources made? Select all that apply: (optional)
      </fieldset>
      <hr>
      <fieldset class="full-length">
        <div class="rfi-section-3-checkbox-display-vertical">
        <div *ngFor="let rm of referralsMade" class="with-width">
          <label>
            <input type="checkbox" [value]="rm" [checked]="isItemChecked(rm,caseFile.referralsMade)"
              (change)="onRMCheckboxChange(rm)" class="intake-form-radio-button" [disabled]="isDisabled">
            {{ rm.referralsMadeOptions }}
          </label>
        </div>
        </div>

      </fieldset>
      <div class="clear"></div>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>If Other, Please specify</mat-label>
          <input matInput [(ngModel)]="caseFile.referralsMadeOther" class="form-control-textbox"
            [disabled]="rmOthersDisabled" name="rmOtherssection4ra" />
        </mat-form-field>
      </fieldset>

      <fieldset>
        Did the person need assistance that you were not able to provide? Select all that apply: (optional)
      </fieldset>
      <hr>
      <fieldset class="full-length">
        <div class="rfi-section-3-checkbox-display-vertical">
        <div *ngFor="let rnm of referralsNotMade" class="with-width">
          <label>
            <input type="checkbox" [value]="rnm" [checked]="isItemChecked(rnm, caseFile.referralsNotMade)"
              (change)="onRNMCheckboxChange(rnm)" class="intake-form-radio-button" [disabled]="isDisabled">
            {{ rnm.referralsNotMadeOption }}
          </label>
        </div>
        </div>
      </fieldset>
      <div class="clear"></div>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>If Other, Please specify</mat-label>
          <input matInput [(ngModel)]="caseFile.referralsNotMadeOther" class="form-control-textbox"
            [disabled]="rnmOthersDisabled" name="rnmOtherssection4ra" />
        </mat-form-field>
      </fieldset>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Notes (optional)</mat-label>
          <textarea matInput class="form-control-textbox w-100" name="referralsnotes"
            [(ngModel)]="caseFile.referralNotes" [disabled]="isDisabled"></textarea>
        </mat-form-field>
      </fieldset>

      <fieldset>
        Is a follow up needed?
      </fieldset>
      <hr />
      <fieldset>
        <mat-radio-group name="radRAfollowupsection4ra" class="intake-form-radio-group" [(ngModel)]="caseFile.followUp"
          [disabled]="isDisabled">
          <mat-radio-button name="radRAfollowupYes" class="intake-form-radio-button" [value]="true">
            Yes
          </mat-radio-button>
          <mat-radio-button name="radRAfollowupNo" class="intake-form-radio-button" [value]="false">
            No
          </mat-radio-button>
          <mat-error
					*ngIf="parentSubmitted && collectAllTheMeetingInformation && caseFile.followUp == null">Please
					select one</mat-error>
        </mat-radio-group>
      </fieldset>
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Notes (optional)</mat-label>
          <textarea matInput class="form-control-textbox w-100" name="facilitynotessection4ra" [(ngModel)]="caseFile.followUpText"
            [disabled]="isDisabled"></textarea>
        </mat-form-field>
      </fieldset>
    </div>

    <div [hidden]="this.hideCancellationReason">
      <fieldset>
        Was a reason provided?
      </fieldset>
      <hr />
      <fieldset ngForm #cancelRAReason="ngForm">
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Reason</mat-label>
          <textarea matInput class="form-control-textbox w-100" name="cancellationranotessection4ra"
            [(ngModel)]="caseFile.rightsAdvisorReasonForCancellation" [disabled]="isDisabled"
            placeholder="Please provide some notes" [required]="!hideCancellationReason"></textarea>
            <mat-error *ngIf="parentSubmitted && !hideCancellationReason && caseFile.rightsAdvisorReasonForCancellation.length == 0">
              Please provide reason for cancellation</mat-error>
        </mat-form-field>
      </fieldset>
    </div>

    <div [hidden]="this.collectAllTheMeetingInformation === undefined">
      <fieldset>
        Any Other Comments or Notes (optional)
      </fieldset>
      <hr />
      <fieldset>
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Notes</mat-label>
          <textarea matInput class="form-control-textbox w-100" name="daranotessection4ra"
            [(ngModel)]="caseFile.rightsAdvisorAdditionalNotes" [disabled]="isDisabled"
            placeholder="Please provide some notes"></textarea>
        </mat-form-field>
      </fieldset>
  </div>
</div>