import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DesignatedFacilityService } from 'src/app/services/designated-facility.service';

@Component({
  selector: 'app-generatefacilityaccesscode',
  templateUrl: './generatefacilityaccesscode.component.html',
  styleUrls: ['./generatefacilityaccesscode.component.scss']
})
export class GenerateFacilityAccessCodeComponent {
  facilityId: number;
  facilityname: string | null;
  city: string | null;

  constructor(private designatedFacilityService: DesignatedFacilityService, private router: Router, private route: ActivatedRoute,) { this.facilityId = 0; this.facilityname = ""; this.city = ""; }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.facilityId = params['id'];
      this.facilityname = params['name'];
      this.city = params['city'];
    })
  }

  generateCodeFacility() {
    this.designatedFacilityService.generateCode(this.facilityId).subscribe({
      next: (data) => {
        localStorage.setItem("code", data.accessCode);
        this.router.navigate(['/facilityaccesscode']).catch(() => {
          console.log('Navigation Failure');
        });
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }
}
