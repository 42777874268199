import { Component } from '@angular/core';
import { ApplicationVersionService } from 'src/app/services/application-version.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  applicationVersion: string='';

  constructor(private _applicationversion:ApplicationVersionService){}

  ngOnInit():void{
    this._applicationversion.getAppVerison().subscribe({
      next:(data) =>{
        this.applicationVersion = data.applicationVersion;
      }
    })
  }
}
