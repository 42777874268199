import { Component, Input, ViewChild } from '@angular/core';
import { RequestForInformation, defaultRequestForInformationValues } from '../../request-for-information';
import { RequestForInformationFormOptionService } from 'src/app/services/request-for-information-form-option.service';
import { MatRadioGroup } from '@angular/material/radio';

@Component({
  selector: 'app-section-requestforinformation-request-received',
  templateUrl: './section-requestforinformation-request-received.component.html',
  styleUrls: ['./section-requestforinformation-request-received.component.scss']
})
export class SectionRequestforinformationRequestReceivedComponent {

  @Input() requestforinformation:RequestForInformation=defaultRequestForInformationValues();
  @Input() isDisabled:boolean=false;
  inquiryReceived:any[]=[];
  inquiryOthersVisible:boolean=false;
  @ViewChild('radinquiryreceived') radInquiryReceived!:MatRadioGroup;
  constructor(private _rfioptionservice:RequestForInformationFormOptionService){
    
  }

  ngOnInit():void{
    this._rfioptionservice.getInquiryReceived().subscribe((options) =>
    {
      this.inquiryReceived = options;
      console.log(this.inquiryReceived);
    });
  }

  radInquirychange() {
    this.inquiryOthersVisible=false
    const selectedId=this.requestforinformation.requestForInformationInquiryReceivedVersionId;
    const filteredResult = this.inquiryReceived.filter(ir=>ir.id==selectedId)
    if(filteredResult[0].inquiryName == "Other")
    {
      this.inquiryOthersVisible=true;
    }
    }
}
