<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container">
    <h1>Facilities Administration</h1>
  </div>
</div>
<div class="container">
  <div class="content" role="main">
    <a routerLink="/facilities" class="back-to-previous">&#8592; Back to Facilities</a>
    <h2 class="admin-subheading">Add Facility</h2>
    
    <div class="facility-heading">Facility Information</div>
    <div class="designatedFacilityForm">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Facility Name</mat-label>
        <input placeholder="Facility Name" matInput [(ngModel)]="designatedFacility.facilityName" class="form-control-textbox" name="facilityName" />
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>City</mat-label>
        <mat-select placeholder="Select City" name="city" [(ngModel)]="designatedFacility.cityVersionId">
          <mat-option *ngFor="let city of cityList" [value]="city.cityId">{{ city.cityName }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Health Authority</mat-label>
        <mat-select placeholder="Select Health Authority" name="healthAuthority"
          [(ngModel)]="designatedFacility.healthAuthorityVersionId" (selectionChange)="setHealthRegion()">
          <mat-option *ngFor="let ha of healthAuthorities" [value]="ha.healthAuthorityId">{{ ha.healthAuthorityName }}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Health Region</mat-label>
        <mat-select placeholder="Select Health Region" name="healthRegion"
          [(ngModel)]="designatedFacility.healthRegionVersionId"
          [disabled]="!currentHealthAuthority.requireHealthRegionSelection">
          <mat-option *ngFor="let hr of healthRegions" [value]="hr.healthRegionId">{{ hr.healthRegionName }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="form-buttons">
        <input type="submit" (click)="saveFacility()" class="submit-form-button" value="Submit"/>
        <a routerLink="/facilities" class="submit-form-button cancel-button">Cancel</a>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>