export interface ChangePassword{
        userDbId:number;
        oldPassword:string;
        newPassword:string;
        status:boolean;
}

export function defaultChangePasswordValues():ChangePassword{
    return {userDbId:0,oldPassword:'',newPassword:'',status:false};
}
