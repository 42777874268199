<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container">
    <h1>Facilities Administration</h1>
  </div>
</div>
<div class="container">
  <div class="content" role="main">
    <a routerLink="/editfacility/{{ unitObj.facilityVersionId }}" class="back-to-previous">&#8592; Back to Facility</a>
    <h2 class="admin-subheading">Edit Unit</h2>

    <div class="facility-heading">Unit Information</div>
    <div class="designatedFacilityForm">
      <mat-form-field appearance="outline">
        <mat-label>Unit Name</mat-label>
        <input matInput [(ngModel)]="unitObj.unitName" class="form-control-textbox" name="facilityName" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Address</mat-label>
        <input matInput [(ngModel)]="unitObj.address" class="form-control-textbox" name="facilityName"  />
      </mat-form-field>

      <div class="about-this-record">
        <p><strong>About this record</strong></p>
        <p>If you save changes to this record</p>
        <ul>
          <li>There are {{this.closedCaseFileCount}} closed case files linked to this facility.</li>
          <li>These records will not be updated.</li>
          <li>There are {{this.openCaseFileCount}} open case files linked to this facility.</li>
          <li>These records will be updated.</li>
          <li *ngIf="unitDeactivationLine">{{unitDeactivationLine}}</li>
        </ul>
      </div>

      <span>
        <input type="checkbox" [(ngModel)]="checkboxFlag" (change)="checkboxchecked()" name="unitCheckbox"> By checking
        this box I confirm I want to make this change
      </span>
      <div class="clear"></div>
      <div class="form-buttons">
        <input type="submit" (click)="updateUnit()" class="submit-form-button" [disabled]="!generatecodeButton" value="Submit" />
        <button routerLink="/editfacility/{{ this.unitObj.facilityVersionId }}" class="submit-form-button cancel-button">Cancel</button>

        <button routerLink="/generateunitcode/{{unitObj.unitVersionId}}"
          [disabled]="!generatecodeButton" class="deactivate-button" style="margin: 0 2em;">Generate Code</button>

        <button (click)="disableUnit()" class="deactivate-button" [disabled]="!deactivateButton">Deactivate</button>
      </div>
      <app-unit-audit-list [unitVersionId]="unitObj.unitVersionId"></app-unit-audit-list>
    </div>
   
  </div>
  
</div>

<app-footer></app-footer>