<app-topnav></app-topnav>

<div class="page-heading">
  <h1>Units</h1>
</div>
<div class="container">
  <div class="content" role="main">

    <div class="createButton">
      <a routerLink="/addunit" class="iras-button primary"><strong>+</strong> Add Unit</a>
    </div>
    <app-unit-list></app-unit-list>

  </div>
</div>

<app-footer></app-footer>