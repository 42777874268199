<div>
    <div class="clear"></div>
<div id="form-submission-message">
    <div>
        <div class="content">
            <div class="success">
                <span><img src="/assets/tick.svg" class="tick" width="80px" height="80px" /></span>
                <span class="thankyoutext">
                    <h3>Thank you.</h3>
                    Your form submission has been received.
                </span>
            </div>
            <div class="clear"></div>
            Note:
            <span>Thank you for contacting us, we look forward to being of assistance to you. 
                Kindly note, the Intake Coordinator works Monday to Friday 8:00 am to 4:00pm PST (excluding statuary holidays). 
                Please allow up to 3 business days for a response.</span>
            <div class="clear"></div>
           
        </div>
    </div>
    </div>