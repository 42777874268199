import { Component, Input, OnInit } from '@angular/core';
import { BlankCaseFile, CaseFile } from '../../case-file/case-file';
import { CaseFileService } from 'src/app/services/case-file.service';

@Component({
  selector: 'app-section-casefile-details',
  templateUrl: './section-casefile-details.component.html',
  styleUrls: ['./section-casefile-details.component.scss']
})
export class SectionCasefileDetailsComponent {
  @Input() caseFile: CaseFile = BlankCaseFile();
  @Input() isDisabled: boolean = false;

  caseFileId: string = "0";
  rightsAdvisorName: string = "Unassigned";
  requestdatetime: string = "";
 timeZone:string="";
  constructor(private casefileservice:CaseFileService) {
    this.timeZone = this.casefileservice.timeZoneName;
   }
}
