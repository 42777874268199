<div class="clear"></div>
<div class="dfTableContainer allFilesContainer">
	<div class="filterForm">
		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always" class="w-65-5">
				<mat-label>Name</mat-label>
				<input matInput class="form-control-textbox" name="filterRAName"
					[(ngModel)]="queryFilter.AdvisorNameTerm" />
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Email</mat-label>
				<input matInput class="form-control-textbox" name="filterRAName"
					[(ngModel)]="queryFilter.AdvisorEmailTerm" />
			</mat-form-field>
		</fieldset>

		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always" class="w-65-5">
				<mat-label>Health Region</mat-label>
				<mat-select placeholder="Select" name="filterFacilityHealthRegion"
					[(ngModel)]="queryFilter.AdvisorHealthRegionTerm">
					<mat-option value="0">Select</mat-option>
					<mat-option *ngFor="let hr of healthRegions" [value]="hr.healthRegionId">{{
						hr.healthRegionName}}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Status</mat-label>
				<mat-select placeholder="Select" name="filterFacilityStatus" [(ngModel)]="queryFilter.Status">
					<mat-option value="ALL">Select</mat-option>
					<mat-option value="ACTIVE">Active</mat-option>
					<mat-option value="INACTIVE">Inactive</mat-option>
					<mat-option value="PENDING">Pending</mat-option>
					<mat-option value="PASSWORD_EXPIRED">Password Expired</mat-option>
				</mat-select>
			</mat-form-field>
		</fieldset>
		<div class="form-buttons">
			<div class="filterButtons">
				<button class="submit-form-button" (click)="search()" #searchButton>Search</button>
				<button class="submit-form-button cancel-button no-margin" (click)="resetFilters()">Reset</button>
			</div>
		</div>
	</div>
	<div class="clear"></div>
	<div class="tableContents">
		<ng-container #allFilesTable
			*ngTemplateOutlet="rightsadvisorstable; context: {data: rightsadvisor, headerVisible: true}"></ng-container>
		<mat-paginator #paginator [length]="this.totalRecords" [pageSize]="25" [hidden]="this.totalRecords === 0"
			[pageSizeOptions]="[10, 25, 50]" (page)="handlePageEvent($event)" ariaLabel=''></mat-paginator>
	</div>
</div>

<ng-template #rightsadvisorstable let-data="data" let-headerVisible="headerVisible">
	<app-no-results #noResults></app-no-results>

	<table mat-table class="fileList primary" [dataSource]="data" [hidden]="data.length === 0">
		<ng-container matColumnDef="Name">

			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Name">Rights
				Advisors Name</th>
			<td mat-cell *matCellDef="let rightsadvisor" [matTooltip]="rightsadvisor.advisorName">
				<div *ngIf="rightsadvisor.status.toLocaleLowerCase() !== 'inactive'; else withoutlinktag">
					<a
						routerLink="/edit-rights-advisor/{{this.rightsadvisor.rightsAdvisorVersionId}}">{{rightsadvisor.advisorName}}</a>
				</div>
				<ng-template #withoutlinktag><a>{{rightsadvisor.advisorName}}</a></ng-template>
			</td>
		</ng-container>

		<ng-container matColumnDef="Email">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Date">Email</th>
			<td mat-cell *matCellDef="let rightsadvisor">
				{{rightsadvisor.advisorEmail}}
			</td>
		</ng-container>

		<ng-container matColumnDef="ActiveCases">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Date"># of Active
				Cases</th>
			<td mat-cell *matCellDef="let rightsadvisor">
				{{rightsadvisor.openCaseCount}}
			</td>
		</ng-container>

		<ng-container matColumnDef="HealthRegion">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Date">Health Region
			</th>
			<td mat-cell *matCellDef="let rightsadvisor">
				<a>{{rightsadvisor.healthRegionName}}</a>
			</td>
		</ng-container>

		<ng-container matColumnDef="Status">

			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Date">Status</th>
			<td mat-cell *matCellDef="let rightsadvisor">
				<app-status-bubble [status]="rightsadvisor.status"></app-status-bubble>
			</td>
		</ng-container>

		<ng-container matColumnDef="Actions">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Actions">
				Actions
			</th>
			<td mat-cell *matCellDef="let rightsadvisor">
				<a routerLink="/disable-rights-advisor/{{rightsadvisor.rightsAdvisorVersionId}}/{{rightsadvisor.advisorName}}"
					[matTooltip]="'Disable'">
					<svg height="2em" viewBox="0 0 448 512">
						<style>
							svg {
								fill: #7398a8;
								padding-right: 5%;
							}
						</style>
						<path
							d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
					</svg>
				</a>
				<a routerLink="/edit-rights-advisor/{{rightsadvisor.rightsAdvisorVersionId}}/{{rightsadvisor.advisorName}}/{{rightsadvisor.advisorEmail}}/{{rightsadvisor.advisorHealthRegionVersionId}}"
					[matTooltip]="'Edit'">
					<svg height="2em" viewBox="0 0 512 512">
						<style>
							svg {
								fill: #7398a8;
								padding-right: 5%;
							}
						</style>
						<path
							d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" />
					</svg>
				</a>
			</td>
		</ng-container>

		<ng-container *ngIf="headerVisible">
			<tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="tableHeader"></tr>
		</ng-container>
		<tr mat-row *matRowDef="let rightsadvisor; columns: columnsToDisplay"></tr>
	</table>
	<div class="clear"></div>
	<i class="fa fa-regular fa-trash-can"></i>
</ng-template>