import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { CaseFile } from '../components/case-file/case-file';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { caseFileSections } from '../components/case-file/case-file-sections';
import { CaseFileAuditlog } from '../components/case-file/case-file-audit-log';
import { CaseFileQueryFilter } from '../components/case-file/case-file-query';
import { CaseFileReportQuery } from '../components/case-file/case-file-reporting-query';

@Injectable({
	providedIn: 'root',
})
export class CaseFileService {

	apiUrl = environment.apiUrl + 'CaseFile/'; // injected from the environment.ts

	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	timeZoneName:string=new Date().toLocaleDateString('en-US', { day: '2-digit', timeZoneName: 'long',}).slice(4);

	constructor(
		private http: HttpClient,
		private _authservice: AuthService) { }

	getCaseFileQueryFilterParams(filter: CaseFileQueryFilter): HttpParams {
		let params = new HttpParams(); // Can't use {fromObject} here. Dates don't play nicely.
		params = params.append('CaseFileTerm', filter.CaseFileTerm);
		params = params.append('NameTerm', filter.NameTerm);
		params = params.append('PhnTerm', filter.PhnTerm);
		params = params.append('RightsAdvisorVersionId', filter.RightsAdvisorVersionId);
		params = params.append('FacilityUnitVersionId', filter.FacilityUnitVersionId);
		params = params.append('StartDate', filter.StartDate == '' ? filter.StartDate : new Date(filter.StartDate).toDateString());
		params = params.append('EndDate', filter.EndDate == '' ? filter.EndDate : new Date(filter.EndDate).toDateString());
		params = params.append('CaseFileStatus', filter.CaseFileStatus);
		params = params.append('OrderByProperty', filter.OrderByProperty);
		params = params.append('OrderByDirection', filter.OrderByDirection);
		params = params.append('PageSize', filter.PageSize);
		params = params.append('PageNumber', filter.PageNumber);
		params = params.append('timeZone',this.timeZoneName);
		return params
	}

	getCaseFilesCMHA(casefilequery: CaseFileQueryFilter): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		let param = this.getCaseFileQueryFilterParams(casefilequery)
		return this.http.get<CaseFile[]>(this.apiUrl + "getCaseFilesCMHA", { headers: headers, params: param, observe: "response" });
	}

	getCaseFilesRA(casefilequery: CaseFileQueryFilter): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		let param = this.getCaseFileQueryFilterParams(casefilequery)
		return this.http.get<CaseFile[]>(this.apiUrl + "getCaseFilesRA", { headers: headers, params: param, observe: "response" });
	}

	getCaseFileCountByUnit(unitVersionId: number): Observable<any[]> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<any[]>(this.apiUrl + "getCaseFileCountByUnit/" + unitVersionId, { headers: headers })
	}

	getCaseFileCountByFacility(facilityVersionId: number): Observable<any[]> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<any[]>(this.apiUrl + "getCaseFileCountByFacility/" + facilityVersionId, { headers: headers })
	}

	getCaseFileCountByRA(RAVersionId: number): Observable<any[]> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<any[]>(this.apiUrl + "getCaseFileCountByRA/" + RAVersionId, { headers: headers })
	}

	saveIntakeFormCMHA(caseFile: CaseFile): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<any>(this.apiUrl + "createCaseFileCMHA", caseFile, { headers: headers });
	}

	saveIntakeFormDF(caseFile: CaseFile): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });

		return this.http.post<any>(this.apiUrl + "createCaseFileDF", caseFile, { headers: headers });
	}

	getCaseFileDetails(casefileid: number): Observable<any> {
		let params = new HttpParams();
		params = params.append("timeZone",this.timeZoneName);
		params = params.append("casefileid",casefileid);
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<any>(this.apiUrl + "getCaseFileDetails" , { headers: headers, params: params });
	}

	updateCaseFileIRAS(caseFile: CaseFile): Observable<null> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<null>(this.apiUrl + "updateCaseFileIRAS/", caseFile, { headers: headers });
	}

	updateCaseFileIRASAndRAAssigned(caseFile: CaseFile): Observable<null> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<null>(this.apiUrl + "updateCaseFileIRASAndRAAssigned/", caseFile, { headers: headers });
	}

	updateCaseFileRA(caseFile: CaseFile): Observable<null> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<null>(this.apiUrl + "updateCaseFileRA/", caseFile, { headers: headers });
	}

	updateAndCloseCaseFileIRAS(caseFile: CaseFile): Observable<null> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<null>(this.apiUrl + "updateAndCloseCaseFileIRAS/", caseFile, { headers: headers });
	}

	getCaseFileSections(casefileid: number): Observable<caseFileSections> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<caseFileSections>(this.apiUrl + "getCaseFileSections/" + casefileid, "", { headers: headers });
	}

	getPDFdownloadLink(casefileid: number): string {
		return this.apiUrl + "Pdf/" + casefileid;
	}

	getCaseFileAuditLog(caseFileId: number): Observable<CaseFileAuditlog[]> {
		let params = new HttpParams();
		params = params.append("timeZone",this.timeZoneName);
		params = params.append("caseFileId",caseFileId);
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<CaseFileAuditlog[]>(this.apiUrl + "getCaseFileAuditLogs", { headers: headers,params:params});
	}

	getPDFdownloadLinkStatus(caseFileId: number): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<CaseFileAuditlog[]>(this.apiUrl + "getCaseFilePdfDownloadLinkStatus/" + caseFileId, { headers: headers });
	}

	downloadPDF(casefileid: number): Observable<Blob> {
		let params = new HttpParams();
		params = params.append("timeZone",this.timeZoneName);
		params = params.append("caseFileId",casefileid);
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get(this.apiUrl + 'downloadPdf', { headers: headers,params:params, responseType: 'blob' });
	}

	issueNewPDFLink(caseFileId: number): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<null>(this.apiUrl + 'issueNewPDFLink/' + caseFileId, null, { headers: headers });
	}

	getcasefilereport(caseFileReportQuery:CaseFileReportQuery):Observable<Blob>{
		let params = new HttpParams();
		params=params.set('timeZone',this.timeZoneName);
		params = params.append("StartDate",caseFileReportQuery.startDate);
		params = params.append("EndDate",caseFileReportQuery.endDate);
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get(this.apiUrl + 'downloadReportToExcel', { headers: headers,params:params, responseType: 'blob' });
	}
}
