import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class topnavService {
    apiUrl = environment.apiUrl
    result: any = null;
    httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private http: HttpClient) { }

    getTopNav(token: string): Observable<any> {
        if (token != null) {
            const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
            const options = { headers: headers };
            this.result = this.http.get<any>(this.apiUrl + "getTopNav", options);
        }
        return this.result
    }

}