import { Component, OnInit, Query } from '@angular/core';
import { DesignatedFacilityService } from 'src/app/services/designated-facility.service';
import { DesignatedFacility, defaultValues } from 'src/app/components/designated-facility/designated-facility';
import { ActivatedRoute, Router } from '@angular/router';
import { CityService } from 'src/app/services/city-service';
import { HealthRegionService, HealthRegion, HealthAuthority } from 'src/app/services/healthregion-service';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
  selector: 'app-designatedfacility',
  templateUrl: './designatedfacility.component.html',
  styleUrls: ['./designatedfacility.component.scss']
})

export class AddDesignatedFacilityComponent implements OnInit {
  designatedFacility: DesignatedFacility = defaultValues();
  accessCode = "";
  cityList: any = [];
  healthAuthorities: HealthAuthority[] = [];
  healthRegions: HealthRegion[] = [];
  currentHealthAuthority: HealthAuthority = {} as HealthAuthority;

  constructor(
    private designatedFacilityService: DesignatedFacilityService,
    private router: Router,
    private cityService: CityService,
    private healthRegionService: HealthRegionService,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) { }

  saveFacility() {
    this.designatedFacilityService.saveFacility(this.designatedFacility).subscribe({
      // next: (data) => {
      //   this.router.navigate(['/facilities']).catch(() => {
      //     console.log('Navigation Failure');
      //   });
      // },
      next: () => {
        this.toastService.showMessage('The facility has been saved.')
        this.router.navigate(['/facilities'])
      },
      error: (error) => {
        console.log(error, 'Unable to connect to Designated Facility API Endpoint');
      },
      complete: () => {
      }
    });
  }

  setHealthRegion(): void {
    const currentHealthAuthority = this.healthAuthorities.find(item => item.healthAuthorityId == this.designatedFacility.healthAuthorityVersionId);
   
    if (currentHealthAuthority !== undefined) {
      this.currentHealthAuthority = currentHealthAuthority;

      if (currentHealthAuthority.requireHealthRegionSelection) {
        this.designatedFacility.healthRegionVersionId = 0;
      }
      else {
        const matchingRegion = this.healthRegions.find(item => item.healthRegionName == currentHealthAuthority.healthAuthorityName);
        if (matchingRegion !== undefined) {
          this.designatedFacility.healthRegionVersionId = matchingRegion.healthRegionId;
        }
      }
    }
  }

  ngOnInit(): void {
    this.cityService.getCities().subscribe({
      next: (data) => {
        this.cityList = data;
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });

    this.healthRegionService.getHealthRegions().subscribe({
      next: (data) => {
        this.healthRegions = data;
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });

    this.healthRegionService.getHealthAuthorities().subscribe({
      next: (data) => {
        this.healthAuthorities = data;
        console.log(data);
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }
}
