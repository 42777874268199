<app-topnav></app-topnav>
<div class="page-heading">
    <div class="container"><h1>Account Profile</h1></div>
  </div>
  <div class="clear"></div>
<div class="container">
    <div class="content">
<mat-tab-group>
    <mat-tab label="Tab 1">
      <ng-template mat-tab-label>Account Profile</ng-template>
     <app-profile-ra *ngIf="this.showProfile === 'RA';else elseblock"></app-profile-ra>
     <ng-template #elseblock><app-profile-cmha></app-profile-cmha></ng-template>
    </mat-tab>
    <mat-tab label="Tab 2">
      <ng-template mat-tab-label>Password</ng-template>
     <app-changepassword></app-changepassword>
    </mat-tab>
    
  </mat-tab-group>
</div>
</div>
  <app-footer></app-footer>