import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ResetPassword, defaultRPValues } from 'src/app/components/users/reset-password';
import { ForgotPasswordService } from 'src/app/services/forgot-password.service';
import { ResetPasswordService } from 'src/app/services/reset-password.service';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {



 rpObj:ResetPassword=defaultRPValues();
 confirmPassword:string="";
 errorDivVisible:boolean=false;
 fpGuid:string="";
 constructor(private _resetpasswordservice:ResetPasswordService,private route :ActivatedRoute,private router:Router,private fpservice:ForgotPasswordService,
  private _toastServive:ToastService){}

ngOnInit():void{
  this.route.params.subscribe((params)=>{
    this.fpGuid= params['guid']
    this.rpObj.resetPasswordGuid = params['guid'];
  })

this.fpservice.getUsernameFromGuid(this.fpGuid).subscribe({
  next:(data)=>{this.rpObj.username = data.username;console.log(this.rpObj.username)},
  error:(err)=>{ 
    this._toastServive.showMessage('This link has expired. Please request a new link');
    this.router.navigate(['/login']);}
})
}

 resetPassword(rpForm: NgForm) {
  if(rpForm.invalid)
  {
    Object.values(rpForm.controls).forEach(control=>control.markAllAsTouched());
    return;
  }
  else if(this.rpObj.password  != this.confirmPassword)
  {
    return;
  }
  else{
this._resetpasswordservice.resetpassword(this.rpObj).subscribe({
  next:(data)=>{
this.errorDivVisible = true;
  },
  error:(err)=>{
   
  }
})
  }
  }

  gotologin() {
    this.router.navigate(['/login']);
    }
}
