import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { RequestForInformation } from '../components/request-for-information/request-for-information';
import { AuthService } from './auth.service';
import { RequstForInformationQueryFilter } from '../components/request-for-information/request-for-information-query';
import { RequestForInformationSection } from '../components/request-for-information/request-for-information-section-model';
import { RequestForInformationAuditLog } from '../components/request-for-information/request-for-information-audit-log';
import { CaseFileService } from './case-file.service';

@Injectable({
    providedIn: 'root',
})
export class RequestForInformationFormService {

    apiUrl = environment.apiUrl; // injected from the environment.ts

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(
        private http: HttpClient,private _authservice:AuthService,private _casefileservice:CaseFileService) { }

        getRequestForInformationQueryFilterParams(filter: RequstForInformationQueryFilter): HttpParams {
            let params = new HttpParams(); // Can't use {fromObject} here. Dates don't play nicely.
            params = params.append('Id', filter.id);
            params = params.append('NameTerm', filter.NameTerm);
            params = params.append('TreatmentTypeTerm', filter.TreatmentTypeTerm);
            params = params.append('PhoneTerm', filter.PhoneTerm);
            params = params.append('EmailTerm', filter.EmailTerm);
            params = params.append('InquiryReceivedByTerm', filter.InquiryReceivedByTerm);
            params = params.append('StartDate', filter.StartDate == '' ? filter.StartDate : new Date(filter.StartDate).toDateString());
            params = params.append('EndDate', filter.EndDate == '' ? filter.EndDate : new Date(filter.EndDate).toDateString());
            params = params.append('Status', filter.Status);
            params = params.append('OrderByProperty', filter.OrderByProperty);
            params = params.append('OrderByDirection', filter.OrderByDirection);
            params = params.append('PageSize', filter.PageSize);
            params = params.append('PageNumber', filter.PageNumber);
            return params
        }
    
        getRequestForInformationForms(rfiQuery: RequstForInformationQueryFilter): Observable<any> {
            const token = this._authservice.getAuthToken();
            const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
            let param = this.getRequestForInformationQueryFilterParams(rfiQuery)
            return this.http.get<RequestForInformation[]>(this.apiUrl + "RequestForInformation/getRequestForInformationForms", { headers: headers, params: param, observe: "response" });
        }


    saveRequestForInformationForm(rfiObj:RequestForInformation): Observable<any> {
        const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        return this.http.post<any>(this.apiUrl + 'RequestForInformation/createRequestForInformationForm/',rfiObj,{headers:headers});

    }

    createPublicRequestForInformationForm(rfiObj:RequestForInformation): Observable<RequestForInformation> {
        const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        return this.http.post<RequestForInformation>(this.apiUrl + 'RequestForInformation/createPublicRequestForInformationForm/',rfiObj,{headers:headers});

    }

    getRequestForInformationFormDetails(rfiFormId:number): Observable<RequestForInformation> {
        const token = this._authservice.getAuthToken();
        let param = new HttpParams();
        param = param.append('rfiFormId',rfiFormId);
        param = param.append('timeZone',this._casefileservice.timeZoneName);
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        return this.http.get<RequestForInformation>(this.apiUrl + "RequestForInformation/getRequestForInformationDetails", { headers: headers,params:param});
    }

    getRequestForInformationFormSections(rfiFormId:number): Observable<RequestForInformationSection> {
        const token = this._authservice.getAuthToken();
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        return this.http.get<RequestForInformationSection>(this.apiUrl + "RequestForInformation/getRequestForInformationSections/"+rfiFormId, { headers: headers});
    }

    updateRequestForInformationForm(rfiObj:RequestForInformation): Observable<any> {
        const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        return this.http.post<any>(this.apiUrl + 'RequestForInformation/updateRequestForInformationForm/',rfiObj,{headers:headers});

    }

    saveAndCloseRequestForInformationForm(rfiObj:RequestForInformation): Observable<any> {
        const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        return this.http.post<any>(this.apiUrl + 'RequestForInformation/saveAndCloseRequestForInformationForm/',rfiObj,{headers:headers});

    }

    getRFIFormAuditLog(rfiformid: number): Observable<RequestForInformationAuditLog[]> {
		let params = new HttpParams();
		params = params.append("timeZone",this._casefileservice.timeZoneName);
		params = params.append("rfiformid",rfiformid);
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<RequestForInformationAuditLog[]>(this.apiUrl + "RequestForInformation/getRequestForInformationAuditLogs", { headers: headers,params:params});
	}
}