import { Component } from '@angular/core';
import { CMHAAgents, defaultValues } from '../cmha-agents/CMHAAgents';
import { CaseFileCount } from '../case-file/case-file-count';
import { ToastService } from 'src/app/services/toast-service.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { RightsAdvisorService } from 'src/app/services/rights-advisor.service';
import { CMHAAgentService } from 'src/app/services/cmhaagents.service';

@Component({
  selector: 'app-profile-cmha',
  templateUrl: './profile-cmha.component.html',
  styleUrls: ['./profile-cmha.component.scss']
})
export class ProfileCmhaComponent {
  cmhaAgents:CMHAAgents=defaultValues();
  healthRegions:any[]=[];
  filteredResponse: CaseFileCount[] = [];
  closedCaseFileCount: number = 0;
    openCaseFileCount: number = 0;
    RADeactivationLine: string = "";
    checkboxFlag: boolean = false;
    deactivateButton:boolean=false;

    constructor(private _toastservice:ToastService,private cmhaAgentService:CMHAAgentService,private _authservice:AuthService,private router:Router){}

    ngOnInit():void{
    
      this.cmhaAgentService.getCMHAAgentDetailsFromUserID().subscribe({
        next:(res)=>{
          console.log("cmhadetails"+res)
          this.cmhaAgents = res;
          this.closedCaseFileCount = res.closeCaseCount;
          this.openCaseFileCount = res.openCaseCount;
          
        }
      })
       
    }
  
  updateCmhaAgent(){
        this.cmhaAgentService.updateCMHAAgent(this.cmhaAgents).subscribe(data=>{
          this._toastservice.showMessage("Details updated successfully");
          this.router.navigate(['/dashboard-cmha']);
        })
      }
}
