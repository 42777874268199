<div class="form-section-header">
  <div class="container">
    <h2>Case Id: {{caseFileId}}</h2>
    <h4>Rights Advisor: {{rightsAdvisorName}}</h4>
  </div>
</div>
<div class="form-subsection-content">
  <div class="container">
    <span>Date and Time IRAS Request Received</span><br>
    <span>{{requestdatetime|date:"dd/MM/yyyy hh:mm aaa"}}</span><br>
    <sub>All times are in PST</sub>
  </div>
</div>