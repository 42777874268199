<app-topnav></app-topnav>

<div class="page-heading">
	<div class="container">
		<h1>Forgot Password</h1>
	</div>
</div>
<form #forgotPasswordForm="ngForm">
	<div class="container">
		<div class="content" role="main">
			<div class="login-heading">
				<strong>{{usertype}}</strong>
			</div>
			<div class="facility-heading w-70">Forgot/Reset Password</div>
			<div class="designatedFacilityForm w-70">
				<div class="login-fail fp-box" [hidden]="!errorDivVisible">
					<img src="/assets/tick.svg">
					<span>
						<h2>Thank you</h2>
						<p>If you have an email registered with our system you should receive an email shortly with
							instructions on how to reset your password.</p>
					</span>

				</div>
				<ng-container *ngIf="inputVisible">
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Username</mat-label>
						<input matInput [(ngModel)]="forgotPasswordObj.username" class="form-control-textbox"
							name="Username" #username="ngModel" required />
						<mat-error *ngIf="username.invalid && username.touched">Field is required</mat-error>
					</mat-form-field>

					<div class="clear-10"></div>
					<div class="form-buttons">
						<div class="form-buttons-with-forgot-password">
							<input type="submit" (click)="forgotPassword(forgotPasswordForm)" class="submit-form-button"
								value="Submit" #submitButton />
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="!inputVisible">

					<div class="form-buttons">
						<div class="form-buttons-with-forgot-password">
							<input type="submit" (click)="goBack()" class="submit-form-button" value="Return to login"
								#submitButton />
						</div>
					</div>
				</ng-container>
			</div>
			<div>

			</div>
			<div>

			</div>
		</div>
	</div>
</form>

<app-footer></app-footer>