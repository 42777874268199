import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RequestForInformationFormOptionService {

    apiUrl = environment.apiUrl; // injected from the environment.ts

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(
        private http: HttpClient,) { }

    getInquiryReceived(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'RequestForInformation/getInquiryReceivedOptions/');

    }

    getTreatmentOptions(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'RequestForInformation/getTreatmentOptions/');
    }

    getNatureOfInquiry(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'RequestForInformation/getNatureOfInquiry/');
    }

    getNatureOfInquiryForPublicSite(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'RequestForInformation/getNatureOfInquiryForPublicSite/');
    }

    getAssistanceProvided(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'RequestForInformation/getAssistanceProvided/');
    }

    getReferralsMade(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'RequestForInformation/getReferralsMade/');
    }

    getFollowUpNeeded(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'RequestForInformation/getFollowupNeeded/');
    }
}