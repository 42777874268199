import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CaptchaService {

	apiUrl = environment.apiUrl + 'Captcha/'; // injected from the environment.ts

	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(
		private http: HttpClient,) { }

	getSiteKey(): Observable<any> {
		return this.http.get<any>(this.apiUrl+'getSiteKey');
			
	}

    verifyCaptcha(captcharesponse:string):Observable<any>
    {
        return this.http.post(this.apiUrl+'verifyCaptcha',{recaptchaResponse:captcharesponse})
    }
}