import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { defaultLoginValues, Login } from 'src/app/components/login/login';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-ra-login',
  templateUrl: './ra-login.component.html',
  styleUrls: ['./ra-login.component.scss']
})
export class RaLoginComponent {
  loginObj: Login = defaultLoginValues();
  token: string = "";
  errorDivVisible: boolean = false;
  errorDivHeading: string = "";
  errorDivMessage: string = "";
  errorDivButtonText: string = "";
  @ViewChild('submitButton') submitButton!: ElementRef
  constructor(public _loginService: LoginService, private _authService: AuthService, public router: Router) { }

  loginRA(loginRaForm: NgForm): void {
    if (!loginRaForm.invalid) {
      this.loginObj.userDbId = 0;
      this.loginObj.email = "TestEmail";
      this._loginService.loginRA(this.loginObj).subscribe({
        next: (res) => {
          if (res.isLoginSuccessful) {
            if (res.passwordChangeRequiredDays>0) {
              this._authService.setUserLoginData(res);
              this.router.navigateByUrl('/dashboard-ra');
            }
            else {
              this.setErrorDiv('Your password has expired', 'Your password is no longer valid and needs to be reset.', 'Reset Password', true);
            }

          }
          else {
            this.setErrorDiv('Invalid Username and/or Password', 'Sorry, the username and password do not match. Try again or contact CMHA at (604) 681-4070', 'Try Again', true);
          }
        }
      })
    }
    else {
      Object.values(loginRaForm.controls).forEach(control => control.markAsTouched());
    }
  }

  hideErrorDiv() {
    switch (this.errorDivButtonText.toUpperCase().replace(' ', '')) {
      case "TRYAGAIN":
        this.setErrorDiv('', '', '', false);
        break;
      case "RESETPASSWORD":
        this.router.navigate(['/forgot-password/RA'])
        break;
      default:
        break;
    }

  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.submitButton.nativeElement.click();
      event.preventDefault();
    }
  }

  setErrorDiv(heading: string, message: string, buttonText: string, visibility: boolean) {
    this.errorDivHeading = heading;
    this.errorDivMessage = message;
    this.errorDivButtonText = buttonText;
    this.errorDivVisible = visibility
  }
}
