import { Component } from '@angular/core';
import { ApplicationRoles } from 'src/app/components/users/user-roles';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent {
  userRole!:ApplicationRoles;
  showProfile:string="";
constructor(private _authservice:AuthService){}

ngOnInit():void{
  this.userRole = this._authservice.getRole()
  switch(this.userRole){
    case ApplicationRoles.RIGHTS_ADVISOR:
      this.showProfile = "RA";
      break;
      case ApplicationRoles.CMHA_INTAKE:
        this.showProfile = "CMHA"
        break;
        case ApplicationRoles.CMHA_ADMIN:
          this.showProfile = "CMHA"
          break;
  }
}
}
