<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container">
    <h1>Facilities Administration</h1>
  </div>
</div>
<div class="container">
  <div class="content" role="main">
    <a routerLink="/facilities" class="back-to-previous">&#8592; Back to Facilities</a>
    <h2 class="admin-subheading">Edit Facility</h2>

    <div class="facility-heading">Facility Information</div>
    <div class="designatedFacilityForm">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Facility Name</mat-label>
        <input placeholder="Facility Name" matInput [(ngModel)]="designatedFacility.facilityName" class="form-control-textbox" name="facilityName" />
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>City</mat-label>
        <mat-select placeholder="Select City" name="city" [(ngModel)]="designatedFacility.cityVersionId">
          <mat-option *ngFor="let city of cityList" [value]="city.cityId">{{ city.cityName }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Health Authority</mat-label>
        <mat-select placeholder="Select Health Authority" name="healthAuthority"
          [(ngModel)]="designatedFacility.healthAuthorityVersionId" (selectionChange)="setHealthRegion()">
          <mat-option *ngFor="let ha of healthAuthorities" [value]="ha.healthAuthorityId">{{ ha.healthAuthorityName }}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Health Region</mat-label>
        <mat-select placeholder="Select Health Region" name="healthRegion"
          [(ngModel)]="designatedFacility.healthRegionVersionId"
          [disabled]="!currentHealthAuthority.requireHealthRegionSelection">
          <mat-option *ngFor="let hr of healthRegions" [value]="hr.healthRegionId">{{ hr.healthRegionName }}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <div class="about-this-record">
        <p><strong>About this record</strong></p>
        <p>If you save changes to this record</p>
        
        <ul>
          <li>There are {{this.closedCaseFileCount}} closed case files linked to this facility.</li>
          <li>These records will not be updated.</li>
          <li>There are {{this.openCaseFileCount}} open case files linked to this facility.</li>
          <li>These records will be updated.</li>
          <li *ngFor="let line of this.aboutThisRecordLines">{{ line }}</li>
        </ul>
        {{ this.aboutThisRecordLines }}
      </div>

      <span>
        <input type="checkbox" [(ngModel)]="checkboxFlag" (change)="checkboxchecked()" name="unitCheckbox"> By checking
        this box I confirm I want to make this change
      </span>

      <div class="clear"></div>
      <div class="form-buttons">
        <input type="submit" (click)="updateFacility()" class="submit-form-button" [disabled]="!generatecodeButton" value="Submit" />
        <a routerLink="/facilities" class="submit-form-button cancel-button">Cancel</a>
        <button (click)="disableFacility()" class="deactivate-button float-right" [disabled]="!deactivateButton">Deactivate</button>
        <div class="clear"></div>
        <div class="createButton form-buttons">
          <div class="w-20"></div>
          <a routerLink="/addunit/{{facilityid}}" class="submit-form-button"><strong>+</strong> Add Unit</a>
        </div>
      </div>
      <div class="clear"></div>

      <app-unit-list [facilityid]="facilityid"></app-unit-list>
      <app-designated-facility-audit-log-list [facilityVersionId]="facilityid"></app-designated-facility-audit-log-list>
    </div>
  </div>
</div>
<app-footer></app-footer>