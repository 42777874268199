<app-topnav></app-topnav>
<div class="page-heading">
	<div class="container">
		<h1>Request For Rights Advice in Facility</h1>
	</div>
</div>
<div class="container">
	<div class="content">
		<div class="success">
			<span><img src="/assets/tick.svg" class="tick" width="80px" height="80px" /></span>
			<span class="thankyoutext">
				<h3>Thank you.</h3>
				Your form submission has been received.
			</span>
		</div>
		<div class="clear"></div>
		Case ID: {{casefileid}}
		<div class="clear"></div>
		Next Steps:
		<ul>
			<li>Download PDF copy of request for rights advice submission</li>
			<li>Keep on file on client record</li>
		</ul>
		<div class="clear"></div>
		<div class="form-buttons">
			<button (click)="downloadButtonClick()" class="submit-form-button" [disabled]="pdfbuttondisabled">Download
				PDF Submission</button>
		</div>
		<div class="clear"></div>
		Note:
		<ul>
			<li>This is a one time use download button.</li>
			<li>CMHA cannot reissue this PDF form</li>
		</ul>
		<div class="clear"></div>
		If you have questions, contact the IRAS Intake Coordinator at intake&#64;irasbc.ca.
	</div>
</div>

<app-footer></app-footer>