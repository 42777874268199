import { Component, OnInit } from '@angular/core';
import { UnitService } from 'src/app/services/unit-service';
import { Units, defaultUnitValues } from 'src/app/components/unit/unit';
import { ActivatedRoute, Router } from '@angular/router';
import { DesignatedFacilityService } from 'src/app/services/designated-facility.service';
import { Location } from '@angular/common';
import { ToastService } from 'src/app/services/toast-service.service';
import { NgForm } from '@angular/forms';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-addunit',
  templateUrl: './addunit.component.html',
  styleUrls: ['./addunit.component.scss']
})
export class AddUnitComponent implements OnInit {

  unitObj = defaultUnitValues();
  facilityList: any = [];
  facilityid: number = 0;
  submitButtonClicked=false;
  validationErrors: string[]=[];

  constructor(
    public unitService: UnitService, 
    public router: Router, 
    public designatedFacilityService: DesignatedFacilityService, 
    private route: ActivatedRoute, 
    private location: Location,
    private _toastService:ToastService) 
    { }

  ngOnInit(): void {
    this.designatedFacilityService.getActiveFacilities().subscribe({
      next: (data) => { this.facilityList = data; },
      error: (e) => console.log(e),
      complete: () => { }
    });

    this.route.params.subscribe(params => {
      this.unitObj.facilityVersionId = +params['facilityid'];
    });
  }

  saveUnit(addunitForm:NgForm): void {
    if(!this.validateAddUnit(addunitForm))
    {
      this._toastService.showMessage("Error in adding unit. Please fill all the fields");
      return;
    }

    this.unitService.addUnit(this.unitObj).subscribe({
      next: (data) => {
        const newUnitId: number = data.unitVersionId;
        const localStorageKey = 'accesscode_' + newUnitId;
        localStorage.setItem(localStorageKey, data.accessCode);

        this.router.navigate(['/unitaccesscode/' + newUnitId]).catch(() => {
          console.log('Navigation Failure');
        });
      },
      error: (error) => {
        console.log(error.status)
        if (error.status === HttpStatusCode.BadRequest) {
          this.validationErrors = [];
          let validationErrorDictionary = error.error;
          for (let fieldName in validationErrorDictionary) {
            if (validationErrorDictionary.hasOwnProperty(fieldName)) {
              this.validationErrors.push(validationErrorDictionary[fieldName]);
            }
          }
        }
      },
      complete: () => {
      }
    });
  }

  validateAddUnit(addunitForm:NgForm):boolean{
    let flag=false;
    if(addunitForm.valid){
       flag = true;
    }
    else{
      Object.values(addunitForm.controls).forEach(control => control.markAsTouched());
      flag=false;
    }
    return flag;
  }
}
