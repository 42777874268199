<app-authorize></app-authorize>
<app-topnav></app-topnav>
<div class="page-heading">
  <div class="container"><h1>Request for Information</h1></div>
</div>

<div class="container">
  <a (click)="goback()" class="back-to-previous">&#8592; Back to Dashboard</a>
</div>

<div class="container">
  <div class="alert alert-danger" role="alert" *ngIf="validationErrors.length > 0">
    <ul>
      <li *ngFor="let error of validationErrors">
        {{ error }}
      </li>
    </ul>
  </div>
</div>
<form>
    <app-section-requestforinformation-request-received [requestforinformation]="requestForInformationForm" 
    [isDisabled]="!rfiFormSections.sectionRequestReceivedInfoEnabled"></app-section-requestforinformation-request-received>

    <app-section-requestforinformation-personal-info [requestforinformation]="requestForInformationForm" 
    [isDisabled]="!rfiFormSections.sectionPersonalInfoEnabled"> </app-section-requestforinformation-personal-info>

    <app-section-requestforinformation-additional-info [requestforinformation]="requestForInformationForm" 
    [isDisabled]="!rfiFormSections.sectionAdditionalInfoEnabled"></app-section-requestforinformation-additional-info>

    <div class="container">
    <div class="form-buttons">
    
        <input type="submit" value="Submit" class="submit-form-button" (click)="addRequestForInformationForm()" [hidden]="!rfiFormSections.submitButtonVisible">
        <button type="button"  class="submit-form-button" (click)="updateRequestForInformationForm()" [hidden]="!rfiFormSections.saveButtonVisible">Save</button>

        <button class="submit-form-button"  class="submit-form-button cancel-button" [hidden]="!rfiFormSections.cancelButtonVisible" (click)="goback()" >Cancel</button>
      <button type="button" class="submit-form-button" [hidden]="!rfiFormSections.saveAndCloseButtonVisible" (click)="saveAndCloseRequestForInformationForm()">Save & Close Case</button>
        </div>
    </div>
</form>
<div class="clear"></div>
<div class="container"><app-request-for-information-audit-log-list [rfiForm]="requestForInformationForm"></app-request-for-information-audit-log-list></div>
<div class="clear"></div>
<app-modal *ngIf="modalOpen | async" [title]="modalTitle" [content]="modalContent" [okButtonValue]="modalOkButtonValue"
   [cancelButtonValue]="modalCancelButtonValue" [okButtonText]="modalOkButtonText" [cancelButtonText]="modalCancelButtonText"></app-modal>
<app-footer></app-footer>