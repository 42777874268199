<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container"><h1>Rights Advisors</h1></div>
</div>
<div class="container">
  <div class="content">
    <div class="clear"></div>   
    <a href="/rights-advisors" class="back-to-facilities">&#8592; Back to Rights Advisors</a>
    
    <div class="access-code">
        
      <h3>Deactivate Rights Advisor?</h3>
      <div class="clear"></div>
      <span>{{advisorname}}</span>
      <div class="clear"></div>
      <div>
        You are attempting to deactivate this adviosr. Once deactivated, it will not longer appear as an option on the list 
        and the status will change to deactivated. Once deactivated it can no longer be activated.
      </div>
      <div class="clear"></div>
      <div>
        <div class="form-buttons">
                            
            <input
              type="submit"
              (click)="disableAdvisor()"
              class="submit-form-button" value="Submit"
            />
            <a routerLink="/rights-advisors" class="submit-form-button cancel-button"
              >Cancel</a
            >
         
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
