
import { Component, EventEmitter, Input, Output  } from '@angular/core';
import{MAT_DIALOG_DATA} from '@angular/material/dialog'
import { ModalService } from 'src/app/services/modal.service';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() isOpen: boolean = false;
  @Input() title:string="";
  @Input() content:string="";
  @Input() okButtonValue:string="OK";
  @Input() cancelButtonValue:string="Cancel"
  @Input() okButtonText:string="Ok";
  @Input() cancelButtonText:string="Cancel";

  //@Inject(MAT_DIALOG_DATA) public data:any
constructor(private modalService: ModalService){}
closeModal() {
  this.modalService.closeModal();
  this.modalService.buttonClick(null);
}

onButtonClick(button: string) {
  this.modalService.buttonClick(button);
  this.closeModal();
}
}
