import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ForgotPassword, defaultForgotPasswordValues } from 'src/app/components/users/forgot-password';
import { ForgotPasswordService as ForgotPasswordService } from 'src/app/services/forgot-password.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
	errorDivVisible: boolean = false;
	inputVisible: boolean = true;
	forgotPasswordObj: ForgotPassword = defaultForgotPasswordValues();
	usertype: string = "";
	bbApp:boolean=false;
	constructor(private _forgotPasswordService: ForgotPasswordService, private _route: ActivatedRoute, private location: Location) { }

	ngOnInit(): void {

		this._route.params.subscribe(params => {
			this.forgotPasswordObj.usertype = params['usertype']

			switch (this.forgotPasswordObj.usertype) {
				case "RA":
					this.usertype = "Rights Advisor"
					break;

				case "CMHA":
					this.usertype = "Intake Coordinator"
					break;

				default:
					this.usertype = "NA";
			}
		})
	}

	forgotPassword(fpForm: NgForm): void {
		if (fpForm.invalid) {
			return;
		}

		this._forgotPasswordService.forgotpassword(this.forgotPasswordObj).subscribe((res) => {
			this.errorDivVisible = true;
			this.inputVisible = false;
		})
	}


	goBack(): void {
		this.location.back();
	}

}
