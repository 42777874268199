import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from '../components/login/login';
import { activateRA } from '../components/users/activateuser';
import { AuthModel } from '../components/authorize/auth-model';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    apiUrl = environment.apiUrl

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private http: HttpClient) { }

    loginDF(login: Login): Observable<AuthModel> {
        return this.http.post<AuthModel>(this.apiUrl + "Login/DF", login);
    }

    loginCMHA(login: Login): Observable<AuthModel> {
        return this.http.post<AuthModel>(this.apiUrl + "Login/CMHA", login);
    }

    loginRA(login: Login): Observable<AuthModel> {
        return this.http.post<AuthModel>(this.apiUrl + "Login/RA", login);
    }

    activateRa(activationDetails: activateRA): Observable<any> {
        return this.http.post<activateRA>(this.apiUrl + "RightsAdvisor/activateRA", activationDetails);
    }
}