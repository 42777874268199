export interface CaseFilePersonalInfo {
    firstName?: string;
    lastName?: string;
    pronounVersionId?: number;
    personalHealthNumber?: string;
    personalHealthNumberNotApplicable: boolean;
	email?: string;
    dateOfBirth?: Date;
}

export function BlankCaseFilePersonalInfo(): CaseFilePersonalInfo {
	return {
        firstName: '',
        lastName: '',
        pronounVersionId: undefined,
        personalHealthNumber: '',
        personalHealthNumberNotApplicable: false,
		email: '',
		dateOfBirth: undefined, //new Date('01-01-1900'),
	};
}