import { Injectable } from "@angular/core";
import { ApplicationRoles } from "../users/user-roles";

export enum RequstForInformationStatus {
  Create=0,  
  ForReview = 1,
  InProgress = 2,
  ClosedCase = 3
}

@Injectable({
  providedIn: 'root'
})

export class RequstForInformationStatusEnumDescriptionService {
  private descriptions: { [key: number]: string } = {
    [RequstForInformationStatus.Create]: 'Select',
    [RequstForInformationStatus.ForReview]: 'For Review',
    [RequstForInformationStatus.InProgress]: 'In Progress',
    [RequstForInformationStatus.ClosedCase]: 'Closed',
   
  };

  getDescription(enumValue: number): string {
    return this.descriptions[enumValue] || 'Unknown';
  }

  getDescriptionForFilters(enumValue: any): string {
    return this.descriptions[enumValue] || 'Unknown';
  }
}