<div class="top-bg">
  <div class="container">
    <mat-toolbar class="height-100 padding-0">
      <div class="logo">
        <a stroked [href]="dashboardLink" class="user-profile">
          <span class="iras-box">IRAS</span> 
          <div class="ml-10">
            Independent Rights <br>Advice Service
          </div>
        </a>
        
      </div>
      <div class="w-45">
        <nav class="navbar navbar-expand-lg navbar-light">
          <button class="navbar-toggler toggle-button" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" (click)="changetoggle()">
            <span class="navbar-toggler-icon"></span>
         </button>
          <div [class]="contentClass" id="collapsibleNavbar">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item active nav-item-with-padding-right nav-item-with-padding-left" [hidden]="!dashboardCMHAEnabled">
                <button mat-button class="nav-link" (click)="navigate('/dashboard-cmha')">Dashboard</button>
              </li>
              <li class="nav-item active nav-item-with-padding-right nav-item-with-padding-left" [hidden]="!dashboardRAEnabled">
                <button mat-button class="nav-link" (click)="navigate('/dashboard-ra')">Dashboard</button>
              </li>
              <li class="nav-item nav-item-with-padding-right nav-item-with-padding-left" [hidden]="!FormsEnabled">
                <button mat-button [matMenuTriggerFor]="Formmenu" class="nav-link">
                  Forms
                  <svg height="10px" width="10px" viewBox="236.0936 26.6153 12.0005 12.0005" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
                    <path d="M 397.624 412.488 L 403.624 424.488 L 391.624 424.488 L 397.624 412.488 Z" style="fill: rgb(255, 255, 255); paint-order: fill; fill-rule: nonzero; stroke: rgb(255, 255, 255); transform-origin: 423.22px 456.474px;" transform="matrix(-1, -0.000044000008, 0.000044000008, -1, -206.720454691392, -461.845587030027)" bx:shape="triangle 391.624 412.488 12 12 0.5 0 1@7b3103c3"/>
                  </svg>
                </button>
                <!-- #enddocregion mat-menu-trigger-for -->
                <mat-menu #Formmenu="matMenu" class="dashboard-menu">
                  <button mat-menu-item (click)="navigate('/treatment-form/create')">Request for Rights Advice</button>
                  <button mat-menu-item (click)="navigate('/request-for-information-form/create')">Request for Information</button>
                </mat-menu>
              </li>
              <li class="nav-item nav-item-with-padding-right nav-item-with-padding-left" [hidden]="!AdminEnabled">
                <button mat-button [matMenuTriggerFor]="Adminmenu" class="nav-link">
                  Administration
                  <svg height="10px" width="10px" viewBox="236.0936 26.6153 12.0005 12.0005" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
                    <path d="M 397.624 412.488 L 403.624 424.488 L 391.624 424.488 L 397.624 412.488 Z" style="fill: rgb(255, 255, 255); paint-order: fill; fill-rule: nonzero; stroke: rgb(255, 255, 255); transform-origin: 423.22px 456.474px;" transform="matrix(-1, -0.000044000008, 0.000044000008, -1, -206.720454691392, -461.845587030027)" bx:shape="triangle 391.624 412.488 12 12 0.5 0 1@7b3103c3"/>
                  </svg>
                </button>
                <!-- #enddocregion mat-menu-trigger-for -->
                <mat-menu #Adminmenu="matMenu" class="dashboard-menu">
                  <button mat-menu-item (click)="navigate('/facilities')">Facilities</button>
                  <button mat-menu-item (click)="navigate('/rights-advisors')">Rights Advisors</button>
                  <button mat-menu-item (click)="navigate('/email-queue')">Email Queue</button>
                  <button mat-menu-item (click)="navigate('/report-home')">Reports</button>
                </mat-menu>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="userinfodiv" [hidden]="!showUserInfo">
      <div class="right-border height-70"></div>
        <a stroked class="user-profile">
          <div>
            <div class="icon">
              <img src="/assets/normal_u1797.svg" height="30px" width="30px" [hidden]="!userlogo">
              <img src="/assets/hospital.svg" height="30px" width="30px" [hidden]="userlogo">
            </div>
          </div>
          <div class="details">
            <div class="name">
              <button mat-button [matMenuTriggerFor]="Usermenu" class="nav-link">
                <span>{{username}}</span>
                <sub class="user-role">{{userRole}}</sub>
              </button>
              <!-- #enddocregion mat-menu-trigger-for -->
              
              <mat-menu #Usermenu="matMenu" class="dashboard-menu">
                <button mat-menu-item (click)="changePassword()" [hidden]="!profileVisible">Profile</button>
                <button mat-menu-item (click)="logout()">Logout</button>
              </mat-menu></div>
          </div>
        </a>
      </div>
    </mat-toolbar>
  </div>
</div>