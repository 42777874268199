import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlankCaseFile, CaseFile } from '../../case-file/case-file';
import { CaseFileService } from 'src/app/services/case-file.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast-service.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-section-issuepdf',
  templateUrl: './section-issuepdf.component.html',
  styleUrls: ['./section-issuepdf.component.scss']
})
export class SectionIssuepdfComponent {

  @Input() caseFile:CaseFile=BlankCaseFile();
  @Input() isDisabled:boolean=false;
  @Output() notifyParent: EventEmitter<void> = new EventEmitter<void>();

constructor(private _casefileservice:CaseFileService,private router:Router,private _toastservice:ToastService,private _modalService:ModalService){}
  issueNewPDF() {
    this.notifyParent.emit();
    }

   
}
