import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table'
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { DatePipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';


import {
	DateAdapter, MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
} from '@angular/material/core';

// import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
// import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
// import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
// import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentModule } from '@angular-material-components/moment-adapter';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { Config } from '../environments/config';

// figure out auth!!
// import { AuthInterceptor } from './auth/auth.interceptor';
// import { AppRoutingModule } from './app.routes';
// import { AuthGuardService } from './auth/auth-guard.service';
// import { SkipAuthGuard } from 'auth/skip-auth-guard.service';
// import { AuthService } from './auth/auth.service';

// modules

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TreatmentFormComponent } from './pages/treatment-form/treatment-form.component';
import { TopnavComponent } from './components/topnav/topnav.component';
import { IntakeFormComponent } from './components/intake-form/intake-form.component';
import { CaseFileListComponent } from './components/case-file/case-file-list/case-file-list.component';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { FooterComponent } from './components/footer/footer.component';
import { AddDesignatedFacilityComponent } from './pages/designatedfacility/add/designatedfacility.component';
import { DesignatedFacilityListComponent } from './components/designated-facility/designated-facility-list/designated-facility-list.component';
import { FacilitiesComponent } from './pages/designatedfacility/list/facilities.component';
import { DisableFacilityComponent } from './pages/designatedfacility/disable/disable-facility.component';
import { GenerateFacilityAccessCodeComponent } from './pages/designatedfacility/generatefacilityaccesscode/generatefacilityaccesscode.component';
import { EditDesignatedFacilityComponent } from './pages/designatedfacility/edit/editfacility.component';
import { RightsAdvisorListComponent } from './components/rights-advisor/rights-advisor-list/rights-advisor-list.component';
import { RightsAdvisorsComponent } from './pages/rightsadvisors/list/rightsadvisors.component';
import { EmailQueueComponent } from './pages/email-queue/email-queue.component';
import { EmailQueueViewComponent } from './pages/email-queue/email-queue-view.component';
import { EmailQueueListComponent } from './components/email-queue/email-queue-list/email-queue-list.component';
import { AddRightsAdvisorComponent } from './pages/rightsadvisors/add/addrightsadvisor.component';
import { EditRightsAdvisorComponent } from './pages/rightsadvisors/edit/editrightsadvisor.component';
import { DisableRightsAdvisorComponent } from './pages/rightsadvisors/disable/disablerightsadvisor.component';

import { MatSelectModule } from '@angular/material/select';
import { UnitListComponent } from './components/unit/unit-list/unit-list.component';
import { AddUnitComponent } from './pages/designatedfacility/addunit/addunit.component';
import { UnitsListComponent } from './pages/designatedfacility/listunits/units-list.component';
import { EditUnitsComponent } from './pages/designatedfacility/editunits/editunits.component';
import { UnitAccessCodeComponent } from './pages/designatedfacility/unitaccesscode/unitaccesscode.component';
import { MatRadioModule } from '@angular/material/radio';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { GenerateUnitAccessCodeComponent } from './pages/designatedfacility/generateunitaccesscode/generateunitaccesscode.component';
import { LoginComponent } from './pages/login/login.component';
import { DfLoginComponent } from './pages/df-login/df-login.component';
import { AuthorizeComponent } from './components/authorize/authorize.component';
import { CmhaLoginComponent } from './pages/cmha-login/cmha-login.component';
import { RaLoginComponent } from './pages/ra-login/ra-login.component';
import { Section1Component } from './components/intake-form-sections/section1/section1.component';
import { Section2Component } from './components/intake-form-sections/section2/section2.component';
import { Section3RaComponent } from './components/intake-form-sections/section3-ra/section3-ra.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Section3IrasComponent } from './components/intake-form-sections/section3-iras/section3-iras.component';
import { Section4RaComponent } from './components/intake-form-sections/section4-ra/section4-ra.component';
import { Section4IrasComponent } from './components/intake-form-sections/section4-iras/section4-iras.component';
import { Section5IrasComponent } from './components/intake-form-sections/section5-iras/section5-iras.component';
import { SectionCasefileDetailsComponent } from './components/intake-form-sections/section-casefile-details/section-casefile-details.component';
import { SectionRequestReceivedInfoComponent } from './components/intake-form-sections/section-request-received-info/section-request-received-info.component';
import { SectionAssignRaComponent } from './components/intake-form-sections/section-assign-ra/section-assign-ra.component';
import { DashboardRaComponent } from './pages/dashboard-ra/dashboard-ra.component';
import { CaseFileAuditLogListComponent } from './components/case-file/case-file-audit-log-list/case-file-audit-log-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalComponent } from './components/modal/modal.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BreakLine } from './components/case-file/case-file-audit-log-list/breakLine.pipe';
import { ActivateRightsAdvisorComponent } from './pages/rightsadvisors/activate/activate-ra.component';
import { ChangePasswordComponent } from './components/changepassword/changepassword.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ChangePasswordSnackbarComponent } from './components/changepassword-snackbar/changepassword-snackbar.component';
import { ClosedCasePdfDownloadComponent } from './pages/closed-case-pdf-download/closed-case-pdf-download.component';
import { SectionIssuepdfComponent } from './components/intake-form-sections/section-issuepdf/section-issuepdf.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ProfileRaComponent } from './components/profile-ra/profile-ra.component';
import { ProfileCmhaComponent } from './components/profile-cmha/profile-cmha.component';
import { PageNotFoundComponent } from './pages/page-not-found-component/page-not-found-component.component';
import { StatusBubbleComponent } from './components/status-bubble/status-bubble.component';
import { SectionRequestforinformationRequestReceivedComponent } from './components/request-for-information/request-for-information-sections/section-requestforinformation-request-received/section-requestforinformation-request-received.component';
import { SectionRequestforinformationPersonalInfoComponent } from './components/request-for-information/request-for-information-sections/section-requestforinformation-personal-info/section-requestforinformation-personal-info.component';
import { SectionRequestforinformationAdditionalInfoComponent } from './components/request-for-information/request-for-information-sections/section-requestforinformation-additional-info/section-requestforinformation-additional-info.component';
import { RequestForInformationFormComponent } from './pages/request-for-information-form/request-for-information-form.component';
import { RequestForInformationFormsListComponent } from './components/request-for-information/request-for-information-forms-list/request-for-information-forms-list.component';
import { RequestForInformationPublicFormComponent } from './components/request-for-information/request-for-information-public-form/request-for-information-public-form.component';
import { RequestForInformationThankyouComponent } from './components/request-for-information/request-for-information-thankyou/request-for-information-thankyou.component';
import { MatExpansionModule, matExpansionAnimations } from '@angular/material/expansion';
import { CasefilereportComponent } from './pages/reports/casefilereport/casefilereport.component';
import { ReportsHomeComponent } from './pages/reports/reports-home/reports-home.component';
import { RequestForInformationAuditLogListComponent } from './components/request-for-information/request-for-information-audit-log-list/request-for-information-audit-log-list.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { RightsAdvisorAuditLogsComponent } from './components/rights-advisor/rights-advisor-audit-logs/rights-advisor-audit-logs.component';
import { DesignatedFacilityAuditLogListComponent } from './components/designated-facility/designated-facility-audit-log-list/designated-facility-audit-log-list.component';
import { UnitAuditListComponent } from './components/unit/unit-audit-list/unit-audit-list.component';
const MY_DATE_FORMAT = {
	parse: {
		dateInput: 'DD/MM/yyyy', // this is how your date will be parsed from Input
	},
	display: {
		dateInput: 'DD/MM/yyyy', // this is how your date will get displayed on the Input
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	declarations: [
		AppComponent,
		IntakeFormComponent,
		CaseFileListComponent,
		DashboardComponent,
		TreatmentFormComponent,
		ThankyouComponent,
		FooterComponent,
		AddDesignatedFacilityComponent,
		DesignatedFacilityListComponent,
		FacilitiesComponent,

		DisableFacilityComponent,
		GenerateFacilityAccessCodeComponent,
		EditDesignatedFacilityComponent,
		RightsAdvisorListComponent,
		RightsAdvisorsComponent,
		AddRightsAdvisorComponent,
		EditRightsAdvisorComponent,
		DisableRightsAdvisorComponent,

		EmailQueueComponent,
		EmailQueueViewComponent,
		EmailQueueListComponent,

		UnitListComponent,
		AddUnitComponent,
		UnitsListComponent,
		EditUnitsComponent,
		UnitAccessCodeComponent,
		GenerateUnitAccessCodeComponent,
		LoginComponent,
		DfLoginComponent,
		AuthorizeComponent,
		CmhaLoginComponent,
		RaLoginComponent,
		Section1Component,
		Section2Component,
		Section3RaComponent,
		Section3IrasComponent,
		Section4RaComponent,
		Section4IrasComponent,
		Section5IrasComponent,
		SectionCasefileDetailsComponent,
		SectionRequestReceivedInfoComponent,
		SectionAssignRaComponent,
		DashboardRaComponent,
		CaseFileAuditLogListComponent,
		ModalComponent,
		BreakLine,
		ActivateRightsAdvisorComponent,
		ChangePasswordComponent,
		NoResultsComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		ChangePasswordSnackbarComponent,
		ClosedCasePdfDownloadComponent,
		SectionIssuepdfComponent,
		ProfilePageComponent,
		ProfileRaComponent,
		ProfileCmhaComponent,
		PageNotFoundComponent,
		StatusBubbleComponent,
		SectionRequestforinformationRequestReceivedComponent,
		SectionRequestforinformationPersonalInfoComponent,
		SectionRequestforinformationAdditionalInfoComponent,
		RequestForInformationFormComponent,
		RequestForInformationFormsListComponent,
		RequestForInformationPublicFormComponent,
		RequestForInformationThankyouComponent,
  CasefilereportComponent,
  ReportsHomeComponent,
  RequestForInformationAuditLogListComponent,
  RightsAdvisorAuditLogsComponent,
  DesignatedFacilityAuditLogListComponent,
  UnitAuditListComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		MatTableModule,
		MatSortModule,
		MatToolbarModule,
		MatPaginatorModule,
		MatTooltipModule,
		TopnavComponent,
		HttpClientModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		BrowserAnimationsModule,
		NgIf,
		MatCardModule,
		MatDatepickerModule,
		MatSelectModule,
		MatRadioModule,
		MatNativeDateModule,
		NgxMaterialTimepickerModule,
		MatDialogModule,
		DatePipe,
		MatSnackBarModule,
		MatAutocompleteModule,
		MatTabsModule,
		MatExpansionModule,
		RecaptchaModule,
		RecaptchaV3Module
	],
	providers: [
		DatePipe,
		MatSnackBarModule,
		// figure out auth!!
		// {
		// 	provide: HTTP_INTERCEPTORS,
		// 	useClass: AuthInterceptor,
		// 	multi: true
		// },
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [Config],
			multi: true,
		},
		// {
		// 	provide: APP_BASE_HREF,
		// 	useValue: '/',
		// },
		Config,
		// { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
		{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { hideRequiredMarker: true, floatLabel: 'always', appearance: 'outline' } },
		// { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
	],
	bootstrap: [AppComponent]
})

export class AppModule { }

export function initializeApp(config: Config) {
	return (): Promise<any> => {
		return config.Init();
	};
}