import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DesignatedFacilityService } from 'src/app/services/designated-facility.service';
import { DesignatedFacility } from '../designated-facility';
import { DesignatedFacilityQuery, defaultDesignatedFacilityFilterValues } from '../designated-facility-query';
import { CityService } from 'src/app/services/city-service';
import { HealthRegionService } from 'src/app/services/healthregion-service';
import { NoResultsComponent } from '../../no-results/no-results.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-designated-facility-list',
  templateUrl: './designated-facility-list.component.html',
  styleUrls: ['./designated-facility-list.component.scss']
})
export class DesignatedFacilityListComponent {
  totalRecords: number = 0;
  designatedFacilities: DesignatedFacility[] = [];
  queryFilter: DesignatedFacilityQuery = defaultDesignatedFacilityFilterValues();

  status: string = "ACTIVE";
  cityList: any[] = [];
  healthAuthorities: any[] = [];
  healthRegions: any[] = [];
  columnsToDisplay = [
    'Name', 'NumberOfActiveUnits', 'City', 'HealthAuthority', 'HealthRegion', 'Status'
  ];

  pageEvent!: PageEvent;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('noResults') noResults!: NoResultsComponent;
  @ViewChild('searchButton') searchButton !: ElementRef
  constructor(
    private designatedFacilityService: DesignatedFacilityService,
    private cityService: CityService,
    private healthRegionService: HealthRegionService
  ) { }

  ngOnInit(): void {
    this.cityService.getCities().subscribe({
      next: (data) => { this.cityList = data; },
      error: (error) => { console.log(error); },
      complete: () => { }
    });
    this.healthRegionService.getHealthRegions().subscribe({
      next: (data) => { this.healthRegions = data },
      error: (err) => console.log(err),
      complete: () => { }
    });
    this.healthRegionService.getHealthAuthorities().subscribe({
      next: (data) => { this.healthAuthorities = data; },
      error: (error) => { console.log(error); },
      complete: () => { }
    });

    this.searchWithFilters();
  }

  private searchWithFilters() {
    this.designatedFacilityService.getAllFacilities(this.queryFilter).subscribe({
      next: (data) => {
        let headers = data.headers.get('x-pagination');
        if (headers) {
          let parsedHeaders = JSON.parse(headers);
          this.totalRecords = parsedHeaders.TotalCount;
          this.noResults.setTotal(this.totalRecords);
        }
        this.designatedFacilities = data.body ? data.body : [];
      },
      error: (error) => {
        console.log(error, 'Unable to connect to Designated Facility API Endpoint');
      },
      complete: () => { }
    });
  }

  search() {
    this.queryFilter.PageNumber = 0;
    this.paginator.pageIndex = 0;

    this.searchWithFilters();
  }

  resetFilters() {
    this.queryFilter = defaultDesignatedFacilityFilterValues();
    this.paginator.pageSize = this.queryFilter.PageSize;
    this.paginator.pageIndex = this.queryFilter.PageNumber;

    this.searchWithFilters();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.queryFilter.PageNumber = e.pageIndex;
    this.queryFilter.PageSize = e.pageSize;

    this.searchWithFilters();
  }

  setFacilityStatus(a: boolean): string {
    let desc = "";
    switch (a) {
      case true:
        desc = "Active";
        break;
      case false:
        desc = "Inactive"
        break;
    }
    return desc;
  }

  @HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
	  if (event.key === 'Enter') {
		this.searchButton.nativeElement.click();
		event.preventDefault();
	  }
	}
}
