import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { defaultLoginValues, Login } from 'src/app/components/login/login';
import { Units, defaultUnitValues } from 'src/app/components/unit/unit';
import { LoginService } from 'src/app/services/login.service';
import { UnitService } from 'src/app/services/unit-service';
import { InstantiateExpr } from '@angular/compiler';
import { HttpResponse } from '@angular/common/http';
import { MatSelectChange } from '@angular/material/select';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Observable, map, of, startWith } from 'rxjs';

@Component({
  selector: 'app-df-login',
  templateUrl: './df-login.component.html',
  styleUrls: ['./df-login.component.scss']
})
export class DfLoginComponent {
  unitObj: Units = defaultUnitValues();
  unitList: Units[] = [];
  loginObj: Login = defaultLoginValues();
  token: string = "";
  selectedData: any;
  facilityUsername = new FormControl<string | Units>('');
  filteredOptions!: Observable<Units[]>;
  selectedUnit: Units|null = null;
  caseFileId: number = 0
  errorDivVisible: boolean = false;
  errorDivHeading: string = "";
  errorDivMessage: string = "";
  errorDivButtonText: string = "";
  usernameClass="";
  @ViewChild('submitButton') submitButton!: ElementRef

  constructor(
    public unitService: UnitService,
    public loginService: LoginService,
    public router: Router,
    private authService: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.caseFileId = params['casefileid'];
    })

    this.unitService.getActiveUnits().subscribe({
      next: (data) => { this.unitList = data },
      error: (err) => console.log('Error: ' + err),
      complete: () => { 
        this.filteredOptions = this.facilityUsername.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string'
              ? value
              : value?.unitName;
    
            return name
              ? this._filter(name as string)
              : this.unitList.slice();
          })
        );
      }
    })
  }

  private _filter(value: string): Units[] {
    const filterValue = value.toLowerCase();
    return this.unitList.filter(unit => (unit.facilityName).toLowerCase().startsWith(filterValue));
  }

  formatFacilityUnitName(unit: Units): string {
    return unit && unit.unitName
      ? unit.facilityName + ' - ' + unit.unitName.toString()
      : '';
  }

  updateFacilityUnitsFiltering() {
    this.filteredOptions = of(this.unitList)
      .pipe(
        map(units => this.filterFacilityUnit(units)),
      )
  }

  filterFacilityUnit(values: any[]) {
    // in this particular case, this.selectedUnit acts as a string of the search text
    const searchingString = typeof this.selectedUnit === "string"
    if (searchingString) {
      const searchValue = this.selectedUnit?.toString().toLowerCase();
      return values.filter(unit => unit.facilityName.toLowerCase().startsWith(searchValue))
    }
    return values.filter(unit => unit.facilityName.toLowerCase().startsWith(this.selectedUnit))
  }

  loginDF(loginDFForm: NgForm): void {
    try{
    this.loginObj.username = this.selectedUnit?.unitVersionId.toString()??""

    if (this.checkvalidations()) {
      this.loginObj.userDbId = 0;
      this.loginObj.email = "";
	  
      this.loginService.loginDF(this.loginObj).subscribe({
        next: (res) => {
          if (res.isLoginSuccessful) {
            res.username = res.agentName = this.selectedUnit?.facilityName + ':' + this.selectedUnit?.unitName;
            this.authService.setUserLoginData(res);
            if (this.caseFileId == 0) {
              this.router.navigateByUrl('/treatment-form/create');
            }
            else {
              this.router.navigateByUrl('/closedcasepdfdownload/' + this.caseFileId);
            }
          }
          else {
            this.setErrorDiv('Invalid Username and/or Password', 'Sorry, the username and password do not match. Try again or contact CMHA at (604) 681-4070', 'Try Again', true);
          }
        },
        error:(err)=>{
          this.setErrorDiv('Invalid Username and/or Password', 'Sorry, the username and password do not match. Try again or contact CMHA at (604) 681-4070', 'Try Again', true);
        }
      })
    }
    else {
     
      Object.values(loginDFForm.controls).forEach(control => control.markAllAsTouched());
    }
  }
  catch(err)
  {
    this.setErrorDiv('Invalid Username and/or Password', 'Sorry, the username and password do not match. Try again or contact CMHA at (604) 681-4070', 'Try Again', true);
  }
  }

  checkvalidations(): boolean {
    if (this.loginObj.username == "0" || this.loginObj.username == "" || this.loginObj.password == "") {
      return false;
    }
    return true
  }

  getSelectedText(event: MatSelectChange) {
    this.selectedData = {
      value: event.value,
      text: event.source.triggerValue
    };
  }

  hideErrorDiv() {
    this.errorDivVisible = false;
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.submitButton.nativeElement.click();
      event.preventDefault();
    }
  }

  setErrorDiv(heading: string, message: string, buttonText: string, visibility: boolean) {
    this.errorDivHeading = heading;
    this.errorDivMessage = message;
    this.errorDivButtonText = buttonText;
    this.errorDivVisible = visibility
  }

  markasdirty(loginDFForm:NgForm){
     Object.values(loginDFForm.controls).forEach(control => control.markAsDirty());
  }
}
