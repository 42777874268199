import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DesignatedFacilityService } from 'src/app/services/designated-facility.service';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
  selector: 'app-disable-facility',
  templateUrl: './disable-facility.component.html',
  styleUrls: ['./disable-facility.component.scss']
})
export class DisableFacilityComponent implements OnInit {

  facilityId: number;
  facilityname: string | null;
  city: string | null;
  sub: any;
  constructor(
    private designatedFacilityService: DesignatedFacilityService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) 
  {
    this.facilityId = 0;
    this.sub = null;
    this.facilityname = "";
    this.city = "";
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.facilityId = params['id'];
      this.facilityname = params['name'];
      this.city = params['city'];
    }
    )
  }

  disableFacility() {
    this.designatedFacilityService.disableFacility(this.facilityId).subscribe({
      next: () => {
        this.toastService.showMessage('Record has been deactivated');
        this.router.navigate(['/facilities']).catch(() => {
          console.log('Navigation Failure');
        });
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });
  }
}
