import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResetPassword } from '../components/users/reset-password';

@Injectable({
	providedIn: 'root',
})
export class ResetPasswordService {
    apiUrl = environment.apiUrl

    httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(
		private http: HttpClient) { }

        resetpassword(rp:ResetPassword): Observable<any> {
           return this.http.post<any>(this.apiUrl+"ResetPassword",rp);
        }

        
}