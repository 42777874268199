import { PaginationQuery } from "../pagination/pagination-query"

export type RAQueryFilter = PaginationQuery & {
    AdvisorNameTerm: string,
    AdvisorEmailTerm: string,
    AdvisorHealthRegionTerm: number
    Status: string
}

export function defaultRAQueryFilterValues(): RAQueryFilter {
    return {
        AdvisorNameTerm: '',
        AdvisorEmailTerm: '',
        AdvisorHealthRegionTerm: 0,
        Status: 'ALL',
        OrderByDirection: '',
        OrderByProperty: '',
        PageNumber: 0,
        PageSize: 25
    }
}