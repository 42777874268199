<div class="form-section-header">
	<div class="container">
		<h3>Section 1 </h3>
	</div>
</div>
<div class="form-subsection-header">
	<div class="container">
		<h4>Request Received Information</h4>
	</div>
</div>
<div class="form-subsection-content">
    <div class="container">
        <fieldset>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Date Request Received</mat-label>
                <input matInput [matDatepicker]="dpdaterequest" [(ngModel)]="caseFile.cmhaDateRequestReceived"
                    name="drrsectionRequestReceived" placeholder="DD/MM/YYYY" [disabled]="isDisabled">

				<mat-datepicker-toggle matIconSuffix [for]="dpdaterequest" [disabled]="isDisabled"></mat-datepicker-toggle>
				<mat-datepicker #dpdaterequest disabled="false" [disabled]="isDisabled"></mat-datepicker>
			</mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="timepicker-control">
                <mat-label>Time Request Received</mat-label>
                <input matInput hidden="true" />
                <ngx-timepicker-field [defaultTime]="'10:10 am'" style="border-bottom: 0px;"
                    [(ngModel)]="caseFile.cmhaTimeRequestReceived" [disabled]="isDisabled" name="trrsectionRequestReceived"></ngx-timepicker-field>
            </mat-form-field>
        </fieldset>
    </div>
</div>