<app-topnav></app-topnav>
<div class="page-heading">
    <div class="container">
      <h1>Request For Rights Advice Report</h1>
    </div>
  </div>
  <div class="clear"></div>
  <div class="container">
<div class="caseFileTableContainer allFilesContainer">
	<div class="filterForm">
		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Start Date</mat-label>
				<input matInput [matDatepicker]="filterStartDate" class="form-control-textbox" name="filterStartDate"
					placeholder="Start Date" [(ngModel)]="caseFileReportQuery.startDate" />
				<mat-datepicker-toggle matIconSuffix [for]="filterStartDate"></mat-datepicker-toggle>
				<mat-datepicker #filterStartDate disabled="false"></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>End Date</mat-label>
				<input matInput [matDatepicker]="filterEndDate" class="form-control-textbox" name="filterEndDate"
					placeholder="End Date" [(ngModel)]="caseFileReportQuery.endDate">
				<mat-datepicker-toggle matIconSuffix [for]="filterEndDate"></mat-datepicker-toggle>
				<mat-datepicker #filterEndDate disabled="false"></mat-datepicker>
			</mat-form-field>
	</fieldset>

		<div class="form-buttons">
			<div class="filterButtons">
				<button class="submit-form-button" (click)="searchWithFilters()" #searchButton>Download</button>
				<button class="submit-form-button cancel-button no-margin" (click)="resetFilters()">Reset</button>
			</div>
		</div>
	</div>

	<div class="clear"></div>
	
</div>
</div>
<app-footer></app-footer>