import { Component } from '@angular/core';
import { RightsAdvisor, defaultValues } from '../rights-advisor/rights-advisor';
import { CaseFileCount } from '../case-file/case-file-count';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/toast-service.service';
import { RightsAdvisorService } from 'src/app/services/rights-advisor.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-ra',
  templateUrl: './profile-ra.component.html',
  styleUrls: ['./profile-ra.component.scss']
})
export class ProfileRaComponent {

  rightsadvisor:RightsAdvisor=defaultValues();
  healthRegions:any[]=[];
  filteredResponse: CaseFileCount[] = [];
  closedCaseFileCount: number = 0;
    openCaseFileCount: number = 0;
    RADeactivationLine: string = "";
    checkboxFlag: boolean = false;
    deactivateButton:boolean=false;

    constructor(private _toastservice:ToastService,private rightsadvisorService:RightsAdvisorService,private _authservice:AuthService,private router:Router){}

    ngOnInit():void{
    
      this.rightsadvisorService.getRADetailsFromUserID().subscribe({
        next:(res)=>{
          this.rightsadvisor = res;
          this.closedCaseFileCount = res.closeCaseCount;
          this.openCaseFileCount = res.openCaseCount;
          
        }
      })
       
    }
  
  updateRightsAdvisor(){
        this.rightsadvisorService.updateRightsAdvisor(this.rightsadvisor).subscribe(data=>{
          this._toastservice.showMessage("Details updated successfully");
          this.router.navigate(['/dashboard-ra']);
        })
      }
}
