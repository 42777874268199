<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container">
    <h1>Facilities Administration</h1>
  </div>
</div>
<div class="container">
  <div class="content" role="main">
    <a routerLink="/editunit/{{ unit.unitVersionId }}" class="back-to-previous">&#8592; Back to Unit</a>

    <h2 class="admin-subheading">Unit Access Code</h2>
    <div class="container">
      <div class="access-code">
        
        <div class="info-panel">
          <h3>New Access Code Created</h3>
          <p>An access code has been generated.</p>
        </div>

        <div class="unit-access-code">
          <p>Unit Access Code: <strong>{{ generatedCode }}</strong></p>
        </div>

        <div class="form-buttons">
          <button (click)="copyAccessCode()" class="submit-form-button">Copy access code</button>
        </div>

        <h3 class="admin-subheading">Facility Unit Summary</h3>

        <p><strong>Facility:</strong> {{this.unit.facilityName }}</p>
        <p><strong>Unit:</strong> {{ this.unit.unitName }}</p>
        <p><strong>Address:</strong> {{this.unit.address }}</p>
        <br />

        <p>Next steps:</p>
        <ul>
          <li>Copy the access code</li>
          <li>Update facilty unit contact person(s) of access code and how to use it</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>