import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CaseFile } from '../case-file';
import { CaseFileService } from 'src/app/services/case-file.service';
import { ApplicationRoles } from '../../users/user-roles';
import { CaseFileStatus, CaseFileStatusEnumDescriptionService } from '../case-file-status';
import { RightsAdvisorService } from 'src/app/services/rights-advisor.service';
import { Units } from '../../unit/unit';
import { UnitService } from 'src/app/services/unit-service';
import { CaseFileQueryFilter, defaultCaseFileQueryFilters } from '../case-file-query';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NoResultsComponent } from '../../no-results/no-results.component';
import { tap } from 'rxjs';
import { Sort, MatSortModule } from '@angular/material/sort';

@Component({
	selector: 'app-case-file-list',
	templateUrl: './case-file-list.component.html',
	styleUrls: ['./case-file-list.component.scss']
})
export class CaseFileListComponent implements OnInit, AfterViewInit {
	@Input() usertype: ApplicationRoles = ApplicationRoles.CMHA_INTAKE;
	totalRecords: number = 25;
	caseFiles: CaseFile[] = [];
	caseFileQueryFilter: CaseFileQueryFilter = defaultCaseFileQueryFilters();

	casefilestatus: string = "";
	raList: any[] = [];
	facilityUnitList: Units[] = [];
	applicationStatus = Object.values(CaseFileStatus).filter((f) => !isNaN(Number(f)));
	columnsToDisplay = [
		'CMHADateRequestReceived', 'Id', 'LastName', 'FacilityUnitVersionId', 'CaseFileStatus'
	];
	sortField: string = 'CMHADateRequestReceived';
	sortDirection: string = 'desc';
	loggedInRA:number=0
	caseFileRA:number=0
	userRoleName:string='CMHA'

	pageEvent!: PageEvent;
	@ViewChild('paginator') paginator!: MatPaginator;
	@ViewChild('noResults') noResults!: NoResultsComponent;
	@ViewChild('searchButton') searchButton!: ElementRef

	constructor(
		private casefileService: CaseFileService,
		public enumDescService: CaseFileStatusEnumDescriptionService,
		private rightsAdvisorService: RightsAdvisorService,
		private unitService: UnitService,
		private authservice: AuthService) {
	}

	identity(caseFile: CaseFile): CaseFile {
		return caseFile;
	}

	ngOnInit(): void {
		this.rightsAdvisorService.getActiveRightsAdvisorsList().subscribe({
			next: (res) => {
				this.raList = res;
				this.setRADefaultFilters();
				this.searchWithFilters();
			},
			error: (e) => { console.error('Error:' + e) },
			complete: () => { }
		});

		this.unitService.getAllUnits().subscribe({
			next: (units) => { this.facilityUnitList = units }
		})

		
	}

	ngAfterViewInit(): void {
		if (this.paginator && this.paginator.page) {
			this.paginator?.page.pipe(tap(() => this.searchWithFilters())
			).subscribe();
		}
	}

	handlePageEvent(e: PageEvent) {
		this.pageEvent = e;
		this.caseFileQueryFilter.PageNumber = e.pageIndex;
		this.caseFileQueryFilter.PageSize = e.pageSize;
	}

	announceSortChange(sortState: Sort) {
		this.caseFileQueryFilter.OrderByProperty = sortState.active;
		this.caseFileQueryFilter.OrderByDirection = sortState.direction ? sortState.direction : 'desc';
		this.searchWithFilters();
	}

	setRADefaultFilters(): void {
		let role = this.authservice.getRole();

		if (role == ApplicationRoles.RIGHTS_ADVISOR) {
			let advisorname = this.authservice.getUserLoginData().agentName

			const selectedItem = this.raList.find(item => item.advisorName == advisorname);
			if (selectedItem) {
				this.caseFileQueryFilter.RightsAdvisorVersionId = selectedItem.rightsAdvisorVersionId;
				this.searchWithFilters()
			}
			this.caseFileQueryFilter.CaseFileStatus = CaseFileStatus.RAAssigned;
		}
	}

	setCaseFileStatus(a: number): string {
		let desc = this.enumDescService.getDescription(a);
		return desc;
	}

	searchWithFilters() {
		switch (this.usertype) {
			case ApplicationRoles.CMHA_INTAKE:
				this.casefileService.getCaseFilesCMHA(this.caseFileQueryFilter).subscribe({
					next: (data) => {
						this.bindDataAndPaging(data);
					},
					error: (error) => {
						console.log(error, 'Unable to connect to CaseFile API Endpoint');
					},
					complete: () => { }
				});
				break;

			case ApplicationRoles.RIGHTS_ADVISOR:
				this.casefileService.getCaseFilesRA(this.caseFileQueryFilter).subscribe({
					next: (data) => {
						this.bindDataAndPaging(data);
						this.loggedInRA = this.authservice.getUserLoginData().userDbId
						this.userRoleName='RA'
					},
					error: (error) => {
						console.log(error, 'Unable to connect to CaseFile API Endpoint');
					},
				});
		}
	}

	

	private bindDataAndPaging(data: any) {
		let headers = data.headers.get('x-pagination');
		if (headers) {
			let parsedHeaders = JSON.parse(headers);
			this.totalRecords = parsedHeaders.TotalCount;
			this.noResults.setTotal(this.totalRecords);
		}
		this.caseFiles = data.body ? data.body : [];
	}

	resetFilters(): void {
		this.caseFileQueryFilter = defaultCaseFileQueryFilters();
		this.paginator.pageSize = this.caseFileQueryFilter.PageSize;
		this.paginator.pageIndex = this.caseFileQueryFilter.PageNumber;

		this.setRADefaultFilters();
		this.searchWithFilters();
	}

	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
	  if (event.key === 'Enter') {
		this.searchButton.nativeElement.click();
		event.preventDefault();
	  }
	}
}
