<div>
    <div>
      <div class="alert alert-danger" role="alert" *ngIf="validationErrors.length > 0">
        <ul>
          <li *ngFor="let error of validationErrors">
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
    <div class="public-form">
        <form #form="ngForm">
            <fieldset>
                Please select one of the following options.
            </fieldset>
            <fieldset>
                <mat-radio-group name="radtreatmentoptiongroup" [(ngModel)]="requestforinformation.requestForInformationTreatmentOptionsVersionId"
                  aria-labelledby="intake-form-radio-group-label" class="intake-form-radio-group display-vertical" (change)="radTreatmentOptionsChange()"
                  required>
                  <mat-radio-button name="radioTreatmentOptions" *ngFor="let to of treatmentOptions"
                    [value]="to.id" class="intake-form-radio-button">
                    {{to.treatmentOption}}
                  </mat-radio-button>
                  
                </mat-radio-group>
               
              </fieldset>
              <fieldset>
                <div class="rfi-other-textbox">
                  <input type="text" matInput class="form-control-textbox " [hidden]="!treatmentOptionOtherTextVisible" 
                  [(ngModel)]="requestforinformation.requestForInformationTreatmentOptionsOtherText" name="rfi-treatment-option-other">
                </div>
              </fieldset>
              <div class="clear"></div>
              <fieldset>
                <mat-form-field appearance="outline" floatLabel="always" class="w-48">
                    <mat-label>First Name</mat-label>
                    <input matInput [(ngModel)]="requestforinformation.firstName" class="form-control-textbox" name="firstName"
                      required />
                  </mat-form-field>
            
                  <mat-form-field appearance="outline" floatLabel="always" class="w-48">
                    <mat-label>Last Name (Optional)</mat-label>
                    <input matInput [(ngModel)]="requestforinformation.lastName" class="form-control-textbox" name="lastName"
                      />
                  </mat-form-field>
              </fieldset>
              <div class="clear"></div>
              <fieldset>
                Preferred Contact Method (Select at least one)
              </fieldset>
              <div class="clear-10"></div>
              <fieldset>
                <div class="with-padding w-70">
                    <label>
                      <input type="checkbox" class="intake-form-radio-button" name="phonecheck" [(ngModel)]="isPhoneChecked">
                        Phone
                      </label>
                      <mat-form-field appearance="outline" floatLabel="always" class="padding-left-10 w-48">
                        <input matInput [(ngModel)]="requestforinformation.phoneNumber" class="form-control-textbox" name="phone" [disabled]="!isPhoneChecked"
                         />
                      </mat-form-field>
                    </div>
              </fieldset>
              <fieldset>
                <div class="with-padding w-70">
                    <label>
                      <input type="checkbox" class="intake-form-radio-button" name="emailCheck" [(ngModel)]="isEmailChecked">
                        Email
                      </label>
                      <mat-form-field appearance="outline" floatLabel="always" class="padding-left-10 w-48">
                        <input matInput [(ngModel)]="requestforinformation.email" class="form-control-textbox" name="email" [disabled]="!isEmailChecked"
                         />
                      </mat-form-field>
                    </div>
              </fieldset>
              <div class="clear"></div>
              <fieldset>
                <label id="label-natureofinquiry">I would like information about: (Select all that apply).</label>
              </fieldset>
              <div class="clear-10"></div>
              <fieldset>
                <div class="public-form-chkbox display-vertical">
                 <div *ngFor="let noi of natureOfInquiry" class="with-width">
                   <label>
                     <input type="checkbox" [value]="noi" (change)="onNOICheckboxChange(noi)" class="intake-form-radio-button" 
                     [checked]="isItemChecked(noi,requestforinformation.requestForInformationNatureOfInquiryVersion)">
                       {{ noi.natureOfInquiry }}
                     </label>
                   </div>
                   
                </div>
               </fieldset>
               <div class="clear-10"></div>
               <fieldset>
                 <mat-form-field appearance="outline" class="w-100" floatLabel="always" [hidden]="!noiOthersVisible" >
                     
                     <input matInput [(ngModel)]="requestforinformation.requestForInformationNatureOfInquiryOtherText" class="form-control-textbox"
                      name="noiOther" placeholder="If Other, Please specify"/>
                   </mat-form-field>
               </fieldset>
               <div class="clear"></div>
               <fieldset>
                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                  <mat-label>What information are you looking for</mat-label>
                    <input matInput [(ngModel)]="requestforinformation.informationLookingFor" class="form-control-textbox"
                     name="noiOther" placeholder="What information are you looking for"/>
                  </mat-form-field>
              </fieldset>
               <fieldset>
                <!-- <re-captcha [siteKey]="'6LdEglUpAAAAAIofwNbYFoTQRkainSDD6H0Phx0I'" [size]="'normal'" [theme]="'light'" name="recaptcha" (resolved)="resolved($event)" required></re-captcha> -->
                <re-captcha [siteKey]="'6LeHIWApAAAAAMOvS3Vg83uKYQ1Zlr1Wrgt_6JSX'" [size]="'normal'" [theme]="'light'" name="recaptcha" (resolved)="resolved($event)" required></re-captcha>
               </fieldset>
               <div class="form-buttons">
    
                <input type="submit" value="Submit" class="submit-form-button" (click)="addRequestForInformationForm()" [disabled]="!captchavalidated" >
                <button type="button"  class="submit-form-button cancel-button" (click)="resetForm()" >Reset Form</button>
                
        
               </div>   
               <div class="clear"></div>
               <fieldset>
                <span class="post-form-info">
             Your personal information is being collected under section 26(c) of the Freedom of Information and Protection of Privacy Act 
             to inquire about information on the Independent Rights Advice Service and to tailor the information provided to you based on your situation.
              If you have any questions about the collection of this personal information, please contact: Provincial Manager, Independent Rights Advice 
              Service, Canadian Mental Health Association, BC Division, 905 - 1130 West Pender Street, Vancouver BC, V6E 4A4.
            </span>
               </fieldset>         
        </form>
    </div>
    <div class="clear"></div>
  
</div>

