import { Component } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { CaseFileService } from 'src/app/services/case-file.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent {
downloadLink:string="";
casefileid:number=0;
pdfbuttondisabled:boolean=true;
pdfbuttonclass="";
constructor(private _casefileservice:CaseFileService,private route:ActivatedRoute,private router:Router){}
ngOnInit() {
  this.route.params.subscribe(params => {
     this.casefileid = params['casefileid'];
  });
  this._casefileservice.getPDFdownloadLinkStatus(this.casefileid).subscribe({
    next:(response) =>{
      if (response!=null && response!="" && response != undefined ){
        this.pdfbuttondisabled = response.forReviewPdfDownloaded;
        this.changeButtonClass();
     }
   },
   error:(err)=>{this.downloadLink="/Login"}
  })
   
    window.scroll(0,0,);
}

downloadButtonClick(){
  this._casefileservice.downloadPDF(this.casefileid).subscribe({
    next:(response:Blob) =>{
      const fileUrl = URL.createObjectURL(response)
      this.pdfbuttondisabled = true;
      this.changeButtonClass();
      window.open(fileUrl);
  }
})

}

changeButtonClass()
{
  if(this.pdfbuttondisabled == true)
  {
  this.pdfbuttonclass = "submit-form-button-disabled"
  }
  else
  {
    this.pdfbuttonclass = "submit-form-button";
  }
}
}
