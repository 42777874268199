<app-authorize></app-authorize>
<app-topnav></app-topnav>

<div class="page-heading">
	<div class="container">
		<h1>Dashboard</h1>
	</div>
</div>
<div class="container">
  <div class="content" role="main">
    <div class="cmha-dashboard">
    <mat-tab-group [selectedIndex]="selectedTab" >
      <mat-tab label="Tab 1" tabindex="1">
        <ng-template mat-tab-label >Request for Rights Advice Cases</ng-template>
        <div class="createButton form-buttons">
          <h1>Request For Rights Advice in Facility</h1><a routerLink="/treatment-form/create"
            class="submit-form-button white-bg"><strong>+</strong> Create case</a>
        </div>
        <app-case-file-list [usertype]="usertype"></app-case-file-list>
      </mat-tab>
      <mat-tab label="Tab 2" tabindex="2">
        <ng-template mat-tab-label>Request for Information Cases</ng-template>
        <div class="createButton form-buttons">
          <h1>Request For Information</h1><a routerLink="/request-for-information-form/create"
            class="submit-form-button white-bg"><strong>+</strong> Create case</a>
        </div>
        <app-request-for-information-forms-list></app-request-for-information-forms-list>
      </mat-tab>
    </mat-tab-group>
  </div>
  <!-- <div class="form-buttons">
    <button (click)="downloadButtonClick()" class="submit-form-button">Download
      PDF Submission</button>
  </div> -->
  </div>
</div>

<app-footer></app-footer>