import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { activateRA, defaultActivationValues } from 'src/app/components/users/activateuser';
import { LoginService } from 'src/app/services/login.service';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
  selector: 'app-activate-ra',
  templateUrl: './activate-ra.component.html',
  styleUrls: ['./activate-ra.component.scss']
})
export class ActivateRightsAdvisorComponent {

  activationDetailsRA: activateRA = defaultActivationValues();
  raEmail = "";
  confirmPassword: string = "";
  constructor(
    private _loginservice: LoginService, 
    private _toastservice: ToastService, 
    private router: Router, 
    private _route: ActivatedRoute
    ) { }

  activateRA(af: NgForm) {
    if (af.invalid) {
      Object.values(af.controls).forEach(control => control.markAllAsTouched());
      return;
    }
    if (this.activationDetailsRA.password != this.confirmPassword) {
      return;
    }
    this._loginservice.activateRa(this.activationDetailsRA).subscribe({
      next: (response) => {
        this._toastservice.showMessage("Account is activated. Please login in to the portal");
        this.router.navigate(['/login']);
      },
      error: (err) => { }
    })
  }

  ngOnInit(): void {
    this._route.params.subscribe(param => {
      this.activationDetailsRA.activationGuid = decodeURIComponent(param['guid']);
      this.raEmail = param['email']
      console.log(this.raEmail);
      console.log(param);
    }
    );
  }
}

