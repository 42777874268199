<div class="form-section-header">
	<div class="container">
		<h3><em>Section 5 IRAS -</em> TO BE COMPLETED BY IRAS INTAKE Coordinator</h3>
	</div>
</div>
<div class="form-subsection-header">
	<div class="container">
		<h4>Additional Information</h4>
	</div>
</div>
<div class="form-subsection-content">
	<div class="container">
		<fieldset>
			<mat-form-field appearance="outline" class="w-100" floatLabel="always">
				<mat-label>Please tell us what you changed (optional)</mat-label>
				<textarea matInput class="form-control-textbox w-100" name="facilitynotessection5iras"
					[(ngModel)]="caseFile.cmhaAdditionalInformationNotes" [disabled]="isDisabled"></textarea>
			</mat-form-field>
		</fieldset>

		<span>
			<input type="checkbox" [(ngModel)]="caseFile.allowSaveOnceConfirmed" [value]="true" name="section5checkbox"
				id="section5checkbox"><label for="section5checkbox">&nbsp; By checking this box I confirm I want to make
				these changes. </label>
		</span>

	</div>
</div>