import { Component, Input } from '@angular/core';
import { UnitService } from 'src/app/services/unit-service';
import { Units, defaultUnitValues } from '../unit';

@Component({
  selector: 'app-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.scss']
})

export class UnitListComponent {
  @Input() facilityid: number;
  unitObj: Units[] = [];
  columnsToDisplay = [
    'Name', 'Address', 'Status'
  ];

  constructor(private unitService: UnitService) {
    this.facilityid = 0
  }

  ngOnInit(): void {
    this.unitService.getFilteredUnitbyFacility(this.facilityid).subscribe({
      next: (data) => {
        this.unitObj = data;
      },
      error: (err) => console.log("Error", err),
    });
  }

  setStatus(a: boolean): string {
    return a ? "Active" : "Deactivated";
  }
}
