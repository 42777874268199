<app-topnav></app-topnav>
<div class="page-heading">
  <div class="container"><h1>Facilities</h1></div>
</div>
<div class="container">
  <div class="content">
    <div class="clear"></div>   
    <a href="/facilities" class="back-to-facilities">&#8592; Back to Facilities</a>
    
    <div class="access-code">
        
      <h3>Deactivate Facility?</h3>
      <div class="clear"></div>
      <span>{{facilityname}},{{city}}</span>
      <div class="clear"></div>
      <div>
        You are attempting to deactivate this facility. Once deactivated, it will not longer appear as an option on the list 
        and the status will change to deactivated. Once deactivated it can no longer be activated.
      </div>
      <div>
        <div class="form-buttons">
                            
            <input
              type="submit"
              (click)="disableFacility()"
              class="submit-form-button" value="Submit"
            />
            <a routerLink="/facilities" class="submit-form-button cancel-button"
              >Cancel</a
            >
         
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
