export interface CaseFileReportQuery
{
    startDate:string,
    endDate:string,
    
}

export function caseFileReportingqueryDefaultValues():CaseFileReportQuery
{
    return{startDate:'',endDate:''}
}