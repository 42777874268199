<div class="container">
    <div class="content" role="main">

        <div class="designatedFacilityForm">

            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="cmhaAgents.cmhaIntakeAgentName" class="form-control-textbox"
                    name="facilityName" />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput [(ngModel)]="cmhaAgents.cmhaIntakeAgentEmail" class="form-control-textbox"
                    name="city" />
            </mat-form-field>

            <div class="about-this-record">
                <p><strong>About this record</strong></p>
                <p>If you save changes to this record</p>
                <ul>
                    <li>There are {{this.closedCaseFileCount}} closed case files linked to this facility.</li>
                    <li>These records will not be updated.</li>
                    <li>There are {{this.openCaseFileCount}} open case files linked to this facility.</li>
                    <li>These records will be updated.</li>
                    <li *ngIf="RADeactivationLine">{{RADeactivationLine}}</li>
                </ul>
            </div>

            <div class="clear"></div>

            <div class="form-buttons">

                <input type="submit" (click)="updateCmhaAgent()" class="submit-form-button" value="Submit" />


            </div>

        </div>
    </div>
</div>