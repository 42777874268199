import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseFileService } from 'src/app/services/case-file.service';

@Component({
  selector: 'app-closed-case-pdf-download',
  templateUrl: './closed-case-pdf-download.component.html',
  styleUrls: ['./closed-case-pdf-download.component.scss']
})
export class ClosedCasePdfDownloadComponent {
  downloadLink:string="";
casefileid:number=0;
pdfbuttondisabled:boolean=true;
constructor(private _casefileservice:CaseFileService,private route:ActivatedRoute,private router:Router){}
ngOnInit() {
  this.route.params.subscribe(params => {
     this.casefileid = params['casefileid'];
  });
  console.log(this.casefileid);
  this._casefileservice.getPDFdownloadLinkStatus(this.casefileid).subscribe({
    next:(response) =>{
      if (response!=null && response!="" && response != undefined ){
        this.pdfbuttondisabled = response.ClosedCasePdfDownloadComponent;
     }
   },
   error:(err)=>{this.downloadLink="/Login"}
  })
   
    window.scroll(0,0,);
}

downloadButtonClick(){
  this._casefileservice.downloadPDF(this.casefileid).subscribe({
    next:(response:Blob) =>{
      const fileUrl = URL.createObjectURL(response)
      window.open(fileUrl);
  }
})

}

}
