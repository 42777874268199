<div class="form-section-header">
	<div class="container">
		<h3><em>Section 4 IRAS -</em> ADDITIONAL INFORMATION TO BE COMPLETED BY INTAKE COORDINATOR</h3>
	</div>
</div>
<div class="form-subsection-header">
	<div class="container">
		<h4>Additional Information</h4>
	</div>
</div>
<div class="form-subsection-content">
	<div class="container">
		<fieldset>
			<label id="label-raaddinfomeetingstatus">Meeting Status</label>
		</fieldset>
		<hr />
		<fieldset ngForm #meetingStatusForm="ngForm">
			<mat-radio-group name="cmhaaddinfomeetingstatusGroup" #cmhaaddinfomeetingstatusGroup="ngModel"
				aria-labelledby="label-raaddinfomeetingstatus" class="intake-form-radio-group"
				[(ngModel)]="caseFile.cmhaAdditionalInformationMeetingStatusVersionId" [disabled]="isDisabled"
				(change)="onCMHAAdditionalInfoMeetingStatusChange($event)" required>
				<mat-radio-button name="cmhaaddinfomeetingstatus" class="intake-form-radio-button"
					*ngFor="let ms of cmhaadditionalinfomeetingStatus"
					[value]="ms.id">{{ms.cmhaAdditionalInfoMeetingStatusName}}</mat-radio-button>
			</mat-radio-group>
		</fieldset>
		
		<mat-error
		*ngIf="isSaveAndCloseClicked && caseFile.cmhaAdditionalInformationMeetingStatusVersionId == null">Please
		select meeting status.</mat-error>
	</div>
</div>