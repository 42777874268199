import { UserDetails, defaultUserDetails } from "../users/userdetail";

export interface Units {
    unitVersionId: number;
    unitName: string;
    address: string;
    accessCode: string;
    facilityVersionId: number;
    isActive: boolean;
    userDetails: UserDetails;
    facilityName: string
}

export function defaultUnitValues(): Units {
    const defaultUserInfo: UserDetails = defaultUserDetails();
    const defaultUnitDetails: Units = {
        unitVersionId: 0,
        unitName: "",
        address: "",
        accessCode: "",
        facilityVersionId: 0,
        facilityName: "",
        userDetails: defaultUserInfo,
        isActive: true
    }

    return defaultUnitDetails;
}