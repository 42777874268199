import { Component, Input } from '@angular/core';
import { BlankCaseFile, CaseFile } from '../../case-file/case-file';
import { CaseFileOptionService } from 'src/app/services/case-file-option.service';
import { UnitService } from 'src/app/services/unit-service';
import { DesignatedFacilityService } from 'src/app/services/designated-facility.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationRoles } from '../../users/user-roles';
import { format } from "date-fns";
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'app-section2',
	templateUrl: './section2.component.html',
	styleUrls: ['./section2.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class Section2Component {
	@Input() caseFile: CaseFile = BlankCaseFile();
	@Input() isDisabled: boolean = false;
	@Input() submittedErrors: any;
	@Input() parentSubmitted: boolean = false;
	@Input() ICSaveClicked:boolean=false;


	maxDate = new Date();

	facilityList: any[] = [];
	unitObj: any[] = [];
	unitaddress: string = "";
	facilityCity: string = "";
	facilityid: number = 0;
	facilityUnitName: string = "";

	certificateLengths: any[] = [];
	patientAdmitRequests: any[] = [];
	patientAdmitRequestUnder16: any[] = [];
	patientAdmitRequestAbove16: any[] = [];

	facilityname: string = "";
	username = "";
	unitname = "";

	disablefordf: boolean = false;

	constructor(
		private casefileoptionService: CaseFileOptionService,
		public unitService: UnitService,
		private designatedFacilityService: DesignatedFacilityService,
		private authservice: AuthService
	) { }

	ngOnInit(): void {
		this.designatedFacilityService.getActiveFacilities().subscribe({
			next: (data) => {
				this.facilityList = data;
				this.selectFacilityForDFUser()
			},
			error: (e) => console.log(e),
			complete: () => { }
		});

		this.casefileoptionService.getCertificateLength().subscribe({
			next: (certlength) => { this.certificateLengths = certlength },
			error: (err) => { console.log(err) },
			complete: () => { }
		});

		this.casefileoptionService.getPatientAdmitRequest().subscribe({
			next: (patientadmitrequest) => {
				this.patientAdmitRequestUnder16 = patientadmitrequest.filter(age => age.isUnderSixteen);
				this.patientAdmitRequestAbove16 = patientadmitrequest.filter(age => !age.isUnderSixteen);
			},
			error: (err) => { console.log('Error', err); },
			complete: () => { }
		});

		console.log(this.maxDate);
	}

	selectFacilityForDFUser() {
		let role = this.authservice.getRole();
		this.username = this.authservice.getUserLoginData().username;
		if (role == ApplicationRoles.DESIGNATED_FACILITY) {
			let user = this.username.split(':');
			this.facilityname = user[0];
			this.unitname = user[1];
			const selectedItem = this.facilityList.find(item => item.facilityName == this.facilityname);
			if (selectedItem) {
				this.facilityid = selectedItem.designatedFacilityVersionId;
				this.drpfacilitychange();
			}
		}
	}

	drpfacilitychange(): void {
		this.unitService.getFilteredUnitbyFacility(this.facilityid).subscribe({
			next: (data) => {
				this.unitObj = data;
				this.setUnitForDFUser();
			},
			error: (err) => console.log("Error", err),

		});
	}

	setUnitForDFUser() {
		let role = this.authservice.getRole();
		if (role = ApplicationRoles.DESIGNATED_FACILITY) {
			const selectedItem = this.unitObj.find(item => item.unitName == this.unitname);
			if (selectedItem) {
				this.caseFile.unitVersionId = selectedItem.unitVersionId;
				this.setUnitData();
			}
		}
	}

	setUnitData(): void {
		var filteredunit = this.unitObj.filter(x => x.unitVersionId == this.caseFile.unitVersionId);
		this.unitaddress = filteredunit[0].address;
		var filteredfacility = this.facilityList.filter(x => x.designatedFacilityVersionId == this.facilityid);
		this.facilityCity = filteredfacility[0].cityName
		let role = this.authservice.getRole();
		if (role == ApplicationRoles.DESIGNATED_FACILITY) {
			this.disablefordf = true;
		}
	}

	hasError(errorKey: string): boolean {
		if (this.submittedErrors == undefined || this.submittedErrors.length == 0)
			return false;

		let error = this.submittedErrors[errorKey];
		if (error !== undefined)
			return true;

		return false;
	}

	validateSection2():boolean
	{
		let flag=true;
		if(this.facilityid ==0)
		{
			flag=false;
		}
		if(this.caseFile.unitVersionId==undefined)
		{
			flag=false;
		}
		if(this.unitaddress == '')
		{
			flag = false
		}
		if(this.facilityCity == '')
		{
			flag=false
		}
		if(this.caseFile.unitPhone == '')
		{
			flag=false
		}
	
		if(this.caseFile.unitEmail == '')
		{
			flag=false
		}
		if(this.caseFile.primaryContactPersonName == '')
		{
			flag=false
		}
		if(this.caseFile.certificateLengthVersionId == undefined)
		{
			flag=false
		}
		if(this.caseFile.dateOfInvoluntaryAdmission == undefined)
		{
			flag=false
		}
		if(this.caseFile.patientAdmitRequestVersionId == undefined)
		{
			flag=false
		}
		if(this.caseFile.facilityNotes == '')
		{
			flag=false
		}
		return flag;
	}
}
