<app-topnav></app-topnav>
<div class="page-heading">
  <div class="container">
    <h1>Activate Rights Advisor Account</h1>
  </div>
</div>
<form #activateForm="ngForm">
<div class="container">
  <div class="content" role="main">
     <div class="facility-heading w-70">Activate Rights Advisor Account</div>
    <div class="designatedFacilityForm w-70">
        <input type="hidden" [(ngModel)]="activationDetailsRA.activationGuid">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="raEmail" class="form-control-textbox" name="email" disabled="true" />
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Password</mat-label>
        <input matInput type="password" [(ngModel)]="activationDetailsRA.password" name="newPassword" class="form-control-textbox" required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}" #newpassword="ngModel"/>
          <mat-error *ngIf="newpassword.invalid && newpassword.touched"> 
            Password must be at least 12 characters, including at least one uppercase letter, one lowercase letter, and one number
          </mat-error>
      </mat-form-field>
      <div class="clear-10"></div>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Repeat Password</mat-label>
        <input matInput type="password" class="form-control-textbox" [(ngModel)]="confirmPassword"
          name="facilityName" />
      </mat-form-field>
      <div class="clear-10"></div>
      <div *ngIf="activationDetailsRA.password != confirmPassword"><span class="confirm-password-error">Passwords do not match.</span></div>

      <div class="form-buttons">
         <div class="form-buttons-with-forgot-password">
        <input type="submit" (click)="activateRA(activateForm)" class="submit-form-button" value="Submit" />
       
        </div>
      </div>
    </div>
    <div>

    </div>
    <div>

    </div>
  </div>
</div>
</form>

<app-footer></app-footer>