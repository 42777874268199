


<div class="container">
  <div class="content" role="main">
    <div class="facility-heading">Change Password</div>
    <form #changepasswordform="ngForm">
    <div class="designatedFacilityForm">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Old Password</mat-label>
        <input matInput class="form-control-textbox" [(ngModel)]="changePasswordObj.oldPassword" name="oldPassword" type="password" required #oldpassword="ngModel"/>
        <mat-error *ngIf="oldpassword.invalid && oldpassword.touched">Field is required</mat-error>
      </mat-form-field>
      <div class="clear-10"></div>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>New Password</mat-label>
        <input matInput class="form-control-textbox"  [(ngModel)]="changePasswordObj.newPassword" name="newPassword" type="password" required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}" #newpassword="ngModel"/>  
        <mat-error *ngIf="newpassword.invalid && newpassword.touched">
          Password must be at least 12 characters, including at least one uppercase letter, one lowercase letter, and one number
       </mat-error>
      </mat-form-field>
      <div class="clear-10"></div>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Confirm Password</mat-label>
        <input matInput class="form-control-textbox" name="confirmPassword" type="password" [(ngModel)]="confirmPassword" required #confirmpassword="ngModel"/>     
        <mat-error *ngIf="confirmpassword.invalid && confirmpassword.touched">Password do not match</mat-error>         
      </mat-form-field>

      <div class="clear-10"></div>
      <div *ngIf="changePasswordObj.newPassword != confirmPassword"><span class="confirm-password-error">Passwords do not match.</span></div>
      <div *ngIf=""></div>
      <div class="form-buttons">
        <input type="submit" class="submit-form-button" (click)="changePassword(changepasswordform)" value="Submit">
        <a [routerLink]="cancelLink" class="submit-form-button cancel-button">Cancel</a
        >
      </div>
    </div>
</form>
  </div>
</div>
