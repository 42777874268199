export interface RequestForInformationSection {
  sectionRequestReceivedInfoVisible: boolean;
  sectionRequestReceivedInfoEnabled: boolean;
  sectionPersonalInfoVisible: boolean;
  sectionPersonalInfoEnabled: boolean;
  sectionAdditionalInfoVisible: boolean;
  sectionAdditionalInfoEnabled: boolean;
  submitButtonVisible: boolean;
  saveButtonVisible: boolean;
  cancelButtonVisible: boolean;
  saveAndCloseButtonVisible: boolean;
}

export function defaultRFISectionValues(): RequestForInformationSection {
  return {
    sectionRequestReceivedInfoVisible: true,
    sectionRequestReceivedInfoEnabled: false,
    sectionPersonalInfoVisible: true,
    sectionPersonalInfoEnabled: true,
    sectionAdditionalInfoVisible: true,
    sectionAdditionalInfoEnabled: false,
    submitButtonVisible: false,
    saveButtonVisible: false,
    cancelButtonVisible: false,
    saveAndCloseButtonVisible: false,
  };
}
