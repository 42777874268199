import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ForgotPassword } from '../components/users/forgot-password';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  apiUrl = environment.apiUrl

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient) { }

  forgotpassword(fp: ForgotPassword): Observable<any> {
    return this.http.post<any>(this.apiUrl + "ForgotPassword", fp);
  }

  getUsernameFromGuid(guid: string): Observable<ForgotPassword> {
    return this.http.get<ForgotPassword>(this.apiUrl + "ForgotPassword/getUsername/" + guid);
  }


}