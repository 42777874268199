<app-topnav></app-topnav>
<div class="clear"></div>
<div class="page-heading">
  <h1>Facilities</h1>
</div>
<div class="container">
  <div class="content">
    <div class="clear"></div>   
    <a href="/facilities" class="back-to-facilities">&#8592; Back to Facilities</a>
    
    <div class="access-code">
        
      <h3>Request New Access Code??</h3>
      <div class="clear"></div>
      <span>{{facilityname}},{{city}}</span>
      <div class="clear"></div>
      <div>
        Maecenas eget turpis at est hendrerit aliquet sit amet nec nisi. Vestibulum rutrum diam a lobortis ultrices. Ut tempus purus sapien, 
        at luctus augue pharetra vitae. Curabitur scelerisque id justo non accumsan. Sed ut odio metus. Sed iaculis non quam suscipit egestas. 
        Nunc fringilla pretium nulla, non sollicitudin urna scelerisque ac.”
        Maecenas eget turpis at est hendrerit aliquet sit amet nec nisi. Vestibulum rutrum diam a lobortis ultrices. Ut tempus purus sapie.
      </div>
      <div>
        <div class="form-buttons">
                            
            <input
              type="submit"
              (click)="generateCodeFacility()"
              class="submit-form-button" value="Submit"
            />
            <a routerLink="/facilities" class="submit-form-button cancel-button"
              >Cancel</a
            >
         
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
