
<div class="form-section-header">
    <div class="container">
      <h3 class="padding-left-10">Section 2 </h3>
    </div>
  </div>
  <div class="container">
  <div class="form-subsection-header">
       <h4 class="padding-left-10">Personal Information</h4>
    </div>
  </div>
  <div class="form-subsection-content">
    <div class="container">
      <fieldset>
  
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>First Name</mat-label>
          <input matInput [(ngModel)]="requestforinformation.firstName" class="form-control-textbox" name="firstName"
            [disabled]="isDisabled" required />
        </mat-form-field>
  
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Last Name (Optional)</mat-label>
          <input matInput [(ngModel)]="requestforinformation.lastName" class="form-control-textbox" name="lastName"
            [disabled]="isDisabled" />
        </mat-form-field>
  
      </fieldset>
      <fieldset>
  
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Phone Number (Optional)</mat-label>
          <input matInput [(ngModel)]="requestforinformation.phoneNumber" class="form-control-textbox"
            [disabled]="isDisabled" name="phonenumber" />
            
        </mat-form-field>
        
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Email (Optional)</mat-label>
          <input matInput [(ngModel)]="requestforinformation.email" class="form-control-textbox" name="email" [disabled]="isDisabled" />
        </mat-form-field>
        </fieldset>
        <fieldset>
            <label id="intake-form-radio-group-label">Please select one of the following options:</label>
            </fieldset> 
            <fieldset>
            <mat-radio-group name="radtreatmentoptiongroup" [(ngModel)]="requestforinformation.requestForInformationTreatmentOptionsVersionId"
              aria-labelledby="intake-form-radio-group-label" class="intake-form-radio-group display-vertical" (change)="radTreatmentOptionsChange()"
              [disabled]="isDisabled" required>
              <mat-radio-button name="radioTreatmentOptions" *ngFor="let to of treatmentOptions"
                [value]="to.id" class="intake-form-radio-button">
                {{to.treatmentOption}}
              </mat-radio-button>
              
            </mat-radio-group>
           
          </fieldset>
          <fieldset>
            <div class="rfi-other-textbox">
              <input type="text" matInput class="form-control-textbox " [hidden]="!treatmentOptionOtherTextVisible" 
              [(ngModel)]="requestforinformation.requestForInformationTreatmentOptionsOtherText">
            </div>
          </fieldset>
          <div class="clear"></div>
          <fieldset>
            <mat-form-field appearance="outline" class="w-100" floatLabel="always">
              <mat-label>What information are you looking for</mat-label>
                <input matInput [(ngModel)]="requestforinformation.informationLookingFor" class="form-control-textbox"
                 name="noiOther" placeholder="What information are you looking for"/>
              </mat-form-field>
          </fieldset>
       </div>
  </div>
