import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlankCaseFile, CaseFile } from '../../case-file/case-file';
import { MatSelectChange } from '@angular/material/select';

@Component({
	selector: 'app-section-assign-ra',
	templateUrl: './section-assign-ra.component.html',
	styleUrls: ['./section-assign-ra.component.scss']
})
export class SectionAssignRaComponent {
	@Input() caseFile: CaseFile = BlankCaseFile();
	@Input() isDisabled: boolean = false;

	@Output() raChanged: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
	raList: any[] = [];

	OnRaChange(event: MatSelectChange) {
	this.raChanged.emit(event);
	}
}
