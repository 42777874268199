import { UserDetails, defaultUserDetails } from "../users/userdetail";
import { RequestForInformationAssistanceProvided } from "./RequestForInformationAssistanceProvided";
import { RequestForInformationFollowUpNeeded } from "./RequestForInformationFollowUpNeeded";
import { RequestForInformationNatureOfInquiry } from "./RequestForInformationNatureOfInquiry";
import { RequestForInformationReferralsMade } from "./RequestForInformationReferralsMade";

export interface RequestForInformation {
    id:number,
    requestReceivedOnDate: string;
    requestReceivedOnTime:string;
    requestForInformationInquiryReceivedVersionId: number;
    requestForInformationInquiryReceivedOtherText:String,
    firstName: string ;
    lastName: string ;
    email: string;
    phoneNumber: string;
    requestForInformationTreatmentOptionsVersionId: number;
    requestForInformationTreatmentOptionsOtherText: string;

    requestForInformationNatureOfInquiryVersion: RequestForInformationNatureOfInquiry[];
    requestForInformationNatureOfInquiryOtherText:string,
    
    assistanceProvided:boolean|null,
    requestForInformationAssistanceProvidedVersion: RequestForInformationAssistanceProvided[];
    requestForInformationAssistanceProvidedOtherText:string,
    requestForInformationAssistanceProvidedVersionToBeValidated:boolean,

    referralsMade:boolean|null,
    requestForInformationReferralsMadeVersion: RequestForInformationReferralsMade[];
    requestForInformationReferralsMadeOtherText: string;
    requestForInformationReferralsMadeVersionToBeValidated:boolean,

    requestForInformationFollowUpNeededVersion: RequestForInformationFollowUpNeeded[];
    requestForInformationFollowUpNeededOtherText:string,
    notes: string;
    informationLookingFor:string,
    userDetails: UserDetails;
    
}

export function defaultRequestForInformationValues():RequestForInformation{
return {
    id:0,
    requestReceivedOnDate:'',
    requestReceivedOnTime:'',
    requestForInformationInquiryReceivedVersionId: 0,
    requestForInformationInquiryReceivedOtherText:'',
    firstName: '' ,
    lastName: '' ,
    email: '',
    phoneNumber: '',
    requestForInformationTreatmentOptionsVersionId: 0,
    requestForInformationTreatmentOptionsOtherText: '',

    requestForInformationNatureOfInquiryVersion: [],
    requestForInformationNatureOfInquiryOtherText:'',
    
    assistanceProvided:null,
    requestForInformationAssistanceProvidedVersion: [],
    requestForInformationAssistanceProvidedOtherText:'',
    requestForInformationAssistanceProvidedVersionToBeValidated:false,

    referralsMade:null,
    requestForInformationReferralsMadeVersion: [],
    requestForInformationReferralsMadeOtherText: '',
    requestForInformationReferralsMadeVersionToBeValidated:false,

    requestForInformationFollowUpNeededVersion: [],
    requestForInformationFollowUpNeededOtherText:'',
    notes: '',
    informationLookingFor:'',
    userDetails: defaultUserDetails(),
}
}