import { Component } from '@angular/core';

@Component({
  selector: 'app-email-queue',
  templateUrl: './email-queue.component.html',
  styleUrls: ['./email-queue.component.scss']
})

export class EmailQueueComponent {
}
