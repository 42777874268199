import { CaseFileStatus } from "./case-file-status";

export interface caseFileSections {
    sectionAssignRAVisible: boolean,
    sectionAssignRAEnabled: boolean,
    sectionNoteToRAVisible: boolean,
    sectionNoteToRAEnabled: boolean,
    sectionCaseFileDetailsVisible: boolean,
    sectionCaseFileDetailsEnabled: boolean,
    sectionRequestReceivedInfoVisible: boolean,
    sectionRequestReceivedInfoEnabled: boolean,
    section1Visible: boolean,
    section1Enabled: boolean, 
    section2Visible: boolean, 
    section2Enabled: boolean,
    section3IRASVisible: boolean,
    section3IRASEnabled: boolean,
    section3RAVisible: boolean,
    section3RAEnabled: boolean,
    section4IRASVisible: boolean,
    section4IRASEnabled: boolean,
    section4RAVisible: boolean,
    section4RAEnabled: boolean,
    section5IRASVisible: boolean,
    section5IRASEnabled: boolean,
    sectionPDFIssueEnabled:boolean,
    sectionPDFIssueVisible:boolean,
    caseFileStatus: CaseFileStatus,
    submitButtonVisibility:boolean,
    editButtonVisibility:boolean,
    cancelButtonVisibility:boolean,
    saveAndCloseButtonVisibility:boolean,
    editButtonText:string
}

export function defaultCaseFileSectionValues():caseFileSections
{
    const defaultSectionValues : caseFileSections = {
        sectionAssignRAVisible:false,
        sectionAssignRAEnabled: false,
        sectionNoteToRAVisible: false,
        sectionNoteToRAEnabled: false,
        sectionCaseFileDetailsVisible: false,
        sectionCaseFileDetailsEnabled: false,
        sectionRequestReceivedInfoVisible: false,
        sectionRequestReceivedInfoEnabled: false,
        section1Visible: false,
        section1Enabled: false ,
        section2Visible: false ,
        section2Enabled: false,
        section3IRASVisible: false,
        section3IRASEnabled: false,
        section3RAVisible: false,
        section3RAEnabled: false,
        section4IRASVisible: false,
        section4IRASEnabled: false,
        section4RAVisible: false,
        section4RAEnabled: false,
        section5IRASVisible: false,
        section5IRASEnabled: false ,
        sectionPDFIssueEnabled:false,
        sectionPDFIssueVisible:false,
        caseFileStatus: CaseFileStatus.Create,
        submitButtonVisibility:false,
        editButtonVisibility:false,
        cancelButtonVisibility:false,
        saveAndCloseButtonVisibility:false,
        editButtonText:"",
    }
    return defaultSectionValues;
}