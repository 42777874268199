import { HttpStatusCode } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  RequestForInformation,
  defaultRequestForInformationValues,
} from 'src/app/components/request-for-information/request-for-information';
import {
  RequestForInformationSection,
  defaultRFISectionValues,
} from 'src/app/components/request-for-information/request-for-information-section-model';
import { SectionRequestforinformationAdditionalInfoComponent } from 'src/app/components/request-for-information/request-for-information-sections/section-requestforinformation-additional-info/section-requestforinformation-additional-info.component';
import { RequstForInformationStatus } from 'src/app/components/request-for-information/request-for-information-status';
import { defaultUserDetails } from 'src/app/components/users/userdetail';
import { ModalService } from 'src/app/services/modal.service';
import { RequestForInformationFormService } from 'src/app/services/request-for-information-form.service';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
  selector: 'app-request-for-information-form',
  templateUrl: './request-for-information-form.component.html',
  styleUrls: ['./request-for-information-form.component.scss'],
})
export class RequestForInformationFormComponent {

  requestForInformationForm: RequestForInformation =
    defaultRequestForInformationValues();
  action: string = 'create';
  rfiFormId: number = 0;
  rfiFormSections: RequestForInformationSection = defaultRFISectionValues();
  @ViewChild(SectionRequestforinformationAdditionalInfoComponent)
  sectionAdditionalInfo!: SectionRequestforinformationAdditionalInfoComponent;
  modalOpen = this._modalservice.getModalState();
  modalTitle: string = '';
  modalContent: string = '';
  modalOkButtonValue: string = '';
  modalCancelButtonValue: string = '';
  modalOkButtonText: string = '';
  modalCancelButtonText: string = '';
  modalSub!: Subscription;
  validationErrors: string[];

  constructor(
    private _rfiFormService: RequestForInformationFormService,
    private router: Router,
    private _toastService: ToastService,
    private route: ActivatedRoute,
    private _modalservice: ModalService
  ) {
    this.validationErrors=[];
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      //this.action = params['action'];
      this.action = this.route.snapshot.data['action'];
      const rfiformid = params['formid'];
      if (rfiformid === undefined) this.rfiFormId = 0;
      else this.rfiFormId = this.requestForInformationForm.id = rfiformid;
    });

    this.setFiledsByAction(this.action);
    this.setSectionByStatus();
    this.afterConfirmModalOps();
  }

  checkAssistanceProvidedQuestion() {
    
  }

  checkReferralMadeQuestion() {
    
  }

  setFiledsByAction(action: string) {
    switch (action) {
      case 'view':
        this._rfiFormService
          .getRequestForInformationFormDetails(this.rfiFormId)
          .subscribe({
            next: (data) => {
              this.requestForInformationForm = data;
              this.sectionAdditionalInfo.radAssistanceProvided(this.requestForInformationForm.assistanceProvided);
              this.sectionAdditionalInfo.radReferralMade(this.requestForInformationForm.referralsMade);
              this.setOthersInputFields();
            },
          });
        break;
      case 'create':
        break;
    }
  }

  setSectionByStatus() {
    this._rfiFormService
      .getRequestForInformationFormSections(this.rfiFormId)
      .subscribe({
        next: (sections) => {
          this.rfiFormSections = sections;
        },
      });
  }

  setOthersInputFields() {
    this.sectionAdditionalInfo.noiOthersVisible = false;
    this.sectionAdditionalInfo.apOtherVisible = false;
    this.sectionAdditionalInfo.rmOtherVisible = false;
    this.sectionAdditionalInfo.funOthersVisible = false;
    if (
      this.requestForInformationForm.requestForInformationNatureOfInquiryVersion.find(
        (z) => z.natureOfInquiry == 'Other'
      )
    ) {
      this.sectionAdditionalInfo.noiOthersVisible = true;
    }
    if (
      this.requestForInformationForm.requestForInformationAssistanceProvidedVersion.find(
        (z) => z.assistanceProvided == 'Other'
      )
    ) {
      this.sectionAdditionalInfo.apOtherVisible = true;
    }

    if (
      this.requestForInformationForm.requestForInformationReferralsMadeVersion.find(
        (z) => z.referralMade == 'Other'
      )
    ) {
      this.sectionAdditionalInfo.rmOtherVisible = true;
    }

    if (
      this.requestForInformationForm.requestForInformationFollowUpNeededVersion.find(
        (z) => z.followUpNeeded == 'Other'
      )
    ) {
      this.sectionAdditionalInfo.funOthersVisible = true;
    }
  }

  askConfirmation(
    okButtonText: string,
    okButtonValue: string,
    cancelButtonText: string,
    cancelButtonValue: string,
    content: string,
    title: string
  ) {
    this.modalContent = content;
    this.modalTitle = title;
    this.modalOkButtonValue = okButtonValue;
    this.modalCancelButtonValue = cancelButtonValue;
    this.modalOkButtonText = okButtonText;
    this.modalCancelButtonText = cancelButtonText;

    this._modalservice.openModal();
  }

  afterConfirmModalOps() {
    this.modalSub = this._modalservice
      .getButtonClicked()
      .subscribe((button) => {
        switch (button) {
          case 'RFISAVEANDCLOSE':
            this.requestForInformationForm.userDetails = defaultUserDetails();
            this._rfiFormService
              .saveAndCloseRequestForInformationForm(
                this.requestForInformationForm
              )
              .subscribe({
                next: (data) => {
                  this._toastService.showMessage('Record Saved successfully');
                  this.router.navigate(['/dashboard-cmha/1']);
                },
                error: (error) => {
                  if (error.status === HttpStatusCode.BadRequest) {
                    this.validationErrors = [];
                    let validationErrorDictionary = error.error;
                    for (let fieldName in validationErrorDictionary) {
                      if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                        this.validationErrors.push(validationErrorDictionary[fieldName]);
                      }
                    }
                    
          
                  }
                  else {
                    console.log(error, 'Unable to connect to CaseFile API Endpoint');
                  }
                  window.scroll(0,0,);
                }
              });
            break;
            default:
              this._modalservice.closeModal();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.modalSub) {
      this.modalSub.unsubscribe();
    }
  }

  //#region formOps
  addRequestForInformationForm() {
    this._rfiFormService
      .saveRequestForInformationForm(this.requestForInformationForm)
      .subscribe({
        next: (data) => {
          this._toastService.showMessage('Record Saved successfully');
          this.router.navigate(['/dashboard-cmha/1']);
        },
        error: (error) => {
          if (error.status === HttpStatusCode.BadRequest) {
            this.validationErrors = [];
            let validationErrorDictionary = error.error;
            for (let fieldName in validationErrorDictionary) {
              if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                this.validationErrors.push(validationErrorDictionary[fieldName]);
              }
            }
            
  
          }
          else {
            console.log(error, 'Unable to connect to CaseFile API Endpoint');
          }
          window.scroll(0,0,);
        }
      });
  }

  updateRequestForInformationForm() {
    this.requestForInformationForm.userDetails = defaultUserDetails();
    this._rfiFormService
      .updateRequestForInformationForm(this.requestForInformationForm)
      .subscribe({
        next: (data) => {
          this._toastService.showMessage('Record Saved successfully');
          this.router.navigate(['/dashboard-cmha/1']);
        },
        error: (error) => {
          if (error.status === HttpStatusCode.BadRequest) {
            this.validationErrors = [];
            let validationErrorDictionary = error.error;
            for (let fieldName in validationErrorDictionary) {
              if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                this.validationErrors.push(validationErrorDictionary[fieldName]);
              }
            }
            
  
          }
          else {
            console.log(error, 'Unable to connect to CaseFile API Endpoint');
          }
          window.scroll(0,0,);
        }
      });
  }

  saveAndCloseRequestForInformationForm() {
    this.askConfirmation(
      'Save and close',
      'RFISAVEANDCLOSE',
      'Cancel',
      'CANCEL',
      'Are you sure you want to save and close this case? You will not be able to make changes to this case once it is closed.',
      'Save and Close Case'
    );
  }
  //#endregion

  goback() {
    this.router.navigate(['/dashboard-cmha/1']);
    }
}
