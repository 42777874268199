import { ElementRef, Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { EmailMessage } from '../email-message';
import { EmailQueueService } from 'src/app/services/email-queue-service';
import { EmailQueueQuery, defaultEmailQueueQueryValues } from '../email-queue-query';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NoResultsComponent } from '../../no-results/no-results.component';

@Component({
  selector: 'app-email-queue-list',
  templateUrl: './email-queue-list.component.html',
  styleUrls: ['./email-queue-list.component.scss']
})

export class EmailQueueListComponent implements OnInit {
  totalRecords: number = 25;
  emailMessages: EmailMessage[] = [];
  queryFilter: EmailQueueQuery = defaultEmailQueueQueryValues();
  columnsToDisplay = [
    'Recipient', 'Subject', 'Created', 'Status'
  ]

  @ViewChild('submitButton') submitButton!: ElementRef;

  pageEvent!: PageEvent;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('noResults') noResults!: NoResultsComponent;
  
  constructor(private emailQueueService: EmailQueueService)  {  }

  ngOnInit(): void {
    this.searchWithFilters();
  }

  private searchWithFilters() {
    this.emailQueueService.getAllEmailQueueList(this.queryFilter).subscribe({
      next: (data) => {
        let headers = data.headers.get('x-pagination');
        if (headers) {
          let parsedHeaders = JSON.parse(headers);
          this.totalRecords = parsedHeaders.TotalCount;
          this.noResults.setTotal(this.totalRecords);
        }
        this.emailMessages = data.body ? data.body : [];
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => { }
    });
  }

  search() {
    this.queryFilter.PageNumber = 0;
    this.paginator.pageIndex = 0;

    this.searchWithFilters();
  }

  resetFilters() {
    this.queryFilter = defaultEmailQueueQueryValues();
    this.paginator.pageSize = this.queryFilter.PageSize;
    this.paginator.pageIndex = this.queryFilter.PageNumber;

    this.searchWithFilters();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.queryFilter.PageNumber = e.pageIndex;
    this.queryFilter.PageSize = e.pageSize;

    this.searchWithFilters();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.submitButton.nativeElement.click();
      event.preventDefault();
    }
  }
}
