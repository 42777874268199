import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { RightsAdvisor } from '../components/rights-advisor/rights-advisor'; 
import { environment } from 'src/environments/environment';
import { CMHAAgents } from '../components/cmha-agents/CMHAAgents';
import { AuthService } from './auth.service';

//write code for service 
@Injectable({
  providedIn: 'root'
})
export class CMHAAgentService {

  private cmhaagenturl = environment.apiUrl + 'CMHAIntakeAgents';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

  constructor(private http: HttpClient,private _authservice:AuthService) { }

  getIntakeAgents(): Observable<CMHAAgents[]> {
    return this.http.get<CMHAAgents[]>(this.cmhaagenturl+'/getIntakeAgents');
  }

  updateCMHAAgent(cmhaagent: CMHAAgents): Observable<CMHAAgents> {
    const token = this._authservice.getAuthToken();
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.http.post<CMHAAgents>(this.cmhaagenturl + '/updateCMHAAgent', cmhaagent, { headers: headers });
  }

  getCMHAAgentDetailsFromUserID():Observable<CMHAAgents>{
    const token = this._authservice.getAuthToken();
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.http.get<CMHAAgents>(this.cmhaagenturl + '/getCMHADetailsFromUserID', { headers: headers })
  }

//   addRightsAdvisor(rightsAdvisor: RightsAdvisor): Observable<RightsAdvisor> {
//     return this.http.post<RightsAdvisor>(this.rightsAdvisorUrl, rightsAdvisor);
//   }

//   updateRightsAdvisor(rightsAdvisor: RightsAdvisor): Observable<RightsAdvisor> {
//     return this.http.post<RightsAdvisor>(this.rightsAdvisorUrl + '/updateRA', rightsAdvisor);
//   }

//   disableRightsAdvisor(id: number): Observable<any> {
//     return this.http.post<any>(this.rightsAdvisorUrl + '/disableRA/' + id,null)}
}