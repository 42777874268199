export interface AuthModel{
    userDbId: number,
    token: string,
    expiryUtc: string,
    isLoginSuccessful: boolean,
    roles: number,
    passwordChangeRequiredDays: number,
    agentName: string,
    username:string
}

export function defaultAuthModelValues():AuthModel{
    return{userDbId:0,token:'',expiryUtc:'',isLoginSuccessful:false,roles:0,passwordChangeRequiredDays:0,agentName:'',username:''}
}