import { Component, Input } from '@angular/core';
import { RequestForInformation, defaultRequestForInformationValues } from '../../request-for-information';
import { RequestForInformationFormOptionService } from 'src/app/services/request-for-information-form-option.service';

@Component({
  selector: 'app-section-requestforinformation-personal-info',
  templateUrl: './section-requestforinformation-personal-info.component.html',
  styleUrls: ['./section-requestforinformation-personal-info.component.scss']
})
export class SectionRequestforinformationPersonalInfoComponent {


  @Input() requestforinformation:RequestForInformation=defaultRequestForInformationValues();
  @Input() isDisabled:boolean=false;
  treatmentOptions:any[]=[];
  treatmentOptionOtherTextVisible: boolean=false;
  constructor(private _rfioptionservice:RequestForInformationFormOptionService){

  }

  ngOnInit():void{
    this._rfioptionservice.getTreatmentOptions().subscribe((data)=>this.treatmentOptions=data);
  }

  radTreatmentOptionsChange() {
    this.treatmentOptionOtherTextVisible=false
    const selectedId=this.requestforinformation.requestForInformationTreatmentOptionsVersionId;
    const filteredResult = this.treatmentOptions.filter(to=>to.id==selectedId)
    if(filteredResult[0].treatmentOption == "Other")
    {
      this.treatmentOptionOtherTextVisible=true;
    }
    
    }
}
