import { Component, Input } from '@angular/core';
import { RequestForInformation, defaultRequestForInformationValues } from '../request-for-information';
import { RequestForInformationAuditLog } from '../request-for-information-audit-log';
import { RequestForInformationFormService } from 'src/app/services/request-for-information-form.service';
import { AuditEventTypeEnumDescriptionService } from '../../case-file/case-file-audit-log-list/audti-event-type';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-request-for-information-audit-log-list',
  templateUrl: './request-for-information-audit-log-list.component.html',
  styleUrls: ['./request-for-information-audit-log-list.component.scss']
})
export class RequestForInformationAuditLogListComponent {
  @Input() rfiForm: RequestForInformation = defaultRequestForInformationValues();

  auditlogVisible: boolean = false;
  rfiAuditLogs: RequestForInformationAuditLog[] = [];
  
  columnsToDisplay = ['RFIFormId', 'Date','Event', 'Details']
  
  
  constructor(private _rfiservice: RequestForInformationFormService,public enumdescservice:AuditEventTypeEnumDescriptionService,private datePipe:DatePipe) { }

  ngOnInit(): void {
    console.log(this.rfiForm.id)
    this._rfiservice.getRFIFormAuditLog(this.rfiForm.id).subscribe((data) => {
      this.rfiAuditLogs = data
      this.auditlogVisible = this.rfiAuditLogs.length > 0;
     
    });
  }

  checkIfValidDate(inputString: string): string {
    const date = new Date(inputString);
    // Check if the date is valid and inputString does not contain non-numeric characters
    if(!isNaN(date.getTime()) && /^\d/.test(inputString))
    {
      return this.datePipe.transform(inputString,'dd/MM/yyyy hh:mm')?.toString()!
    }
    else
    {
return inputString;
    }
  }

}
