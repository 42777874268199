import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangePassword, defaultChangePasswordValues } from 'src/app/components/users/change-password';
import { AuthService } from 'src/app/services/auth.service';
import { ChangePasswordService } from 'src/app/services/change-password.service';
import { ToastService } from 'src/app/services/toast-service.service';
import { ApplicationRoles } from '../users/user-roles';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangePasswordComponent {
  
  changePasswordObj: ChangePassword = defaultChangePasswordValues();
  confirmPassword: string = "";
  message: string = "";
  userRole: ApplicationRoles =ApplicationRoles.CMHA_INTAKE;
  cancelLink:string="";
  constructor(private _changepassowrdservice: ChangePasswordService, private _toastService: ToastService, private router: Router, private _authService: AuthService) { }

  changePassword(changepasswordForm: NgForm) {
    if (changepasswordForm.invalid) {
      this.message = "Field is Required";
      Object.values(changepasswordForm.controls).forEach(control => control.markAllAsTouched());
      return;
    }
    else if (this.changePasswordObj.newPassword != this.confirmPassword) {
      Object.values(changepasswordForm.controls).forEach(control => control.markAsDirty());
      this.message = "Passwords do not match. Please type the correct password";
      return;
    }
    else {
      this._changepassowrdservice.changepassword(this.changePasswordObj).subscribe({
        next: (res) => {
          if (res.status) {
            this._toastService.showMessage("Password Changed Successfully. Please login again.");
            this._authService.logout();
            this.router.navigate(['/login']);
          }
        },
        error: (err) => {
          this._toastService.showMessage("Error in changing password. Please contact CMHA Admin.");
        }
      })
    }
  }

  ngOnInit(){
    this.userRole = this._authService.getRole()
  switch(this.userRole){
    case ApplicationRoles.RIGHTS_ADVISOR:
      this.cancelLink = "/dashboard-ra";
      break;
      case ApplicationRoles.CMHA_INTAKE:
        this.cancelLink = "/dashboard-cmha"
        break;
        case ApplicationRoles.CMHA_ADMIN:
          this.cancelLink = "/dashboard-cmha"
          break;
  }
  
}
}
