import { Injectable } from "@angular/core";

export enum AudtiEventType {
  View = 1,
  Change = 2,
  Added=3,
}

@Injectable({
  providedIn: 'root'
})

export class AuditEventTypeEnumDescriptionService {
  private descriptions: { [key: number]: string } = {
    [AudtiEventType.View]: 'View',
    [AudtiEventType.Change]: 'Change',
    [AudtiEventType.Added] : "Added"
   
  };

  getDescription(enumValue: number): string {
    return this.descriptions[enumValue] || 'Unknown';
  }
  
}