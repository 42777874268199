import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { ApplicationRoles } from 'src/app/components/users/user-roles';

import { AuthService } from 'src/app/services/auth.service';
import { CaseFileService } from 'src/app/services/case-file.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	usertype!: ApplicationRoles
	selectedTab: number=0;
	constructor(private _authservice: AuthService,private route:ActivatedRoute,private _casefileservice:CaseFileService) { }
	ngOnInit(): void {
		this.usertype = this._authservice.getRole()

		this.route.params.subscribe(params => {
			const selectedtab = params['tabid'];
			if(selectedtab == undefined)
			{
				this.selectedTab=0
			}
			else
			{
				this.selectedTab=selectedtab
			}
	});

	
}


}
 
