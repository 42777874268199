import { Component, Input } from '@angular/core';
import {
  RequestForInformation,
  defaultRequestForInformationValues,
} from '../../request-for-information';
import { RequestForInformationFormOptionService } from 'src/app/services/request-for-information-form-option.service';

@Component({
  selector: 'app-section-requestforinformation-additional-info',
  templateUrl: './section-requestforinformation-additional-info.component.html',
  styleUrls: ['./section-requestforinformation-additional-info.component.scss'],
})
export class SectionRequestforinformationAdditionalInfoComponent {
  @Input() requestforinformation!: RequestForInformation
  @Input() isDisabled: boolean = false;
  natureOfInquiry: any[] = [];
  noiOthersVisible: boolean = false;
  assistanceProvided: any[] = [];
  apOtherVisible: boolean = false;
  radAssistanceProvidedValue: string = '';
  chkboxAssistanceProvidedVisible: boolean = false;
  chkboxReferralMadeVisible: boolean = false;
  referralMade: any[] = [];
  rmOtherVisible: boolean = false;
  radReferralMadeValue: string = '';
  followUpNeeded: any[] = [];
  funOthersVisible: boolean = false;
  constructor(
    private _rfioptionservice: RequestForInformationFormOptionService
  ) {}

  ngOnInit(): void {
    
    this._rfioptionservice.getNatureOfInquiry().subscribe((options) => {
      this.natureOfInquiry = options;
    });
    this._rfioptionservice.getAssistanceProvided().subscribe((options) => {
      this.assistanceProvided = options;
      
    });
    this._rfioptionservice.getReferralsMade().subscribe((options) => {
      this.referralMade = options;
    });
    this._rfioptionservice.getFollowUpNeeded().subscribe((options) => {
      this.followUpNeeded = options;
    });
  }


 

  isItemChecked(item: any, selectedItems: any[]): boolean {
    return selectedItems.some((selectedItem) => selectedItem.id === item.id);
  }

  onNOICheckboxChange(item: any) {
    this.noiOthersVisible = false;
    this.requestforinformation.requestForInformationNatureOfInquiryOtherText =
      '';
    if (
      this.isItemChecked(
        item,
        this.requestforinformation.requestForInformationNatureOfInquiryVersion
      )
    ) {
      this.requestforinformation.requestForInformationNatureOfInquiryVersion =
        this.requestforinformation.requestForInformationNatureOfInquiryVersion.filter(
          (i) => i.id !== item.id
        );
    } else {
      this.requestforinformation.requestForInformationNatureOfInquiryVersion.push(
        item
      );
    }

    if (
      this.requestforinformation.requestForInformationNatureOfInquiryVersion.find(
        (z) => z.natureOfInquiry == 'Other'
      )
    ) {
      this.noiOthersVisible = true;
    }
  }

  onAPCheckboxChange(item: any) {
    this.apOtherVisible = false;
    this.requestforinformation.requestForInformationAssistanceProvidedOtherText =
      '';
    if (
      this.isItemChecked(
        item,
        this.requestforinformation
          .requestForInformationAssistanceProvidedVersion
      )
    ) {
      this.requestforinformation.requestForInformationAssistanceProvidedVersion =
        this.requestforinformation.requestForInformationAssistanceProvidedVersion.filter(
          (i) => i.id !== item.id
        );
    } else {
      this.requestforinformation.requestForInformationAssistanceProvidedVersion.push(
        item
      );
    }

    if (
      this.requestforinformation.requestForInformationAssistanceProvidedVersion.find(
        (z) => z.assistanceProvided == 'Other'
      )
    ) {
      this.apOtherVisible = true;
    }
  }

  radAssistanceProvided(assistanceProvided:boolean|null) {
   
    if (assistanceProvided) {
      this.chkboxAssistanceProvidedVisible = true;
      this.requestforinformation.requestForInformationAssistanceProvidedVersionToBeValidated = true;
    } else {
      this.chkboxAssistanceProvidedVisible = false;
      this.requestforinformation.requestForInformationAssistanceProvidedVersionToBeValidated = false;
    }
  }

  radReferralMade(referralMade:boolean|null) {
    if (referralMade) {
      this.chkboxReferralMadeVisible = true;
      this.requestforinformation.requestForInformationReferralsMadeVersionToBeValidated = true;
    } else {
      this.chkboxReferralMadeVisible = false;
      this.requestforinformation.requestForInformationReferralsMadeVersionToBeValidated = false;
    }
  }

  onRMCheckboxChange(item: any) {
    this.rmOtherVisible = false;
    this.requestforinformation.requestForInformationReferralsMadeOtherText = '';
    if (
      this.isItemChecked(
        item,
        this.requestforinformation.requestForInformationReferralsMadeVersion
      )
    ) {
      this.requestforinformation.requestForInformationReferralsMadeVersion =
        this.requestforinformation.requestForInformationReferralsMadeVersion.filter(
          (i) => i.id !== item.id
        );
    } else {
      this.requestforinformation.requestForInformationReferralsMadeVersion.push(
        item
      );
    }

    if (
      this.requestforinformation.requestForInformationReferralsMadeVersion.find(
        (z) => z.referralMade == 'Other'
      )
    ) {
      this.rmOtherVisible = true;
    }
  }

  onFUNCheckboxChange(item: any) {
    this.funOthersVisible = false;
    this.requestforinformation.requestForInformationReferralsMadeOtherText = '';
    if (
      this.isItemChecked(
        item,
        this.requestforinformation.requestForInformationFollowUpNeededVersion
      )
    ) {
      this.requestforinformation.requestForInformationFollowUpNeededVersion =
        this.requestforinformation.requestForInformationFollowUpNeededVersion.filter(
          (i) => i.id !== item.id
        );
    } else {
      this.requestforinformation.requestForInformationFollowUpNeededVersion.push(
        item
      );
    }

    if (
      this.requestforinformation.requestForInformationFollowUpNeededVersion.find(
        (z) => z.followUpNeeded == 'Other'
      )
    ) {
      this.funOthersVisible = true;
    }
  }
}
