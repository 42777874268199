<div class="container">
    <div class="content" role="main">

        <div class="designatedFacilityForm">

            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="rightsadvisor.advisorName" class="form-control-textbox"
                    name="facilityName" />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput [(ngModel)]="rightsadvisor.advisorEmail" class="form-control-textbox" name="city" />
            </mat-form-field>

            <mat-form-field appearance="outline" [hidden]='true'>
                <mat-label>Health Region</mat-label>
                <mat-select placeholder="Select Health Region" name="healthRegion"
                    [(ngModel)]="rightsadvisor.advisorHealthRegionVersionId">
                    <mat-option *ngFor="let hr of healthRegions" [value]="hr.healthRegionId">{{ hr.healthRegionName
                        }}</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="about-this-record">
                <p><strong>About this record</strong></p>
                <p>If you save changes to this record</p>

                <ul>
                    <li>There are {{this.closedCaseFileCount}} closed case files linked to this facility.</li>
                    <li>These records will not be updated.</li>
                    <li>There are {{this.openCaseFileCount}} open case files linked to this facility.</li>
                    <li>These records will be updated.</li>
                    <li *ngIf="RADeactivationLine">{{RADeactivationLine}}</li>
                </ul>
            </div>

            <div class="clear"></div>

            <div class="form-buttons">

                <input type="submit" (click)="updateRightsAdvisor()" class="submit-form-button" value="Submit" />


            </div>

        </div>
    </div>
</div>