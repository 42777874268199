import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-bubble',
  templateUrl: './status-bubble.component.html',
  styleUrls: ['./status-bubble.component.scss']
})
export class StatusBubbleComponent {
  @Input() status: string | undefined = '';

  statusClass: string = '';
  statusName: string = '';

  ngOnInit(): void {
    if (this.status == undefined)
      return;
    
    this.statusClass = this.status.replace(' ', '').toLocaleLowerCase();
    this.statusName = this.status;
  }
}
