import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CityService {

	apiUrl = environment.apiUrl + 'City/'; // injected from the environment.ts

	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(
		private http: HttpClient,) { }

	getCities(): Observable<any[]> {
		return this.http.get<any>(this.apiUrl);
			
	}
}