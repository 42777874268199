import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnitService } from 'src/app/services/unit-service';
import { Units } from 'src/app/components/unit/unit';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
  selector: 'app-unitaccesscode',
  templateUrl: './unitaccesscode.component.html',
  styleUrls: ['./unitaccesscode.component.scss']
})
export class UnitAccessCodeComponent {
  unitVersionId: number = 0;
  editfacilityurl: string | null = "";
  unit: Units = {} as Units;

  generatedCode: string = "";

  constructor(
    private unitService: UnitService,
    private router: Router,
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.unitVersionId = params['id'];

      const localStorageKey = 'accesscode_' + this.unitVersionId;
      this.generatedCode = localStorage.getItem(localStorageKey) ?? '';

      if (this.generatedCode == '') {
        this.router.navigateByUrl('/facilities');
        return;
      }
    })

    this.unitService.getUnitDetailsByUnitId(this.unitVersionId).subscribe({
      next: (data) => {
        this.unit = data;
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        this.editfacilityurl = '/editfacility/' + this.unit.facilityVersionId;
      }
    })
  }

  copyAccessCode(): void {
    this.clipboard.copy(this.generatedCode);
    this.toastService.showMessage('Access code copied.')
  }
}
