<app-topnav></app-topnav>

<div class="page-heading"><div class="container"><h1>Change Password</h1></div></div>
<div class="clear"></div>
<div class="container">
  <div class="content" role="main">
    <div class="facility-heading">Change Password</div>
    <form #rpform="ngForm">
    <div class="designatedFacilityForm">
        <div class="login-fail fp-box" [hidden]="!errorDivVisible">
            <img src="/assets/tick.svg">
            <span>
              <h2>Success</h2>
              <p>Your password has been changed.</p>
              <div class="form-buttons">
                <button class="submit-form-button black-bg" (click)="gotologin()">Login</button>
              </div>
            </span>
           
          </div>
   
      <mat-form-field appearance="outline" floatLabel="always" [hidden]="errorDivVisible">
        <mat-label>Username</mat-label>
        <input matInput class="form-control-textbox" [(ngModel)]="rpObj.username" name="username" required disabled="true" />
       </mat-form-field>
      <div class="clear-10"></div>

      <mat-form-field appearance="outline" floatLabel="always" [hidden]="errorDivVisible">
        <mat-label>New Password</mat-label>
        <input matInput class="form-control-textbox"  [(ngModel)]="rpObj.password" name="newPassword" type="password" required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}" #newpassword="ngModel" />  
        <mat-error *ngIf="newpassword.invalid && newpassword.touched"> Password must be at least 12 characters, including at least one uppercase letter, one lowercase letter, and one number</mat-error>      
      </mat-form-field>
      <div class="clear-10"></div>

      <mat-form-field appearance="outline" floatLabel="always" [hidden]="errorDivVisible">
        <mat-label>Retype Password</mat-label>
        <input matInput class="form-control-textbox" name="confirmPassword" type="password" [(ngModel)]="confirmPassword" required #confirmpassword="ngModel" />     
        <mat-error *ngIf="confirmpassword.invalid && confirmpassword.touched">Field is required</mat-error>         
      </mat-form-field>

      <div class="clear-10"></div>
      <div *ngIf="rpObj.password != confirmPassword"><span class="confirm-password-error">Passwords do not match.</span></div>

      <div class="form-buttons" [hidden]="errorDivVisible">
        <input type="submit" class="submit-form-button" (click)="resetPassword(rpform)" value="Submit">
        <a routerLink="/facilities" class="submit-form-button cancel-button"
          >Cancel</a
        >
      </div>
    
    </div>
</form>
  </div>
</div>

<app-footer></app-footer>