import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CaseFileOptionService {
    apiUrl = environment.apiUrl; // injected from the environment.ts

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(
        private http: HttpClient,) { }

    getAppointmentLength(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'AppointmentLength/');
    }

    getAssistanceProvided(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'AssistanceProvided/');
    }

    getCertificateLength(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'CertificateLength/');
    }

    getDifficultyAccessingRAs(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'DifficultyAccessingRA/');
    }

    getHearingServices(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'HearingServices/');
    }

    getInterpretationLanguage(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'InterpretationLanguage/');
    }

    getInterpretationService(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'InterpretationService/');
    }

    getMeetingAccompaniments(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'MeetingAccompaniments/');
    }

    getProvidedInterpreterServices() {
        return this.http.get<any[]>(this.apiUrl + 'Lookup/ProvidedInterpreterServices/');
    }
  
    getMeetingFormats(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'MeetingFormat/');
    }

    getRAMeetingStatus(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'RAMeetingStatus/');
    }

    getRAAdditionalInfoMeetingStatus(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'RAAdditionalInfoMeetingStatus/');
    }

    getCMHAMeetingStatus(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'CMHAMeetingStatus/');
    }

    getCMHAAdditionalInfoMeetingStatus(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'CMHAAdditionalInfoMeetingStatus/');
    }

    getPatients(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'WhoIsPatient/');
    }

    getPatientAdmitRequest(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'PatientAdmitRequest/');
    }

    getPatientStatus(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'PatientStatus/');
    }

    getRAAdvice(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'RAAdviceProvided/');
    }

    getReferralsMade(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'ReferralsMade/');
    }

    getReferralsNotMade(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'ReferralsNotMade/');
    }
 
    getPronouns(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'Pronouns/');
    }

    getRestraintOrSeclusions(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'Lookup/RestraintOrSeclusions');
    }
    
    getUnmetAccommodationNeeds(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'Lookup/UnmetAccommodationNeeds');
    }
    
    getAppointmentConnectionIssues(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl + 'Lookup/AppointmentConnectionIssues');
    }
}
