import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { RightsAdvisor } from '../rights-advisor';
import { RightsAdvisorService } from 'src/app/services/rights-advisor.service';
import { RAQueryFilter, defaultRAQueryFilterValues } from '../rights-advisor-query';
import { HealthRegionService } from 'src/app/services/healthregion-service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NoResultsComponent } from '../../no-results/no-results.component';

@Component({
  selector: 'app-rights-advisor-list',
  templateUrl: './rights-advisor-list.component.html',
  styleUrls: ['./rights-advisor-list.component.scss']
})
export class RightsAdvisorListComponent {
  totalRecords: number = 0;
  rightsadvisor: RightsAdvisor[] = [];
  queryFilter: RAQueryFilter = defaultRAQueryFilterValues();
  healthRegions: any[] = [];
  columnsToDisplay = [
    'Name', 'Email','ActiveCases', 'HealthRegion', 'Status', 
  ]

  pageEvent!: PageEvent;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('noResults') noResults!: NoResultsComponent;
  @ViewChild('searchButton') searchButton !:ElementRef
  constructor(
    private rightsadvisorService: RightsAdvisorService, 
    private healthregionService: HealthRegionService) { }

 
  ngOnInit(): void {
    this.healthregionService.getHealthRegions().subscribe({
      next: (data) => {
        this.healthRegions = data;
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });

    this.searchWithFilters();
  }

  private searchWithFilters() {
    console.log(this.queryFilter);
    this.rightsadvisorService.getAllRightsAdvisorsList(this.queryFilter).subscribe({
      next: (data) => {
        let headers = data.headers.get('x-pagination');
        if (headers) {
          let parsedHeaders = JSON.parse(headers);
          this.totalRecords = parsedHeaders.TotalCount;
          this.noResults.setTotal(this.totalRecords);
        }
        this.rightsadvisor = data.body ? data.body : [];
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => { }
    });
  }

  search() {
    this.queryFilter.PageNumber = 0;
    this.paginator.pageIndex = 0;
    
    this.searchWithFilters();
  }

  resetFilters() {
    this.queryFilter = defaultRAQueryFilterValues();
    this.paginator.pageSize = this.queryFilter.PageSize;
    this.paginator.pageIndex = this.queryFilter.PageNumber;
    
    this.searchWithFilters();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.queryFilter.PageNumber = e.pageIndex;
    this.queryFilter.PageSize = e.pageSize;

    this.searchWithFilters();
  }

  @HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
	  if (event.key === 'Enter') {
		this.searchButton.nativeElement.click();
		event.preventDefault();
	  }
	}
}




