import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Units } from '../components/unit/unit';
import { AuthService } from './auth.service';
import { UnitAuditLog } from '../components/unit/unit-audit-logs';
import { CaseFileService } from './case-file.service';

@Injectable({
	providedIn: 'root',
})
export class UnitService {

	apiUrl = environment.apiUrl + 'Unit/'; // injected from the environment.ts

	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(
		private http: HttpClient,
		private _authservice:AuthService,
		private _casefileservice:CaseFileService) { }

	getAllUnits(): Observable<Units[]> {
		return this.http.get<Units[]>(this.apiUrl);

	}
	getActiveUnits(): Observable<Units[]> {
		return this.http.get<Units[]>(this.apiUrl + 'getActiveUnits');
	}
	
	getFilteredUnitbyFacility(facilityid: number): Observable<Units[]> {
		return this.http.get<Units[]>(this.apiUrl + 'getFilteredUnits/' + facilityid);
	}

	addUnit(unit: Units): Observable<Units> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<Units>(this.apiUrl + "addUnit/", unit,{ headers: headers });
	}

	updateUnit(unit: Units): Observable<Units> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<Units>(this.apiUrl + "editUnit/", unit,{ headers: headers });
	}

	disableUnit(id: number): Observable<boolean> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<boolean>(this.apiUrl + "disableUnit/" + id, null,{headers:headers});
	}

	generateCode(id: number): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<any>(this.apiUrl + "generateAccessCode/" + id, null,{headers:headers});
	}

	getUnitDetailsByUnitId(unitid: number): Observable<Units> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<Units>(this.apiUrl + "getUnitDetailsByUnitId/" + unitid,{headers:headers});
	}

	getUnitAuditLogs(unitversionid:number):Observable<UnitAuditLog[]>
	{
		let params = new HttpParams();
		params = params.append("timeZone",this._casefileservice.timeZoneName);
		params = params.append("unitVersionID",unitversionid);
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<UnitAuditLog[]>(this.apiUrl + "getUnitAuditLogs", { headers: headers,params:params});
	}
}