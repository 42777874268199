<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container">
    <h1>Portal Login</h1>
  </div>
</div>
<form #loginDFForm="ngForm">
  <div class="container">

    <div class="content" role="main">
      <div class="login-heading">
        <strong>Mental Health Facility</strong>
      </div>
      <div class="facility-heading w-70">Log In</div>
      <div class="designatedFacilityForm w-70">
        <div class="login-fail" [hidden]="!errorDivVisible">
          <img src="/assets/normal_u30.svg">
          <span>
            <h2>Invalid Username and/or Password</h2>
            <p>Sorry, the username and password do not match. Try again or contact CMHA at (604) 681-4070</p>

            <div class="form-buttons">
              <button class="submit-form-button black-bg" (click)="hideErrorDiv()">Try Again</button>
            </div>
          </span>
        </div>

        <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>Mental Health Facility and Unit</mat-label>
          <input type="text" placeholder="Select Facility and Unit" matInput [matAutocomplete]="auto"
            name="facilityUsername" [(ngModel)]="selectedUnit" (ngModelChange)="updateFacilityUnitsFiltering()"
            #username="ngModel"  (blur)="markasdirty(loginDFForm)" required ng-MinLength="1">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="formatFacilityUnitName">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.facilityName}} - {{ option.unitName }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="username.invalid && username.touched">Field is required</mat-error>
        </mat-form-field>

        <div class="clear-10"></div>

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Access Code</mat-label>
          <input matInput [(ngModel)]="loginObj.password" placeholder="Enter access code" class="form-control-textbox"
            name="facilityCode" required #password="ngModel" />
          <mat-error *ngIf="password.invalid && password.touched">Field is required</mat-error>
        </mat-form-field>

        <div class="form-buttons">
          <input type="submit" (click)="loginDF(loginDFForm)" class="submit-form-button" value="Submit" #submitButton />
        </div>
      </div>
      <div>
        If you don't have an access code, please talk to your facility team member responsible for this access code or contact CMHA.
      </div>
      <div class="clear"></div>
    </div>
  </div>
</form>

<app-footer></app-footer>