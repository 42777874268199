<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container">
    <h1>Email Queue</h1>
  </div>
</div>
<div class="container">
  <div class="content" role="main">
    <app-email-queue-list></app-email-queue-list>
  </div>
</div>

<app-footer></app-footer>