<div class="noResults" [hidden]="hideNoResults" [ngStyle]="{'visibility': hideNoResults ? 'hidden' : ''}">
    <svg version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="80px" height="80px"
        xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1 0 0 1 -592 -136 )">
            <path
                d="M 54.17391194864199 1  L 9.807889382716052 1  C 4.894258567901239 0.11518958904109615  0.7277921975308677 3.834288630136988  1 8.589494109589042  L 1 49.26023821917808  C 0.7277921975308677 54.01956487671234  4.894258567901239 57.73454273972602  9.807889382716052 57  L 46.65013728395063 57  C 49.00228661728396 57.73454273972602  49.00228661728396 54.18050216438356  46.65013728395063 54  L 9.807889382716056 54  C 6.614208000000004 54.18463073945206  4.253861925925933 51.93087468465754  5 49.26436679424658  L 5 14  L 59.72783431901236 14  L 59.72783431901236 23.948498191506847  C 59.72783431901236 26.313737396986305  63.25810750419753 26.313737396986305  63 23.948498191506847  L 63 8.58918449287671  C 63.25810750419753 3.8298578353424655  59.087437677037045 0.11487997232876279  54.17391194864199 1  Z "
                fill-rule="nonzero" fill="#111013" stroke="none" fill-opacity="0.8470588235294118"
                transform="matrix(1 0 0 1 592 136 )" />
        </g>
        <g transform="matrix(1 0 0 1 -616 -160)  translate(24, 24)">
            <path
                d="M 2.4007238095238113 0.27319000000000265  C 0.3693768095238138 0.27319000000000265  -0.6316001904761894 2.746185666666673  0.8320204761904787 4.163509666666676  L 5.30686247619048 8.642658333333348  L 0.8320204761904787 13.121807000000018  C -0.051179957142856836 14.005007433333342  -0.059588723809521786 15.439116666666676  0.8278150161904796 16.322414000000023  C 1.7110154495238095 17.205614433333345  3.1451246828571384 17.205614433333345  4.028422016190479 16.322414000000023  L 8.494866016190485 11.843265333333353  L 12.961310016190481 16.322414000000023  C 13.844510449523817 17.205614433333345  15.278619682857151 17.205614433333345  16.161917016190486 16.322414000000023  C 17.045117449523822 15.439213566666687  17.045117449523822 14.005104333333353  16.166123552857155 13.121807000000018  L 11.691281552857156 8.642658333333348  L 16.166123552857155 4.163509666666676  C 17.65493821952382 2.72519066666667  16.58249208619048 0.20169933333334591  14.51322488619048 0.27319000000000265  C 13.920218419523815 0.2942183766666732  13.365067552857145 0.5423566666666718  12.952919552857157 0.9671339666666738  L 8.48647555285715 5.441975966666674  L 4.020031552857149 0.9671339666666738  C 3.6036630195238177 0.5213293666666765  3.0106565528571467 0.27319000000000265  2.4008325528571457 0.27319000000000265  Z "
                fill-rule="nonzero" fill="#111013" stroke="none" fill-opacity="0.8470588235294118"
                transform="matrix(1 0 0 1 616 160 )" />
        </g>
        <g transform="matrix(1 0 0 1 -638 -163) translate(47, 28)">
            <path
                d="M 28.10787892564103 8.359260955102023  C 25.270832053846153 3.4303406693877374  20.075746335897435 0.6804896693877331  14.814872338461527 0.7711668979591892  C 12.423849492307683 0.8125168151020484  10.012105928205113 1.4410414836734768  7.811058235897423 2.7187266122449025  C 0.7452934153846108 6.812434040816329  -1.6043937128205163 16.01650089795918  2.521037799999989 23.178795183673472  C 6.369805441025633 29.873412612244902  14.58768446666666 32.54471761224491  21.409663697435896 29.526095326530612  L 26.125635671794857 37.721767040816324  C 26.73681227692306 38.78859532653062  28.0954782102564 39.1566606122449  29.160903697435884 38.54463695510203  C 30.22632918461538 37.924377612244896  30.585664492307686 36.56394395510204  29.97443502820512 35.50124409795918  L 25.159299617948715 27.127732383673465  C 30.34603361794871 22.533744097959175  31.729458694871788 14.660936669387738  28.10787892564103 8.359260955102023  Z M 10.019992487179483 6.5725617551020425  C 11.564425999999989 5.679392112244903  13.245235666666662 5.232833755102049  14.922239487179485 5.199806326530614  C 18.601752692307702 5.133645612244902  22.22756117948718 7.0646916122449035  24.25100271794872 10.579466326530618  C 27.19958202564104 15.706974612244906  25.543616076923083 22.095664897959203  20.60045623076923 24.961217755102044  C 15.657296384615389 27.82264218367348  9.301321871794869 26.07769304081633  6.357077000000001 20.954313183673467  C 3.408497692307695 15.826804897959187  5.076938358974353 9.438114612244906  10.019992487179483 6.5725617551020425  Z "
                fill-rule="nonzero" fill="#111013" stroke="none" fill-opacity="0.8470588235294118"
                transform="matrix(1 0 0 1 638 163 )" />
        </g>
    </svg>
    <p>No search results found, please refine your search and try again</p>
</div>