import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { CaseFileReportQuery, caseFileReportingqueryDefaultValues } from 'src/app/components/case-file/case-file-reporting-query';
import { CaseFileService } from 'src/app/services/case-file.service';

@Component({
  selector: 'app-casefilereport',
  templateUrl: './casefilereport.component.html',
  styleUrls: ['./casefilereport.component.scss']
})
export class CasefilereportComponent {

  caseFileReportQuery:CaseFileReportQuery=caseFileReportingqueryDefaultValues()
  constructor( private _caseFileService:CaseFileService,private datepipe:DatePipe){}




resetFilters() {
this.caseFileReportQuery = caseFileReportingqueryDefaultValues()
}
searchWithFilters() {
  this.caseFileReportQuery.startDate = this.datepipe.transform(this.caseFileReportQuery.startDate,'yyyy-MM-dd')!
  this.caseFileReportQuery.endDate = this.datepipe.transform(this.caseFileReportQuery.endDate,'yyyy-MM-dd')!
  this._caseFileService.getcasefilereport(this.caseFileReportQuery).subscribe({
		next:(response:Blob) =>{
		  const fileUrl = URL.createObjectURL(response)
		  window.open(fileUrl);
	  }
	})
}

}
