import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BlankCaseFile, CaseFile } from '../../case-file/case-file';
import { CaseFileOptionService } from 'src/app/services/case-file-option.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'app-section4-iras',
	templateUrl: './section4-iras.component.html',
	styleUrls: ['./section4-iras.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class Section4IrasComponent {
	@Input() caseFile: CaseFile = BlankCaseFile();
	@Input() isDisabled: boolean = false;
	@Input() isSaveAndCloseClicked: boolean = false;
	@Output() controlsChanged: EventEmitter<any> = new EventEmitter<any>();
	cmhaadditionalinfomeetingStatus: any[] = [];

	constructor(private _casefileoptionservice: CaseFileOptionService) { }

	ngOnInit(): void {

		this._casefileoptionservice.getCMHAAdditionalInfoMeetingStatus().subscribe({
			next: (res) => { this.cmhaadditionalinfomeetingStatus = res },
			error: (err) => console.log('Error:' + err)
		});
	}

	onCMHAAdditionalInfoMeetingStatusChange(event: any) {
		this.controlsChanged.emit(event);

	}

	validateSection4IRAS():boolean{
		let flag = true;
		if(this.caseFile.cmhaAdditionalInformationMeetingStatusVersionId == null)
		{
			flag=false
		}

		return flag;
	}
}
