<div class="form-section-header">
    <div class="container">
      <h3 class="padding-left-10">Section 1 </h3>
    </div>
  </div>
<div class="container">
<div class="form-subsection-header">
    
        <h4 class="padding-left-10">Request Received Information</h4>
    </div>
</div>
<div class="form-subsection-content">
    <div class="container">
        <fieldset>
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Date Request Received</mat-label>
            <input matInput [matDatepicker]="dpdaterequest" [(ngModel)]="requestforinformation.requestReceivedOnDate" name="drr" placeholder="DD/MM/YYYY" [disabled]="isDisabled">
            
            <mat-datepicker-toggle matIconSuffix [for]="dpdaterequest"></mat-datepicker-toggle>
            <mat-datepicker #dpdaterequest [disabled]="isDisabled"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" floatLabel="always" class="timepicker-control">
            <mat-label>Time Request Received</mat-label>
            <input matInput hidden="true" />
                <ngx-timepicker-field [defaultTime]="'10:10 am'" style="border-bottom: 0px;"
                    [(ngModel)]="requestforinformation.requestReceivedOnTime" [disabled]="isDisabled"></ngx-timepicker-field>
          </mat-form-field> 
        </fieldset>
        <fieldset>
            <label id="intake-form-radio-group-label">How was the inquiry received?</label>
            </fieldset>
            <hr>
            <fieldset>
            <mat-radio-group name="radInquiruGroup" [(ngModel)]="requestforinformation.requestForInformationInquiryReceivedVersionId"
              aria-labelledby="intake-form-radio-group-label" class="intake-form-radio-group display-vertical" (change)="radInquirychange()"
              [disabled]="isDisabled" required #radinquiryreceived>
              <mat-radio-button name="radioMeetingFormat" *ngFor="let ir of inquiryReceived"
                [value]="ir.id" class="intake-form-radio-button">
                {{ir.inquiryName}}
              </mat-radio-button>
              
             </mat-radio-group>
          </fieldset>
          <fieldset>
            <div class="rfi-other-textbox">
              <input matInput [hidden]="!inquiryOthersVisible"  [(ngModel)]="requestforinformation.requestForInformationInquiryReceivedOtherText" class="form-control-textbox "
              name="inquiryothertext" />
            </div>
          </fieldset>
    </div>
</div>