<!-- 
    rewrite form bits into separate section modules that bind to the shared object
<personal-info-form [data]="caseFile"></personal-info-form>
 -->
<div [hidden]="!showBackLink" class="container">
  <a (click)="goback()" class="back-to-previous">&#8592; Back to Dashboard</a>
</div>
 
<app-section-casefile-details [caseFile]="caseFile" [hidden]="!caseFileSections.sectionCaseFileDetailsVisible" [isDisabled]="!caseFileSections.sectionCaseFileDetailsEnabled"></app-section-casefile-details>

<!-- TEMPORARY - REMOVE on SECTION 4 DONE -->
<div class="container" style="display: none">
  <h2>Instructions</h2>
  <p>
    In most cases, the facility/team should submit this form through the Rights Advice Service online portal.
    As a backup, the facility/team can submit the form by fax. For assistance, the Rights Advice Service can by reached by phone.
    The hours of operation for the Rights Advice Service are on their website at: irasbc.ca.
  </p>
  <br>
  <p class="disclaimer">
    Your personal information is being collected under section 26(c) of the Freedom of Information and Protection of 
    Privacy Act to set up a meeting between you and a Rights Advisor and help the Rights Advisor to tailor the information and advice they give you.
    If you have any questions about the collection of this personal information, please contact: Provincial Manager, 
    Independent Rights Advice Service, Canadian Mental Health Association, BC Division, 905 – 1130 West Pender Street, Vancouver BC, V6E 4A4.
  </p>
  <br>
</div>

<div class="container">
  <div class="alert alert-danger" role="alert" *ngIf="validationErrors.length > 0">
    <ul>
      <li *ngFor="let error of validationErrors">
        {{ error }}
      </li>
    </ul>
  </div>
</div>

<form ngForm #myForm="ngForm">
  <app-section-assign-ra [caseFile]="caseFile" [hidden]="!caseFileSections.sectionAssignRAVisible" [isDisabled]="!caseFileSections.sectionAssignRAEnabled" (raChanged)="onRAChange($event)"></app-section-assign-ra>
  <app-section-request-received-info [caseFile]="caseFile" [hidden]="!caseFileSections.sectionRequestReceivedInfoVisible" [isDisabled]="!caseFileSections.sectionRequestReceivedInfoEnabled"></app-section-request-received-info>
  <app-section1 [parentSubmitted]="formSubmitted" [ICSaveClicked]="ICSaveClicked"  [caseFile]="caseFile" [hidden]="!caseFileSections.section1Visible" [isDisabled]="!caseFileSections.section1Enabled"></app-section1>
  <app-section2  [parentSubmitted]="formSubmitted" [ICSaveClicked]="ICSaveClicked" [caseFile]="caseFile" [hidden]="!caseFileSections.section2Visible" [isDisabled]="!caseFileSections.section2Enabled"></app-section2>
  <app-section3-ra [parentSubmitted]="formSubmitted" [caseFile]="caseFile" [hidden]="!caseFileSections.section3RAVisible" [isDisabled]="!caseFileSections.section3RAEnabled"></app-section3-ra>
  <app-section3-iras [isSaveAndCloseClicked]="ICisSaveAndCloseClicked" [caseFile]="caseFile" [hidden]="!caseFileSections.section3IRASVisible" 
  [isDisabled]="!caseFileSections.section3IRASEnabled" (controlsChanged)="onCMHASection3And4Change($event)"></app-section3-iras>
  <app-section4-ra [parentSubmitted]="formSubmitted" [caseFile]="caseFile" [hidden]="!caseFileSections.section4RAVisible" [isDisabled]="!caseFileSections.section4RAEnabled"></app-section4-ra>
  <app-section4-iras [isSaveAndCloseClicked]="ICisSaveAndCloseClicked"  [caseFile]="caseFile" [hidden]="!caseFileSections.section4IRASVisible" [isDisabled]="!caseFileSections.section4IRASEnabled" (controlsChanged)="onCMHASection3And4Change($event)"></app-section4-iras>
  <app-section-issuepdf [caseFile]="caseFile" [hidden]="!caseFileSections.sectionPDFIssueVisible" [isDisabled]="!caseFileSections.sectionPDFIssueEnabled" (notifyParent)="issueNewPDF()"></app-section-issuepdf>
  <app-section5-iras [caseFile]="caseFile" [hidden]="!caseFileSections.section5IRASVisible" [isDisabled]="!caseFileSections.section5IRASEnabled"></app-section5-iras>

  <div class="clear"></div>
  <div class="form-buttons">
    <div class="container">
      <input type="submit" (click)="addCaseFile()" class="submit-form-button" [hidden]="!caseFileSections.submitButtonVisibility" value="Submit" />
      <button type="button" (click)="updateCaseFile()" class="submit-form-button" [hidden]="!caseFileSections.editButtonVisibility"
        [disabled]="caseFileSections.section5IRASEnabled && (!caseFile.allowSaveOnceConfirmed||ICSaveDisabled)">{{saveButtonText}}</button>

      <button (click)="goback()" class="submit-form-button" class="submit-form-button cancel-button" [hidden]="!caseFileSections.cancelButtonVisibility">Cancel</button>
      <button type="button" (click)="closeCaseFileIRAS()" class="submit-form-button" [hidden]="!caseFileSections.saveAndCloseButtonVisibility"
        [disabled]="caseFileSections.section5IRASEnabled && (!caseFile.allowSaveOnceConfirmed || ICSaveAndCloseButtonDisbled)">Save & Close Case</button>
    </div>

  </div>
  <div class="clear"></div>
  <div class="container"><app-case-file-audit-log-list [caseFile]="caseFile"></app-case-file-audit-log-list></div>
  <div class="clear"></div>
  <app-modal *ngIf="modalOpen | async" [title]="modalTitle" [content]="modalContent" [okButtonValue]="modalOkButtonValue"
   [cancelButtonValue]="modalCancelButtonValue" [okButtonText]="modalOkButtonText" [cancelButtonText]="modalCancelButtonText"></app-modal>
</form>