<div class="clear"></div>
<div class="dfTableContainer allFilesContainer">
    <div class="boxHeader" style="display: none;">
        <label class="title"> All Facilities</label>
    </div>
    <div class="clear"></div>
    <div class="tableContents">
        <ng-container #allFilesTable
            *ngTemplateOutlet="unitTable; context: {data: unitObj, headerVisible: true}"></ng-container>
        <!-- <mat-paginator #allFilesPaginator [length]="this.totalRecords" [pageSize]="10"
            [pageSizeOptions]="[10, 25, 50]" (page)="pageEvent = $event" ariaLabel=''></mat-paginator> -->
    </div>
</div>

<ng-template #unitTable let-data="data" let-headerVisible="headerVisible">
    <table mat-table class="fileList primary" [dataSource]="data">

        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Facility ID">Unit Version ID</th>
            <td mat-cell *matCellDef="let unitObj"><a>{{unitObj.unitVersionId}}</a></td>
        </ng-container>

        <ng-container matColumnDef="Name">

            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Name">Unit Name</th>
            <td mat-cell *matCellDef="let unitObj">
                <a routerLink="/editunit/{{unitObj.unitVersionId}}" [hidden]="!unitObj.isActive">{{unitObj.unitName}}</a>
                <span [hidden]="unitObj.isActive">{{unitObj.unitName}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="Address">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Date">Address</th>
            <td mat-cell *matCellDef="let unitObj"><a>{{unitObj.address}}</a></td>

        </ng-container>

        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Date">Status</th>
            <td mat-cell *matCellDef="let unitObj">
                <app-status-bubble [status]="(unitObj.isActive ? 'Active' : 'Deactivated')"></app-status-bubble>
            </td>
        </ng-container>

        <ng-container *ngIf="headerVisible">
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="tableHeader"></tr>
        </ng-container>
        <tr mat-row *matRowDef="let designatedFacility; columns: columnsToDisplay"></tr>
    </table>
    <div class="clear"></div>
    <i class="fa fa-regular fa-trash-can"></i>
</ng-template>