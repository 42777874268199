<div class="clear"></div>
<div class="caseFileTableContainer allFilesContainer">
	<div class="filterForm">
		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Case ID</mat-label>
				<input matInput class="form-control-textbox" name="filtercaseid" placeholder="Case ID"
					[(ngModel)]="rfiQueryFilter.id" />

			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Name</mat-label>
				<input matInput class="form-control-textbox" name="filternames" placeholder="First and/or last name"
					[(ngModel)]="rfiQueryFilter.NameTerm">
			</mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Phone</mat-label>
        <input matInput class="form-control-textbox" name="filterphn" placeholder="Phone" [(ngModel)]="rfiQueryFilter.PhoneTerm">
      </mat-form-field>
    </fieldset>

		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always" class="w-65-5">
				<mat-label>Type</mat-label>
				<mat-select placeholder="Select" name="filterTreatmentOptions"
					[(ngModel)]="rfiQueryFilter.TreatmentTypeTerm">
					<mat-option *ngFor="let tt of treatmentType" [value]="tt.id">{{tt.treatmentOption}}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Received By</mat-label>
				<mat-select placeholder="Select" name="filterReceivedBy"
					[(ngModel)]="rfiQueryFilter.InquiryReceivedByTerm">
					<mat-option *ngFor="let ir of inquiryReceived" [value]="ir.id">{{ir.inquiryName}}</mat-option>
				</mat-select>
			</mat-form-field>


		</fieldset>

		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Start Date</mat-label>
				<input matInput [matDatepicker]="filterStartDate" class="form-control-textbox" name="filterStartDate"
					placeholder="Start Date" [(ngModel)]="rfiQueryFilter.StartDate" />
				<mat-datepicker-toggle matIconSuffix [for]="filterStartDate"></mat-datepicker-toggle>
				<mat-datepicker #filterStartDate disabled="false"></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>End Date</mat-label>
				<input matInput [matDatepicker]="filterEndDate" class="form-control-textbox" name="filterEndDate"
					placeholder="End Date" [(ngModel)]="rfiQueryFilter.EndDate">
				<mat-datepicker-toggle matIconSuffix [for]="filterEndDate"></mat-datepicker-toggle>
				<mat-datepicker #filterEndDate disabled="false"></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Status</mat-label>
				<mat-select placeholder="Select One" name="filterStatus" [(ngModel)]="rfiQueryFilter.Status">
					<mat-option *ngFor="let status of applicationStatus"
						[value]="status">{{enumDescService.getDescriptionForFilters(status)}}</mat-option>
				</mat-select>
			</mat-form-field>

		</fieldset>

		<div class="form-buttons">
			<div class="filterButtons">
				<button class="submit-form-button" (click)="searchWithFilters()">Search</button>
				<button class="submit-form-button cancel-button no-margin" (click)="resetFilters()">Reset</button>
			</div>
		</div>
	</div>

	<div class="clear"></div>
	<div class="tableContents">
		<ng-container #allFilesTable
			*ngTemplateOutlet=" rfiTable; context: { data: rfiForms, headerVisible: true }"></ng-container>
		<mat-paginator #paginator [length]="this.totalRecords" [pageSize]="25" [hidden]="this.totalRecords === 0"
			[pageSizeOptions]="[10, 25, 50]" (page)="handlePageEvent($event)" ariaLabel=''></mat-paginator>
	</div>
</div>

<ng-template #rfiTable let-data="data" let-headerVisible="headerVisible">
	<app-no-results #noResults></app-no-results>

	<table mat-table class="fileList primary" [dataSource]="data" [hidden]="data.length === 0" matSort
		#fileTableSort="matSort" matSortActive="UpdatedOnDateTimeUTC" matSortDirection="desc"
		(matSortChange)="announceSortChange($event)">
		<ng-container matColumnDef="Id">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" mat-sort-header
				sortActionDescription="Sort by Case ID">
				Case ID
			</th>
			<td mat-cell *matCellDef="let rfiForms">
				<a routerLink="/request-for-information-form/view/{{ rfiForms.id }}">{{ rfiForms.id }}</a>
			</td>
		</ng-container>

		<ng-container matColumnDef="LastName">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" mat-sort-header
				sortActionDescription="Sort by Name">
				Name
			</th>
			<td mat-cell *matCellDef="let rfiForms">
				<a routerLink="/request-for-information-form/view/{{ rfiForms.id }}">{{ rfiForms.lastName }}, {{
					rfiForms.firstName }}</a>
			</td>
		</ng-container>

    <ng-container matColumnDef="UpdatedOnDateTimeUTC">
      <th mat-header-cell *matHeaderCellDef class="roundedLeft" mat-sort-header sortActionDescription="Sort by Date">
        Date Received
      </th>
      <td mat-cell *matCellDef="let rfiForms">
        <a routerLink="/request-for-information-form/view/{{ rfiForms.id }}">{{
          rfiForms.updatedOnDateTimeUTC | date : "MMM dd, yyyy"
          }}</a>
      </td>
    </ng-container>

    
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef class="roundedLeft" mat-sort-header sortActionDescription="Sort by Date">
        Status
      </th>
      <td mat-cell *matCellDef="let rfiForms">
        <a routerLink="/request-for-information-form/view/{{ rfiForms.id }}">
          <app-status-bubble [status]="setCaseFileStatus(rfiForms.status)"></app-status-bubble>
        </a>
      </td>
    </ng-container>

		<ng-container matColumnDef="RequestForInformationStatus">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" mat-sort-header
				sortActionDescription="Sort by Date">
				Status
			</th>
			<td mat-cell *matCellDef="let rfiForms">
				<a routerLink="/request-for-information-form/view/{{ rfiForms.id }}">
					<app-status-bubble [status]="setCaseFileStatus(rfiForms.status)"></app-status-bubble>
				</a>
			</td>
		</ng-container>

		<ng-container *ngIf="headerVisible">
			<tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="tableHeader"></tr>
		</ng-container>
		<tr mat-row *matRowDef="let caseFile; columns: columnsToDisplay"></tr>
	</table>
</ng-template>
<div class="clear"></div>