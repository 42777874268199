import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { RightsAdvisorService } from 'src/app/services/rights-advisor.service';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
  selector: 'app-disablerightsadvisor',
  templateUrl: './disablerightsadvisor.component.html',
  styleUrls: ['./disablerightsadvisor.component.scss']
})
export class DisableRightsAdvisorComponent implements OnInit {
  advisorname: string;
  id: number;
  constructor(private rightsadvisorService: RightsAdvisorService, private route: ActivatedRoute, private router: Router, private _toastService: ToastService) {
    this.advisorname = ""; this.id = 0;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.advisorname = params['name'];
      this.id = params['id'];
    })
  }

  //write code for disable right advisor
  disableAdvisor() {

    this.rightsadvisorService.disableRightsAdvisor(this.id).subscribe(data => {
      this._toastService.showMessage('RA record has been deactivated');
      this.router.navigate(['/rights-advisors']);
    })
  }


}
