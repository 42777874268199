<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container">
    <h1>Rights Advisor Administration</h1>
  </div>
</div>
<div class="container">
  <div class="content" role="main">
    <div class="createButton form-buttons">
      <h2>Rights Advisors</h2>
      <a routerLink="/add-rights-advisor" class="submit-form-button white-bg"><strong>+</strong> Add Rights Advisor</a>
    </div>
    <app-rights-advisor-list></app-rights-advisor-list>
  </div>
</div>

<app-footer></app-footer>