import { Injectable } from "@angular/core";

export enum ApplicationRoles {
  NO_ROLE = 0,
  CMHA_INTAKE = 1,
  CMHA_ADMIN = 2,
  RIGHTS_ADVISOR = 3,
  DESIGNATED_FACILITY = 4
}

@Injectable({
  providedIn: 'root'
})

export class ApplicationRolesEnumDescriptionService {
  private descriptions: { [key: number]: string } = {
    [ApplicationRoles.CMHA_INTAKE]: 'Intake Coordinator',
    [ApplicationRoles.CMHA_ADMIN]: 'Admin',
    [ApplicationRoles.RIGHTS_ADVISOR]: 'Rights Advisor',
    [ApplicationRoles.DESIGNATED_FACILITY]: 'Designated Facility'
  };

  getDescription(enumValue: number): string {
    return this.descriptions[enumValue] || 'Unknown';
  }
}