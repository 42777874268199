import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DesignatedFacility } from '../components/designated-facility/designated-facility';
import { DesignatedFacilityQuery } from '../components/designated-facility/designated-facility-query';
import { AuthService } from './auth.service';
import { DesignatedFacilityAuditLog } from '../components/designated-facility/designated-facility-audit-logs';
import { CaseFileService } from './case-file.service';

@Injectable({
	providedIn: 'root',
})
export class DesignatedFacilityService {

	apiUrl = environment.apiUrl + 'DesignatedFacility/'; // injected from the environment.ts

	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(
		private http: HttpClient,
		private _authservice: AuthService,
		private _casefileservice:CaseFileService) { }

	getFacility(facilityId: number): Observable<DesignatedFacility> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<DesignatedFacility>(this.apiUrl + "getFacility/" + facilityId, { headers: headers });
	}
			
	getDFQueryFilterParams(filter: DesignatedFacilityQuery): HttpParams {
		return new HttpParams({ fromObject: filter });
	}

	getAllFacilities(dfquery: DesignatedFacilityQuery): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		let param = this.getDFQueryFilterParams(dfquery)
		return this.http.get<DesignatedFacility[]>(this.apiUrl, { headers: headers, params: param, observe: "response" });
	}

	getActiveFacilities(): Observable<DesignatedFacility[]> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<DesignatedFacility[]>(this.apiUrl + 'getActiveFacilities', { headers: headers });
	}

	saveFacility(DesignatedFacility: DesignatedFacility): Observable<DesignatedFacility> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<DesignatedFacility>(this.apiUrl + "addFacility/", DesignatedFacility, { headers: headers });
	}

	disableFacility(id: number): Observable<boolean> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<boolean>(this.apiUrl + "disableFacility/" + id, null, { headers: headers });
	}

	generateCode(id: number): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<any>(this.apiUrl + "generateAccessCode/" + id, null, { headers: headers });
	}

	updateFacility(DesignatedFacility: DesignatedFacility): Observable<DesignatedFacility> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<DesignatedFacility>(this.apiUrl + "updateFacility/", DesignatedFacility, { headers: headers });
	}

	getDFAuditLogs(facilityversionid:number):Observable<DesignatedFacilityAuditLog[]>
	{
		let params = new HttpParams();
		params = params.append("timeZone",this._casefileservice.timeZoneName);
		params = params.append("facilityVersionID",facilityversionid);
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<DesignatedFacilityAuditLog[]>(this.apiUrl + "getDesignatedFacilityAuditLogs", { headers: headers,params:params});
	}
}
