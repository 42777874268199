<div class="modal-overlay" (click)="closeModal()">
    <div class="modal-container" (click)="$event.stopPropagation()">
      <div class="modal-header">
        <h5>{{ title }}</h5>
        
      </div>
      <hr>
      <div class="modal-content">
        <p>{{ content }}</p>
      </div>
      <div class="modal-actions form-buttons" >
        <button (click)="onButtonClick(okButtonValue)" class="submit-form-button">{{okButtonText}}</button>
        <button (click)="onButtonClick(cancelButtonValue)" class="submit-form-button cancel-button">{{cancelButtonText}}</button>
      </div>
    </div>
  </div>