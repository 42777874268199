import { Component, Input, OnInit } from '@angular/core';
import { CaseFile } from '../../case-file/case-file';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'app-section5-iras',
	templateUrl: './section5-iras.component.html',
	styleUrls: ['./section5-iras.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class Section5IrasComponent implements OnInit {
	@Input() caseFile!: CaseFile;
	@Input() isDisabled: boolean = false;
	confirmationOfChanges: boolean = false;

	ngOnInit(): void {
		this.caseFile.allowSaveOnceConfirmed = false
	}
}
