export interface UserDetails{
    createdBy?: string,
    createdByUserId?: string,
    updatedBy?: string,
    updatedByUserId?: string
}

export function defaultUserDetails() : UserDetails
{
    return {
        createdBy:'1',
        createdByUserId: 'TEST',
        updatedBy:'1',
        updatedByUserId: 'TEST'
    }
}
