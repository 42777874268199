import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss']
})
export class AuthorizeComponent {
  constructor(private _authservice:AuthService,private router:Router) {

  }

  ngOnInit():void{
    if(!this._authservice.checkTokenExpiry())
    {
      console.log(this._authservice.checkTokenExpiry())
      this.router.navigateByUrl('/login');
    }
  }

}
