<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container">
    <h1>Email Queue</h1>
  </div>
</div>
<div class="content" role="main">
  <div class="container">
    <div class="email-details">
      <div class="form-section-header">
        <!-- <app-status-bubble [status]="emailMessage?.status"></app-status-bubble> -->
        <span class="a-status-label {{ emailMessage?.status?.toLocaleLowerCase() }}">
          {{ emailMessage?.status }}
        </span>
        <h2>{{ emailMessage?.subject }}</h2>
      </div>
      <div>
        <label>From: <span>{{ emailMessage?.fromName }} &lt;{{ emailMessage?.fromEmailAddress }}&gt;</span></label>
      </div>
      <div>
        <label>To: <span>{{ emailMessage?.toName }} &lt;{{ emailMessage?.toEmailAddress }}&gt;</span></label>
      </div>
      <div>
        <label>Created: <span>{{ emailMessage?.createdDate | date : "dd/MM/yyyy HH:mm" }}</span></label>
      </div>
      <div *ngIf="emailMessage?.status == 'Sent'">
        <label>Sent: <span>{{ emailMessage?.sentDate | date : "dd/MM/yyyy HH:mm" }}</span></label>
      </div>

      <div *ngIf="emailMessage?.status === 'Failed'">
        <label>Error Message:</label>
        <pre class="error-message-output">
          {{ emailMessage?.errorMessage }}        
        </pre>

        <div>
          <label>Times Failed: <span>{{ emailMessage?.timesFailed }}</span></label>
        </div>
      </div>
    </div>

    <hr />
    <div [innerHtml]="emailMessage?.body"></div>

  </div>
</div>

<app-footer></app-footer>