import { Component, Query } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RightsAdvisorService } from 'src/app/services/rights-advisor.service';
import { RightsAdvisor, defaultValues } from 'src/app/components/rights-advisor/rights-advisor';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { Router } from '@angular/router';
import { query } from '@angular/animations';
import { HealthRegionService } from 'src/app/services/healthregion-service';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
	selector: 'app-addrightsadvisor',
	templateUrl: './addrightsadvisor.component.html',
	styleUrls: ['./addrightsadvisor.component.scss']
})
export class AddRightsAdvisorComponent {
	rightsAdvisor = defaultValues();
	healthRegions: any = [];

	constructor(private rightsAdvisorService: RightsAdvisorService, private router: Router, private healthregionService: HealthRegionService,
		private toastService: ToastService) { }

	addRightsAdvisor() {
		this.rightsAdvisorService.addRightsAdvisor(this.rightsAdvisor).subscribe({
			next: (data) => {
				if (data)
					this.router.navigate(['/rights-advisors']);
				else
					this.toastService.showMessage('Unable to add RA. User already exists with that email.');
			},
			error: (error) => {
				console.log(error);
			},
			complete: () => { }
		});
	}

	ngOnInit(): void {

		this.healthregionService.getHealthRegions().subscribe({
			next: (data) => {
				this.healthRegions = data;
				console.log(this.healthRegions);
			},
			error: (error) => {
				console.log(error);
			},
			complete: () => {
			}
		});

	}
}


