import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { ChangePasswordSnackbarComponent } from '../components/changepassword-snackbar/changepassword-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private snackbarref: any;
  constructor(private _snackbar: MatSnackBar) { }

  showMessage(message: string, duration: number = 4000): void {
    this._snackbar.open(message, ' ', { duration: duration, horizontalPosition: 'start', verticalPosition: 'bottom', panelClass: ['custom-snackbar'] })
  }

  showPasswordChangeMessage(message: string): void {
    this.snackbarref = this._snackbar.openFromComponent(ChangePasswordSnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top', panelClass: ['password-change-snackbar']
    })
    const componentInstance = this.snackbarref.instance;
    componentInstance.setMessageFromService(message);
  }

  dismissSnackbar() {
    if (this.snackbarref) {
      this.snackbarref.dismiss();
    }
  }
}
