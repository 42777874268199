<app-topnav></app-topnav>

<div class="page-heading">
    <div class="container">
        <h1>Page Not Found!</h1>
    </div>
</div>
<div class="container">
    <div class="content">
        <div style="float: left;">
            <img src="/assets/404.png" style="width: 200px;"/>
        </div>

        <h2 class="admin-subheading">Whoops! It seems that we have misplaced that page.</h2>
        <p>We can not find the page you are looking for. Please navigate to your dashboard and try again.</p>
    </div>
</div>

<app-footer></app-footer>