import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { RightsAdvisor } from '../components/rights-advisor/rights-advisor';
import { environment } from 'src/environments/environment';
import { RAQueryFilter } from '../components/rights-advisor/rights-advisor-query';
import { AuthService } from './auth.service';
import { RightsAdvisorAuditLog } from '../components/rights-advisor/rights-advisor-audit-log';
import { CaseFileService } from './case-file.service';

//write code for service 
@Injectable({
	providedIn: 'root'
})
export class RightsAdvisorService {

	private rightsAdvisorUrl = environment.apiUrl + 'RightsAdvisor';
	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(private http: HttpClient, private _authservice: AuthService,private _casefileservice:CaseFileService) { }

	getRAQueryFilterParams(qryFilter: RAQueryFilter): HttpParams {
		return new HttpParams({ fromObject: qryFilter });
	}

	getAllRightsAdvisorsList(qryFilter: RAQueryFilter): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		let param = this.getRAQueryFilterParams(qryFilter)
		return this.http.get<RightsAdvisor[]>(this.rightsAdvisorUrl, { headers: headers, params: param, observe: "response" });
	}

	getActiveRightsAdvisorsList(): Observable<RightsAdvisor[]> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<RightsAdvisor[]>(this.rightsAdvisorUrl + '/getActiveRA', { headers: headers });
	}

	addRightsAdvisor(rightsAdvisor: RightsAdvisor): Observable<boolean> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<boolean>(this.rightsAdvisorUrl, rightsAdvisor, { headers: headers });
	}

	updateRightsAdvisor(rightsAdvisor: RightsAdvisor): Observable<RightsAdvisor> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<RightsAdvisor>(this.rightsAdvisorUrl + '/updateRA', rightsAdvisor, { headers: headers });
	}

	disableRightsAdvisor(id: number): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<any>(this.rightsAdvisorUrl + '/disableRA/' + id, null, { headers: headers })
	}

	getRADetailsfromRAVersionID(id: number): Observable<RightsAdvisor> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<RightsAdvisor>(this.rightsAdvisorUrl + '/getRADetailsFromRAVersionID/' + id, { headers: headers })
	}

	getRADetailsFromUserID(): Observable<RightsAdvisor> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<RightsAdvisor>(this.rightsAdvisorUrl + '/getRADetailsFromUserID', { headers: headers })
	}

	resentRightsAdvisorActivationEmail(id: number): Observable<any> {
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.post<any>(this.rightsAdvisorUrl + '/resendActivation/' + id, null, { headers: headers })
	}

	getRAAuditLogs(raversionid:number):Observable<RightsAdvisorAuditLog[]>
	{
		let params = new HttpParams();
		params = params.append("timeZone",this._casefileservice.timeZoneName);
		params = params.append("raVersionID",raversionid);
		const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
		return this.http.get<RightsAdvisorAuditLog[]>(this.rightsAdvisorUrl + "/getRightsAdvisorAuditLogs", { headers: headers,params:params});
	}
}