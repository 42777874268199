import { Component, ElementRef, ViewChild } from '@angular/core';
import { RequestForInformation, defaultRequestForInformationValues } from '../request-for-information';
import { RequestForInformationFormOptionService } from 'src/app/services/request-for-information-form-option.service';
import { RequestForInformationFormService } from 'src/app/services/request-for-information-form.service';
import { ToastService } from 'src/app/services/toast-service.service';
import { Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';
import { CaptchaService } from 'src/app/services/captcha.service';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-request-for-information-public-form',
  templateUrl: './request-for-information-public-form.component.html',
  styleUrls: ['./request-for-information-public-form.component.scss'],
 
})
export class RequestForInformationPublicFormComponent {
  treatmentOptions: any[] = [];
  requestforinformation: RequestForInformation =
    defaultRequestForInformationValues();
  treatmentOptionOtherTextVisible: boolean = false;
  isPhoneChecked: boolean = false;
  isEmailChecked: boolean = false;
  natureOfInquiry: any[] = [];
  noiOthersVisible: boolean = false;
  validationErrors: string[]=[];
captchavalidated:boolean=false;


  constructor(
    private _rfioptionservice: RequestForInformationFormOptionService,
    private _rfiFormService: RequestForInformationFormService,
    private _toastService: ToastService,
    private router: Router,
    private _captchaservice:CaptchaService
  ) { }

  ngOnInit(): void {
    this._rfioptionservice
      .getTreatmentOptions()
      .subscribe((data) => (this.treatmentOptions = data));

    this._rfioptionservice.getNatureOfInquiryForPublicSite().subscribe((options) => {
      this.natureOfInquiry = options;
    });
 
    

  }

  radTreatmentOptionsChange() {
    this.treatmentOptionOtherTextVisible = false;
    const selectedId =
      this.requestforinformation.requestForInformationTreatmentOptionsVersionId;
    const filteredResult = this.treatmentOptions.filter(
      (to) => to.id == selectedId
    );
    if (filteredResult[0].treatmentOption == 'Other') {
      this.treatmentOptionOtherTextVisible = true;
    }
  }

  isItemChecked(item: any, selectedItems: any[]): boolean {
    return selectedItems.some((selectedItem) => selectedItem.id === item.id);
  }

  onNOICheckboxChange(item: any) {
    this.noiOthersVisible = false;
    this.requestforinformation.requestForInformationNatureOfInquiryOtherText =
      '';
    if (
      this.isItemChecked(
        item,
        this.requestforinformation.requestForInformationNatureOfInquiryVersion
      )
    ) {
      this.requestforinformation.requestForInformationNatureOfInquiryVersion =
        this.requestforinformation.requestForInformationNatureOfInquiryVersion.filter(
          (i) => i.id !== item.id
        );
    } else {
      this.requestforinformation.requestForInformationNatureOfInquiryVersion.push(
        item
      );
    }

    if (
      this.requestforinformation.requestForInformationNatureOfInquiryVersion.find(
        (z) => z.natureOfInquiry == 'Other'
      )
    ) {
      this.noiOthersVisible = true;
    }
  }

  resetForm() {
    this.requestforinformation = defaultRequestForInformationValues();
  }

  addRequestForInformationForm() {
   if(!this.captchavalidated)
   {
    return;
   }
    //add captcha validated code here 
    this._rfiFormService
      .createPublicRequestForInformationForm(this.requestforinformation)
      .subscribe({
        next: (data) => {
          const rfiId = data.id
          this.router.navigate(['/reachout-form-thankyou']);
        },
        error: (error) => {
          if (error.status === HttpStatusCode.BadRequest) {
            this.validationErrors = [];
            let validationErrorDictionary = error.error;
            
            for (let fieldName in validationErrorDictionary) {
              if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                this.validationErrors.push(validationErrorDictionary[fieldName]);
              }
            }
            console.log(validationErrorDictionary)
  
          }
          else {
            console.log(error, 'Unable to connect to CaseFile API Endpoint');
          }
          window.scroll(0,0,);
        }
      });
     
  }

resolved(captcharesponse:string)
{
  this.captchavalidated = true;
}

}
