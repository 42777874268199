import { Component } from '@angular/core';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent {
  totalResults: number | undefined;
  hideNoResults: boolean;

  constructor() {
    this.hideNoResults = true;
  }

  setTotal(total: number): void {
    this.totalResults = total;
    this.hideNoResults = this.totalResults != undefined && this.totalResults > 0;
  }
}
