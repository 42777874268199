import { Component } from '@angular/core';

@Component({
  selector: 'app-rightsadvisors',
  templateUrl: './rightsadvisors.component.html',
  styleUrls: ['./rightsadvisors.component.scss']
})
export class RightsAdvisorsComponent {

}
