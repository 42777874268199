<app-topnav></app-topnav>
<div class="page-heading">
	<div class="container">
		<h1>Request For Rights Advice</h1>
	</div>
</div>
<div class="container">
	<div class="content header-spacer">
		Request For Rights Advice Submission :
		<ul>
			<li>Download PDF copy of request for rights advice submission</li>
			<li>Keep on file on client record</li>
		</ul>
		<div class="clear"></div>
		<div class="form-buttons">
			<button (click)="downloadButtonClick()" class="submit-form-button" [disabled]="pdfbuttondisabled">Download
				PDF Submission</button>
		</div>
		<div class="clear"></div>
		Note:
		<ul>
			<li>This is a one time use download button.</li>
		</ul>
		<div class="clear"></div>
		If you have questions contact CMHA.
	</div>
</div>

<app-footer></app-footer>