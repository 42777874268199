<app-topnav></app-topnav>
<div class="page-heading">
  <div class="container">
    <h1>Rights Advisor Portal</h1>
  </div>
</div>
<form #loginRAForm="ngForm">
  <div class="container">
    <div class="content" role="main">
      <div class="login-heading">
        <strong>Rights Advisor</strong>
      </div>
      <div class="facility-heading w-70">Log In</div>
      <div class="designatedFacilityForm w-70">
        <div class="login-fail" [hidden]="!errorDivVisible">
          <img src="/assets/normal_u30.svg">
          <span>
            <h2>{{errorDivHeading}}</h2>
            <p>{{errorDivMessage}}</p>

            <div class="form-buttons">
              <button class="submit-form-button black-bg" (click)="hideErrorDiv()">{{errorDivButtonText}}</button>
            </div>
          </span>

        </div>

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Username</mat-label>
          <input matInput [(ngModel)]="loginObj.username" class="form-control-textbox" name="raUsername"
            #username="ngModel" required />
          <mat-error *ngIf="username.invalid && username.touched">Field is required</mat-error>
        </mat-form-field>
        <div class="clear-10"></div>
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Password</mat-label>
          <input matInput type="password" [(ngModel)]="loginObj.password" class="form-control-textbox"
            #password="ngModel" name="raPassword" required />
          <mat-error *ngIf="password.invalid && password.touched">Field is required</mat-error>
        </mat-form-field>

        <div class="form-buttons">
          <div class="form-buttons-with-forgot-password">
            <input type="submit" (click)="loginRA(loginRAForm)" class="submit-form-button"  value="Submit" #submitButton />
            <span class="float-right"><a href="/forgot-password/RA" class="forgot-password">Forgot password?</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<app-footer></app-footer>