<div class="clear"></div>
<div class="dfTableContainer allFilesContainer">
    <div class="filterForm">
        <fieldset>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Facility</mat-label>
            <input matInput class="form-control-textbox" name="filterFacilityName" [(ngModel)]="queryFilter.FacilityNameTerm"/>
          </mat-form-field>

          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>City</mat-label>
            <mat-select placeholder="Select" name="filterFacilityCity" [(ngModel)]="queryFilter.CityVersionIdTerm">
            <mat-option value="0">ALL</mat-option>
              <mat-option *ngFor="let city of cityList" [value]="city.cityId">{{ city.cityName}}</mat-option>
            </mat-select>
          </mat-form-field>
    
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Status</mat-label>
            <mat-select placeholder="Select" name="filterFacilityStatus" [(ngModel)]="queryFilter.Status">
                <mat-option value="ALL">ALL</mat-option>
                <mat-option value="TRUE" selected="selected">Active</mat-option>
                <mat-option value="FALSE">Inactive</mat-option>
              </mat-select>
          </mat-form-field>
        </fieldset>
            
        <fieldset>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Health Authority</mat-label>
                <mat-select placeholder="Select" name="filterFacilityHealthAuthority" [(ngModel)]="queryFilter.HealthAuthorityId">
                    <mat-option value="0">Select</mat-option>
                    <mat-option *ngFor="let ha of healthAuthorities" [value]="ha.healthAuthorityId">{{ ha.healthAuthorityName}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Health Region</mat-label>
                <mat-select placeholder="Select" name="filterFacilityHealthRegion" [(ngModel)]="queryFilter.HealthRegionId">
                    <mat-option value="0">Select</mat-option>
                    <mat-option *ngFor="let hr of healthRegions" [value]="hr.healthRegionId">{{ hr.healthRegionName}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div appearance="outline" style="width: 31%" floatLabel="always" class="mat-mdc-form-field mat-mdc-form-field-label-always-float mat-form-field-appearance-outline">
            </div>
        </fieldset>

        <div class="form-buttons">
          <div class="filterButtons">
            <button class="submit-form-button" (click)="search()" #searchButton>Search</button>
            <button class="submit-form-button cancel-button no-margin" (click)="resetFilters()">Reset</button>
            </div>
        </div>
       
      </div>  
      <div class="clear"></div>
    <div class="tableContents">
        <ng-container #allFilesTable *ngTemplateOutlet="designatedFacilityTable; context: {data: designatedFacilities, headerVisible: true}"></ng-container>
        <mat-paginator #paginator [length]="this.totalRecords" [pageSize]="25" [hidden]="this.totalRecords === 0"
            [pageSizeOptions]="[10, 25, 50]" (page)="handlePageEvent($event)" ariaLabel=''></mat-paginator>
    </div>
</div>

<ng-template #designatedFacilityTable let-data="data" let-headerVisible="headerVisible">
    <app-no-results #noResults></app-no-results>

    <table mat-table class="fileList primary" [dataSource]="data" [hidden]="data.length === 0">
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Facility ID">Facility ID</th>
            <td mat-cell *matCellDef="let designatedFacilities">
                {{designatedFacilities.designatedFacilityVersionId}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Name">Facility Name</th>
            <td mat-cell *matCellDef="let designatedFacilities">
                <a routerLink="/editfacility/{{designatedFacilities.designatedFacilityVersionId}}">{{designatedFacilities.facilityName}}</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="NumberOfActiveUnits">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft"
                sortActionDescription="Sort by # of units"># of units</th>
            <td mat-cell *matCellDef="let designatedFacilities" style="text-align: center;">
                {{designatedFacilities.numberOfActiveUnits}}
            </td>
        </ng-container>

        <ng-container matColumnDef="City">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft"
                sortActionDescription="Sort by City">City</th>
            <td mat-cell *matCellDef="let designatedFacilities">
                {{designatedFacilities.cityName}}
            </td>
        </ng-container>

        <ng-container matColumnDef="HealthAuthority">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft"
                sortActionDescription="Sort by Health Authority">Health Authority</th>
            <td mat-cell *matCellDef="let designatedFacilities">
                {{designatedFacilities.healthAuthorityName}}
            </td>
        </ng-container>

        <ng-container matColumnDef="HealthRegion">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Health Region">Health Region</th>
            <td mat-cell *matCellDef="let designatedFacilities">
                {{designatedFacilities.healthRegionName}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Date">Status</th>
            <td mat-cell *matCellDef="let designatedFacilities" style="min-width: 110px;">
                <a routerLink="/editfacility/{{designatedFacilities.designatedFacilityVersionId}}">
                    <app-status-bubble [status]="setFacilityStatus(designatedFacilities.isActive)"></app-status-bubble>
                    </a>
            </td>
        </ng-container>
        <ng-container *ngIf="headerVisible">
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="tableHeader"></tr>
        </ng-container>
        <tr mat-row *matRowDef="let designatedFacility; columns: columnsToDisplay"></tr>
    </table>
    <div class="clear"></div>
    <i class="fa fa-regular fa-trash-can"></i>
</ng-template>