import { UserDetails,defaultUserDetails } from "../users/userdetail";
export interface CMHAAgents{
    cmhaIntakeAgentVersionId:number;
    cmhaIntakeAgentName:string;
    cmhaIntakeAgentEmail:string;
    isActive:boolean;
    userDetails:UserDetails;
    openCaseCount:number;
    closeCaseCount:number;
}

export function defaultValues():CMHAAgents{

 const defaultUserInfo:UserDetails = defaultUserDetails();
 const defaultDesignatedFacility:CMHAAgents = {
    cmhaIntakeAgentVersionId:0,cmhaIntakeAgentName:"",userDetails : defaultUserInfo,isActive:true,cmhaIntakeAgentEmail:'',openCaseCount:0,closeCaseCount:0
 }
 
 return defaultDesignatedFacility;
}