import { UserDetails, defaultUserDetails } from "../users/userdetail";

export interface DesignatedFacility {
    designatedFacilityVersionId: number;
    cityVersionId: number;
    facilityName: string;
    cityName: string;
    numberOfUnits: number;
    healthAuthorityVersionId: number;
    healthAuthorityName: string;
    healthRegionVersionId: number;
    healthRegionName: string;
    isActive: boolean;
    userDetails: UserDetails;
}

export function defaultValues(): DesignatedFacility {

    const defaultUserInfo: UserDetails = defaultUserDetails();
    const defaultDesignatedFacility: DesignatedFacility = 
    {
        designatedFacilityVersionId: 0, 
        facilityName: "", 
        cityVersionId: 0, 
        cityName: "", 
        numberOfUnits: 0,
        healthAuthorityVersionId: 0,
        healthAuthorityName: "", 
        healthRegionVersionId: 0,
        healthRegionName: "", 
        userDetails: defaultUserInfo, 
        isActive: true
    }

    return defaultDesignatedFacility;
}