<div class="footer">
  <div class="container">

    <div class="contact-us-heading"><h3>Contact Us</h3></div>
    <div class="div-footer-content">
    <div class="footer-content">
       <span class="contact-us-field contact-us-field-heading">Phone </span><br>
       <span class="contact-us-field contact-us-field-heading">Fax</span>
     </div>
<div class="footer-content">
  <span class="contact-us-field">
    (604) 681-4070
</span>
<br/>
<span class="contact-us-field">
  (604)-677-5534
</span>
</div>
    
<div class="footer-content">
  <span class="contact-us-field contact-us-field-heading">Address </span>
</div>
    <div class="footer-content">
       <span class="contact-us-field">
            Canadian Mental Health Association<br />
            British Columbia Division<br />
            Suite 905 - 1130 West Pender Street<br />
            Vancouver, BC V6E 4A4
        </span>
       
    </div>
  </div>
  </div>
</div>

<div class="copyright">
  <div class="container">
    <div class="copyright-content">
      <p>&copy; Copyright 2023. All rights reserved.</p>
      <span class="app-version">
        {{ applicationVersion }}
      </span>
    </div>
    
  </div>
</div>
