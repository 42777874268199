import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-request-for-information-thankyou',
  templateUrl: './request-for-information-thankyou.component.html',
  styleUrls: ['./request-for-information-thankyou.component.scss']
})
export class RequestForInformationThankyouComponent {
 rfiID:number=0;

 constructor(private route:ActivatedRoute){}
 ngOnInit() {
  this.route.params.subscribe(params=>{
    this.rfiID = params['id']
  });
 }
}
