import { PaginationQuery } from "../pagination/pagination-query"
import { CaseFileStatus } from "./case-file-status"

export type CaseFileQueryFilter = PaginationQuery & {
    CaseFileTerm: string,
    NameTerm: string,
    PhnTerm: string,
    StartDate: string,
    EndDate: string,
    RightsAdvisorVersionId: number,
    FacilityUnitVersionId: number,
    CaseFileStatus: CaseFileStatus
}

export function defaultCaseFileQueryFilters(): CaseFileQueryFilter {
    return {
        CaseFileTerm: "",
        NameTerm: "",
        PhnTerm: "",
        StartDate: "",
        EndDate: "",
        OrderByProperty: "",
        OrderByDirection: "",
        RightsAdvisorVersionId: 0,
        FacilityUnitVersionId: 0,
        CaseFileStatus: 0,
        PageNumber: 0,
        PageSize: 25
    }
}