import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BlankCaseFile, CaseFile } from '../../case-file/case-file';
import { CaseFileOptionService } from 'src/app/services/case-file-option.service';
import { CMHAAgentService } from 'src/app/services/cmhaagents.service';
import { AuthService } from 'src/app/services/auth.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'app-section3-iras',
	templateUrl: './section3-iras.component.html',
	styleUrls: ['./section3-iras.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class Section3IrasComponent {
	@Input() caseFile: CaseFile = BlankCaseFile();
	@Input() isDisabled: boolean = false;
	@Input() isSaveAndCloseClicked: boolean = false;
	@Output() controlsChanged: EventEmitter<any> = new EventEmitter<any>();

	cmhaAgentsList: any[] = [];
	meetingStatus: any[] = [];
	intakeAgentDDDisabled: boolean = false;

	constructor(
		
		private casefileoptionService: CaseFileOptionService,
		private _authservice: AuthService
	) { }

	ngOnInit(): void {
		
		this.casefileoptionService.getCMHAMeetingStatus().subscribe({
			next: (meetingstatus) => { this.meetingStatus = meetingstatus },
			error: (err) => { console.log(err) },
		})
	}

	controlsValueChange(event: any) {
		console.log("Section3IrasComponent: controlsValueChange");
		this.controlsChanged.emit(event);

	}

	validateSection3IRAS():boolean{
		let flag=true
		if(this.caseFile.cmhaMeetingStatusVersionId == null)
		{
			flag=false
		}
		if(this.caseFile.cmhaNotes == '')
		{
			flag=false
		}
		return flag;
	}
}
