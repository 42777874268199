import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, tap, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangePassword } from '../components/users/change-password';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class ChangePasswordService {
    apiUrl = environment.apiUrl

    httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(
		private http: HttpClient,private _authservice:AuthService) { }

        changepassword(cp:ChangePassword): Observable<ChangePassword> {
            const token = this._authservice.getAuthToken();
		const headers = new HttpHeaders({'Authorization':'Bearer '+token});
            return this.http.post<ChangePassword>(this.apiUrl+"ChangePassword",cp,{headers:headers});
        }

        
}