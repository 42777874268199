import { Component, Input } from '@angular/core';
import { RightsAdvisor, defaultValues } from '../rights-advisor';
import { RightsAdvisorAuditLog } from '../rights-advisor-audit-log';
import { AuditEventTypeEnumDescriptionService } from '../../case-file/case-file-audit-log-list/audti-event-type';
import { DatePipe } from '@angular/common';
import { RightsAdvisorService } from 'src/app/services/rights-advisor.service';

@Component({
  selector: 'app-rights-advisor-audit-logs',
  templateUrl: './rights-advisor-audit-logs.component.html',
  styleUrls: ['./rights-advisor-audit-logs.component.scss']
})
export class RightsAdvisorAuditLogsComponent {

  @Input() rightsAdvisor: RightsAdvisor = defaultValues();

  auditlogVisible: boolean = false;
  raAuditLogs: RightsAdvisorAuditLog[] = [];
  
  columnsToDisplay = ['User', 'Date','Event', 'Details']

  constructor(public enumdescservice:AuditEventTypeEnumDescriptionService,public datePipe:DatePipe,public _raService:RightsAdvisorService){}

  ngOnInit(): void {
    this._raService.getRAAuditLogs(this.rightsAdvisor.rightsAdvisorVersionId).subscribe({
      next: (res) => {
        this.raAuditLogs = res;
        this.auditlogVisible = res.length>0
      }
    })
  }
  checkIfValidDate(inputString: string): string {
    const date = new Date(inputString);
    // Check if the date is valid and inputString does not contain non-numeric characters
    if(!isNaN(date.getTime()) && /^\d/.test(inputString))
    {
      return this.datePipe.transform(inputString,'dd/MM/yyyy hh:mm')?.toString()!
    }
    else
    {
return inputString;
    }
  }
}
