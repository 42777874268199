import { Component, Input } from '@angular/core';
import { BlankCaseFile, CaseFile } from '../../case-file/case-file';
import { CaseFileOptionService } from 'src/app/services/case-file-option.service';
import { DatePipe } from '@angular/common';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'app-section3-ra',
	templateUrl: './section3-ra.component.html',
	styleUrls: ['./section3-ra.component.scss'],
	providers: [DatePipe],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class Section3RaComponent {
	@Input() caseFile: CaseFile = BlankCaseFile();
	@Input() isDisabled: boolean = false;
	@Input() parentSubmitted: boolean = false;
	raList: any[] = [];
	requestRecievedDate: string = "";
	requestRecievedTime: string = "";
	meetingFormats: any[] = [];
	rameetingStatus: any[] = [];

	constructor(
		private casefileoptionService: CaseFileOptionService) {
	}

	ngOnInit(): void {
		this.casefileoptionService.getMeetingFormats().subscribe({
			next: (meetingformats) => { this.meetingFormats = meetingformats },
			error: (err) => { console.log(err) },
			complete: () => { }
		});

		this.casefileoptionService.getRAMeetingStatus().subscribe({
			next: (meetingstatus) => { this.rameetingStatus = meetingstatus; },
			error: (err) => { console.log(err) },
		})
	}

	validateSection3RA():boolean
	{
		let flag = true;
		if(this.caseFile.raMeetingFormatVersionId == null)
		{
			flag=false;
		}
		if(this.caseFile.raMeetingStatusVersionId == null)
		{
			flag=false;
		}
		if(this.caseFile.raMeetingDate == '')
		{
			flag=false;
		}
		if(this.caseFile.raMeetingTime == '')
		{
			flag=false;
		}
		if(this.caseFile.raNotes == '')
		{
			flag=false;
		}
		return flag;
	}
}
