<div class="form-section-header">
	<div class="container">
		<h3><em>Section 3 IRAS -</em> TO BE COMPLETED BY INTAKE COORDINATOR</h3>
	</div>
</div>
<div class="form-subsection-header">
	<div class="container">
		<h4>Close Case</h4>
	</div>
</div>
<div class="form-subsection-content">
	<div class="container">
		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>IRAS Intake Agent Name</mat-label>
				<mat-select placeholder="Select One" name="drpIRAS" [(ngModel)]="caseFile.cmhaIntakeAgentVersionId"
					[disabled]="intakeAgentDDDisabled">
					<mat-option *ngFor="let cmha of cmhaAgentsList" [value]="cmha.cmhaIntakeAgentVersionId">{{
						cmha.cmhaIntakeAgentName}}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Date Request Received</mat-label>
				<input matInput [matDatepicker]="dpdaterequest" [(ngModel)]="caseFile.cmhaDateRequestReceived"
					name="drriras" placeholder="DD/MM/YYYY" [disabled]="true">

				<mat-datepicker-toggle matIconSuffix [for]="dpdaterequest" [disabled]="true"></mat-datepicker-toggle>
				<mat-datepicker #dpdaterequest disabled="true"></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" floatLabel="always" class="timepicker-control">
				<mat-label>Time Request Received</mat-label>
				<input matInput hidden="true" />
				<ngx-timepicker-field [defaultTime]="'10:10 am'" style="border-bottom: 0px;" name="trrIRAS"
					[(ngModel)]="caseFile.cmhaTimeRequestReceived" [disabled]="true"></ngx-timepicker-field>
			</mat-form-field>
		</fieldset>

		<fieldset>
			<div class="form-section-validator-controls w-48">
			<mat-form-field appearance="outline" floatLabel="always" class="w-100">
				<mat-label>Meeting Status</mat-label>
				<mat-select placeholder="Select One" name="drpMeetinFormatIRAS"
					[(ngModel)]="caseFile.cmhaMeetingStatusVersionId" [disabled]="isDisabled" [required]="!isDisabled"
					(selectionChange)="controlsValueChange($event)">
					<mat-option *ngFor="let ms of meetingStatus"
						[value]="ms.id">{{ms.cmhaMeetingStatusName}}</mat-option>
				</mat-select>
				
			</mat-form-field>
			<mat-error *ngIf="isSaveAndCloseClicked && caseFile.cmhaMeetingStatusVersionId == null" class="error">Please Select Meeting Status</mat-error>
		</div>
		</fieldset>

		<fieldset>
			<div class="form-section-validator-controls w-100">
			<mat-form-field appearance="outline" class="w-100" floatLabel="always">
				<mat-label>Notes</mat-label>
				<textarea matInput class="form-control-textbox w-100" name="facilitynotesIRAS"
					[(ngModel)]="caseFile.cmhaNotes" [disabled]="isDisabled" [required]="!isDisabled"
					(change)="controlsValueChange($event)"> </textarea>
    		</mat-form-field>
			<mat-error *ngIf="isSaveAndCloseClicked && caseFile.cmhaNotes == ''" class="error">Please include notes.</mat-error>
		</div>
		</fieldset>
	</div>
</div>