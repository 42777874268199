import { Component } from '@angular/core';
import { ApplicationRoles } from 'src/app/components/users/user-roles';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
  selector: 'app-dashboard-ra',
  templateUrl: './dashboard-ra.component.html',
  styleUrls: ['./dashboard-ra.component.scss']
})
export class DashboardRaComponent {
  usertype!: ApplicationRoles
  passwordChangeDays: number = 0;
  constructor(private _authservice: AuthService, private _toastService: ToastService) { }
  ngOnInit(): void {
    this.usertype = this._authservice.getRole();
    this.passwordChangeDays = this._authservice.getUserLoginData().passwordChangeRequiredDays
    if (this.passwordChangeDays <= 14) {
      this._toastService.showPasswordChangeMessage("Your password will expire in " + this.passwordChangeDays + " days.  Change your password to avoid account access disruptions in the near future.")
    }
  }
}
