import { CaseFilePersonalInfo, BlankCaseFilePersonalInfo } from "./case-file-personal-info";
import { UserDetails, defaultUserDetails } from "../users/userdetail";
import { casefileMeetingAccompaniments, lookupValues } from "./casefile-meeting-accompaniments";
import { CaseFileReferralsMade } from "./case-file-referral-made";
import { CaseFileReferralsNotMade } from "./case-file-referrals-not-made";
import { casefileMeetingAttendees } from "./case-file-meeting-attendees";
import { casefileassistanceprovided } from "./case-file-assistanceprovided";

export interface CaseFile {
	id: number;
	status: string
	personalInfo: CaseFilePersonalInfo;
	userDetails: UserDetails;
	meetingFormatVersionId: number | undefined,
	meetingAttendees: casefileMeetingAttendees[],
	interpretationServicesVersionId: number | undefined,
	interpretationLanguageVersionID: number | undefined,
	hearingServicesVersionId: number | undefined,
	anyOtherRequest: string,
	unitVersionId: number | undefined,
	unitPhone: string,
	unitFax: string,
	unitEmail: string,
	primaryContactPersonName: string,
	patientAdmitRequestVersionId: number | undefined,
	certificateLengthVersionId: number | undefined,
	dateOfInvoluntaryAdmission: Date,
	facilityMentalHealthProgramName: string,
	facilityUnitName: string,
	facilityNotes: string,
	rightsAdvisorVersionId: number,
	raRequestReceivedDate: string,
	raRequestReceivedTime: string,
	raMeetingFormatVersionId: number,
	raMeetingStatusVersionId: number,
	raMeetingDate: string,
	raMeetingTime: string,
	raNotes: string,
	rightsAdvisorAdditionalNotes: string;
	cmhaIntakeAgentVersionId: number,
	cmhaDateRequestReceived: string,
	cmhaTimeRequestReceived: string,
	cmhaMeetingStatusVersionId: number,
	cmhaNotes: string,
	appointmentLengthVersionId: number,
	raAdditionalInformationMeetingStatusVersionId: number,
	rightsAdvisorAdditionalInformationMeetingFormatVersionId: number;
	meetingAccompaniments: casefileMeetingAccompaniments[],
	meetingAccompanimentsOther: string,

	unmetAccommodationNeeds: lookupValues[],
	unmetAccommodationNeedNotes: string,

	appointmentConnectionIssues: lookupValues[],
	appointmentConnectionIssueOther: string,

	providedInterpreterServices: lookupValues[],
	providedInterpreterServicesOther: string,
	providedInterpreterServicesNotes: string,
	providedInterpretationLanguageVersionId: number;

	assistanceProvided: casefileassistanceprovided[],
	assistanceProvidedOther: string,

	desiredAdviceProvided: boolean|null,
	desiredAdviceProvidedNotes: string,

	difficultyAccessingRightsAdvice: boolean|null,
	difficultyAccessingRightsAdviceText: string,

	accomodationIssue: boolean,
	accomodationIssueText: string,

	restraintOrSeclusions: lookupValues[],
	restraintOrSeclusionsOther: string,
	restraintOrSeclusionsNotes: string,

	referralsMade: CaseFileReferralsMade[],
	referralsMadeOther: string,
	referralsNotMade: CaseFileReferralsNotMade[],
	referralsNotMadeOther: string;
	referralNotes: string,

	followUp: boolean|null,
	followUpText: string,
	cmhaAdditionalInformationMeetingStatusVersionId: number,
	cmhaAdditionalInformationNotes: string,
	isCaseClosed: boolean,
	irasNotesToRightsAdvisor: string,
	rightsAdvisorReasonForCancellation: string;

	allowSaveOnceConfirmed: boolean
}

export function BlankCaseFile(): CaseFile {
	const blankContactInfo: CaseFilePersonalInfo = BlankCaseFilePersonalInfo();
	const defaultUserInfo: UserDetails = defaultUserDetails();

	const blankCaseFile: CaseFile = {
		id: 0,
		status: 'New',
		personalInfo: blankContactInfo,
		userDetails: defaultUserInfo,
		meetingFormatVersionId: undefined,
		meetingAttendees: [],
		interpretationServicesVersionId: 2,
		interpretationLanguageVersionID: undefined,
		hearingServicesVersionId: undefined,
		anyOtherRequest: '',
		unitVersionId: undefined,
		unitPhone: '',
		unitFax: '',
		unitEmail: '',
		primaryContactPersonName: '',
		patientAdmitRequestVersionId: undefined,
		certificateLengthVersionId: undefined,
		dateOfInvoluntaryAdmission: new Date,
		facilityMentalHealthProgramName: '',
		facilityUnitName: '',
		facilityNotes: '',
		rightsAdvisorVersionId: 0,
		raRequestReceivedDate: '',
		raRequestReceivedTime: '',
		raMeetingFormatVersionId: 0,
		raMeetingStatusVersionId: 0,
		raMeetingDate: '',
		raMeetingTime: '',
		raNotes: '',
		rightsAdvisorAdditionalNotes: '',
		cmhaIntakeAgentVersionId: 0,
		cmhaDateRequestReceived: '',
		cmhaTimeRequestReceived: '',
		cmhaMeetingStatusVersionId: 0,
		cmhaNotes: '',
		appointmentLengthVersionId: 0,
		raAdditionalInformationMeetingStatusVersionId: 0,
		rightsAdvisorAdditionalInformationMeetingFormatVersionId: 0,
		meetingAccompaniments: [],
		meetingAccompanimentsOther: '',

		unmetAccommodationNeeds: [],
		unmetAccommodationNeedNotes: '',

		appointmentConnectionIssues: [],
		appointmentConnectionIssueOther: '',

		providedInterpreterServices: [],
		providedInterpreterServicesOther: '',
		providedInterpreterServicesNotes: '',
		providedInterpretationLanguageVersionId: 0,

		desiredAdviceProvided: null,
		desiredAdviceProvidedNotes: '',
		assistanceProvided: [],
		assistanceProvidedOther: '',

		difficultyAccessingRightsAdvice: null,
		difficultyAccessingRightsAdviceText: '',

		restraintOrSeclusions: [],
		restraintOrSeclusionsOther: '',
		restraintOrSeclusionsNotes: '',

		accomodationIssue: true,
		accomodationIssueText: '',
		referralsMade: [],
		referralsMadeOther: '',
		referralsNotMade: [],
		referralsNotMadeOther: '',
		referralNotes: '',

		followUp: null,
		followUpText: '',

		cmhaAdditionalInformationMeetingStatusVersionId: 0,
		cmhaAdditionalInformationNotes: '',
		isCaseClosed: false,
		irasNotesToRightsAdvisor: '',
		rightsAdvisorReasonForCancellation: '',

		allowSaveOnceConfirmed: true
	};

	return blankCaseFile;
}