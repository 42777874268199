import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../components/modal/modal.component';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private dialogRef: MatDialogRef<ModalComponent> | null = null;

  constructor(private dialog: MatDialog) { }

  private modalState = new BehaviorSubject<boolean>(false);
  private buttonClicked = new BehaviorSubject<string | null>(null);

  getModalState() {
    return this.modalState.asObservable();
  }

  openModal() {
    this.modalState.next(true);
  }

  closeModal() {
    this.modalState.next(false);
  }

  getButtonClicked() {
    return this.buttonClicked.asObservable();
  }

  buttonClick(button: string | null) {
    this.buttonClicked.next(button)
  }

}
