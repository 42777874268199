import { Component, OnInit } from '@angular/core';
import { RightsAdvisorService } from 'src/app/services/rights-advisor.service';
import { defaultValues } from 'src/app/components/rights-advisor/rights-advisor';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HealthRegionService } from 'src/app/services/healthregion-service';
import { CaseFileService } from 'src/app/services/case-file.service';
import { CaseFileCount } from 'src/app/components/case-file/case-file-count';
import { ToastService } from 'src/app/services/toast-service.service';
import { ModalService } from 'src/app/services/modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-editrightsadvisor',
  templateUrl: './editrightsadvisor.component.html',
  styleUrls: ['./editrightsadvisor.component.scss']
})
export class EditRightsAdvisorComponent implements OnInit {
  rightsadvisor = defaultValues();
  healthRegions: any[] = [];
  filteredResponse: CaseFileCount[] = [];
  closedCaseFileCount: number = 0;
  openCaseFileCount: number = 0;
  RADeactivationLine: string = "";
  checkboxFlag: boolean = false;
  deactivateButton: boolean = false;
  modalOpen = this._modalservice.getModalState()
  modalTitle: string = "";
  modalContent: string = '';
  modalOkButtonValue: string = '';
  modalCancelButtonValue: string = '';
  modalOkButtonText: string = '';
  modalCancelButtonText: string = '';
  modalSub!: Subscription;

  constructor(
    private rightsadvisorService: RightsAdvisorService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private healthregionService: HealthRegionService,
    private _casefileservice: CaseFileService, 
    private _toastService: ToastService, 
    private _modalservice: ModalService) {

  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.rightsadvisor.rightsAdvisorVersionId = params['id'];
    }
    );

    this.rightsadvisorService.getRADetailsfromRAVersionID(this.rightsadvisor.rightsAdvisorVersionId).subscribe({
      next: (res) => {
        this.rightsadvisor = res;
        this.closedCaseFileCount = res.closeCaseCount;
        this.openCaseFileCount = res.openCaseCount;
        this.checkCaseFileCountConditions();
      }
    })


    this.healthregionService.getHealthRegions().subscribe({
      next: (data) => {
        this.healthRegions = data;
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      }
    });

    this.afterConfirmModalOps();
  }

  afterConfirmModalOps() {
    this.modalSub = this._modalservice.getButtonClicked().subscribe(button => {
      switch (button) {
        case "DEACTIVATERA":
          this.rightsadvisorService.disableRightsAdvisor(this.rightsadvisor.rightsAdvisorVersionId).subscribe(data => {
            this._toastService.showMessage('The Rights Advisor has been deactivated.');
            this.router.navigate(['/rights-advisors']);
          });
          break;
        case "RESEND":
          this.rightsadvisorService.resentRightsAdvisorActivationEmail(this.rightsadvisor.rightsAdvisorVersionId).subscribe(data => {
            this._toastService.showMessage('Activation email has been resent.');
            this.router.navigate(['/rights-advisors']);
          })
          break;
        case "CANCEL":
          this._modalservice.closeModal();
          break;
        case null: {
          this._modalservice.closeModal();
          break;
        }
        default:
          this._modalservice.closeModal();
      }
    });
  }



  checkCaseFileCountConditions(): void {

    if (this.openCaseFileCount > 0) {
      this.RADeactivationLine = "This rights advisor cannot be deactivated because there are cases allocated to rights advisor."

    }
    else {
      this.RADeactivationLine = "This rights advisor can be deactivated."
    }
  }

  updateRightsAdvisor() {
    this.rightsadvisorService.updateRightsAdvisor(this.rightsadvisor).subscribe(data => {
      this.router.navigate(['/rights-advisors']);
    })
  }
  compareHealthRegion(object1: any, object2: any) {
    return object1 && object2 && object1.healthregionversionid == object2.healthregionversionid;
  }

  checkboxchecked(): void {
    if (this.openCaseFileCount > 0 && this.checkboxFlag) {
      this.deactivateButton = false
    }
    else if (this.openCaseFileCount == 0 && this.checkboxFlag) {
      this.deactivateButton = true
    }
    else {
      this.deactivateButton = false;
    }
  }

  disableRA() {
    this.askConfirmation('Deactivate', 'DEACTIVATERA', 'Close', 'CANCEL', 'Are you sure you want to deactivate this rights advisor?', 'Deactivate Rights Advisor')
  }

  askConfirmation(okButtonText: string, okButtonValue: string, cancelButtonText: string, cancelButtonValue: string, content: string, title: string) {
    this.modalContent = content
    this.modalTitle = title;
    this.modalOkButtonValue = okButtonValue;
    this.modalCancelButtonValue = cancelButtonValue;
    this.modalOkButtonText = okButtonText;
    this.modalCancelButtonText = cancelButtonText;
    this._modalservice.openModal();
  }

  ngOnDestroy(): void {
    if (this.modalSub) {
      this.modalSub.unsubscribe();
    }
  }

  resendEmail() {
    this.askConfirmation('Resend Email', 'RESEND', 'Close', 'CANCEL', 'Are you sure you want to resend the activation email to this rights advisor??', 'Resend Activation Email');
    // this.rightsadvisorService.resendEmail(this.rightsadvisor).subscribe(data => {
    // 	this.router.navigate(['/rights-advisors']);
    // })
  }

}



