<div class="clear"></div>
<div class="caseFileTableContainer allFilesContainer" [hidden]="!auditlogVisible">
<mat-accordion>
	<mat-expansion-panel>
	  <mat-expansion-panel-header>
		<mat-panel-title>
			<div class="expansion-panel-header">
				<label class="title">Audit Logs</label>
			</div>
		</mat-panel-title>
	  </mat-expansion-panel-header>
	  <!-- Content for Panel 1 -->
	
	
	<div class="clear"></div>
	<div class="tableContents">
		<ng-container #allFilesTable *ngTemplateOutlet="
        dfAuditTable;
        context: { data: dfAuditLogs, headerVisible: true }
        "></ng-container>
		<!-- <mat-paginator #allFilesPaginator [length]="this.totalRecords" [pageSize]="10"
            [pageSizeOptions]="[10, 25, 50]" (page)="pageEvent = $event" ariaLabel=''></mat-paginator> -->
	</div>


<ng-template #dfAuditTable let-data="data" let-headerVisible="headerVisible">
	<table mat-table class="fileList primary" [dataSource]="data">
		<ng-container matColumnDef="User">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by File ID">
				User
			</th>
			<td mat-cell *matCellDef="let rfiAuditLogs">
				{{rfiAuditLogs.agentName}}<br/>({{ rfiAuditLogs.user }})</td>
		</ng-container>

		<ng-container matColumnDef="Date">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Date">
				Date
			</th>
			<td mat-cell *matCellDef="let rfiAuditLogs">{{ rfiAuditLogs.date| date : "dd/MM/yyyy HH:mm" }}
			</td>
		</ng-container>

		<ng-container matColumnDef="Event">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Date">
				Event
			</th>
			<td mat-cell *matCellDef="let rfiAuditLogs">{{ enumdescservice.getDescription(rfiAuditLogs.auditEventType)}}
			</td>
		</ng-container>

		<ng-container matColumnDef="Details">
			<th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Name">
				Details
			</th>
			<td mat-cell *matCellDef="let rfiAuditLogs">
				<table class="nestedtable">
					<ng-container *ngIf="rfiAuditLogs.changes.length>0">
					<tr>
						<th> Field Name</th><th>Old Value</th><th>New Value</th>
					</tr>
				</ng-container>
					<ng-container *ngFor="let change of rfiAuditLogs.changes">
					<ng-container *ngIf="!change.hidden">
					<tr>
						<td>{{change.fieldName}}</td><td>{{checkIfValidDate(change.oldValue)}}</td><td>{{checkIfValidDate(change.newValue)}}</td>
					</tr>
				</ng-container>
					</ng-container>
				</table>
				
			</td>
		</ng-container>

		<ng-container *ngIf="headerVisible">
			<tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="tableHeader"></tr>
		</ng-container>
		<tr mat-row *matRowDef="let rfiForm; columns: columnsToDisplay"></tr>
	</table>
</ng-template>
</mat-expansion-panel>
</mat-accordion>
</div>
