import { PaginationQuery } from "../pagination/pagination-query"
import { RequstForInformationStatus } from "./request-for-information-status"


export type RequstForInformationQueryFilter = PaginationQuery & {
    id: number,
    NameTerm: string,
    TreatmentTypeTerm: number,
    PhoneTerm: string,
    EmailTerm: string,
    InquiryReceivedByTerm: number,
    StartDate:string,
    EndDate:string,
    Status: RequstForInformationStatus,
    OrderByProperty: string,
    OrderByDirection: string,
}

export function defaultRFIQueryFilters(): RequstForInformationQueryFilter {
    return {
      id: 0,
      NameTerm: '',
      TreatmentTypeTerm: 0,
      PhoneTerm: '',
      EmailTerm: '',
      InquiryReceivedByTerm: 0,
      StartDate: '',
      EndDate: '',
      Status: 0,
      PageNumber: 0,
      PageSize: 25,
      OrderByProperty: "",
      OrderByDirection: "",
    };
}