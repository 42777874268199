import { Injectable } from '@angular/core';
import { ApplicationRoles } from '../components/users/user-roles';
import { AuthModel, defaultAuthModelValues } from '../components/authorize/auth-model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    constructor() { }

    getAuthToken(): any {
        if (localStorage.getItem('token') == null) {
            return null;
        }
        return localStorage.getItem('token');
    }

    logout(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiry');
        localStorage.removeItem('role');
    }

    checkTokenExpiry(): Boolean {
        const storeDate = localStorage.getItem('tokenExpiry');
        const tokenExpiry = storeDate ? new Date(storeDate) : new Date()
        const currentDate = new Date();
        const currentUtcTime = Date.UTC(
            currentDate.getUTCFullYear(),
            currentDate.getUTCMonth(),
            currentDate.getUTCDate(),
            currentDate.getUTCHours(),
            currentDate.getUTCMinutes(),
            currentDate.getUTCSeconds(),
            currentDate.getUTCMilliseconds()
        );
        if (tokenExpiry > new Date(currentUtcTime)) {
            return true;
        }
        else {
            this.logout();
            return false;
        }
    }

    getRole(): ApplicationRoles {
        const a = localStorage.getItem('role')
        const roleId: number | null = a !== null ? parseInt(a) : null;
        return roleId as ApplicationRoles
    }

    setUserLoginData(authmodel: AuthModel) {
        localStorage.setItem('username', authmodel.username);
        localStorage.setItem('token', authmodel.token);
        localStorage.setItem('tokenExpiry', authmodel.expiryUtc)
        localStorage.setItem('role', authmodel.roles.toString());
        localStorage.setItem('agentname', authmodel.agentName)
        localStorage.setItem('passwordchangerequireddays', authmodel.passwordChangeRequiredDays.toString())
        localStorage.setItem('userDbId', authmodel.userDbId.toString())
    }

    getUserLoginData(): AuthModel {
        let userData = defaultAuthModelValues();
        userData.agentName = localStorage.getItem('agentname')!,
            userData.username = localStorage.getItem('username')!,
            userData.token = localStorage.getItem('token')!,
            userData.expiryUtc = localStorage.getItem('tokenExpiry')!
        userData.passwordChangeRequiredDays = parseInt(localStorage.getItem('passwordchangerequireddays')!)
        userData.userDbId = parseInt(localStorage.getItem('userDbId')!)
        return userData;
    }
}