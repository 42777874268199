<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container">
    <h1>Unit</h1>
  </div>
</div>
<div class="clear"></div>
<div class="container">
  <div class="alert alert-danger" role="alert" *ngIf="validationErrors.length > 0">
    <ul>
      <li *ngFor="let error of validationErrors">
        {{ error }}
      </li>
    </ul>
  </div>
</div>
<form #addUnitForm="ngForm">
<div class="container">
  <div class="content" role="main">
    <a routerLink="/editfacility/{{ unitObj.facilityVersionId }}" class="back-to-previous">&#8592; Back to Facility</a>
    <h2 class="admin-subheading">Add Unit</h2>

    <div class="facility-heading">Unit Information</div>
    <div class="designatedFacilityForm">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Unit Name</mat-label>
        <input matInput [(ngModel)]="unitObj.unitName" class="form-control-textbox" name="facilityUnitName" #facilityUnitName="ngModel" required/>
        <mat-error *ngIf="facilityUnitName.invalid && facilityUnitName.touched">This field is required.</mat-error>
      </mat-form-field>
      
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Facility</mat-label>
        <mat-select placeholder="Select City" name="facility" [(ngModel)]="unitObj.facilityVersionId" disabled="true">
          <mat-option *ngFor="let facility of facilityList" [value]="facility.designatedFacilityVersionId">{{
            facility.facilityName }}</mat-option>
        </mat-select>

      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Address</mat-label>
        <input matInput [(ngModel)]="unitObj.address" class="form-control-textbox" name="facilityUnitAddress" #facilityUnitAddress="ngModel" required />
        <mat-error *ngIf="facilityUnitAddress.invalid && facilityUnitAddress.touched">This field is required.</mat-error>
      </mat-form-field>

      <div class="form-buttons">
        <input type="submit" (click)="saveUnit(addUnitForm)" class="submit-form-button" value="Submit" />
        <button routerLink="/editfacility/{{this.unitObj.facilityVersionId}}" class="submit-form-button cancel-button">Cancel</button>
      </div>
    </div>
  </div>
</div>
</form>
<app-footer></app-footer>