import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class HealthRegionService {

	regionApiUrl: string = environment.apiUrl + 'HealthRegions';
	authorityApiUrl: string = environment.apiUrl + 'HealthAuthorities';

	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(
		private http: HttpClient,) { }

	getHealthRegions(): Observable<HealthRegion[]> {
		return this.http.get<any>(this.regionApiUrl);
	}

	getHealthAuthorities(): Observable<HealthAuthority[]> {
		return this.http.get<any>(this.authorityApiUrl);
	}
}

export type HealthAuthority = {
	healthAuthorityId: number;
	healthAuthorityName: string;
	requireHealthRegionSelection: boolean;
}

export type HealthRegion = {
	healthRegionId: number;
	healthRegionName: string;
}
