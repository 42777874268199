import { Component, OnInit, ViewChild } from '@angular/core';
import { CaseFileService } from 'src/app/services/case-file.service';
import { BlankCaseFile } from '../case-file/case-file';
import { ActivatedRoute, Router } from '@angular/router';
import { Section1Component } from '../intake-form-sections/section1/section1.component';
import { Section2Component } from '../intake-form-sections/section2/section2.component';
import { casefileMeetingAttendees } from '../case-file/case-file-meeting-attendees';
import { SectionCasefileDetailsComponent } from '../intake-form-sections/section-casefile-details/section-casefile-details.component';
import { RightsAdvisorService } from 'src/app/services/rights-advisor.service';
import { Section3RaComponent } from '../intake-form-sections/section3-ra/section3-ra.component';
import { SectionAssignRaComponent } from '../intake-form-sections/section-assign-ra/section-assign-ra.component';
import {
  caseFileSections,
  defaultCaseFileSectionValues,
} from '../case-file/case-file-sections';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationRoles } from '../users/user-roles';
import { CaseFileStatus } from '../case-file/case-file-status';
import { ModalService } from 'src/app/services/modal.service';
import { ToastService } from 'src/app/services/toast-service.service';
import { HttpStatusCode } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Section5IrasComponent } from '../intake-form-sections/section5-iras/section5-iras.component';
import { Section4RaComponent } from '../intake-form-sections/section4-ra/section4-ra.component';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatSelectChange } from '@angular/material/select';
import { Section3IrasComponent } from '../intake-form-sections/section3-iras/section3-iras.component';
import { CMHAAgentService } from 'src/app/services/cmhaagents.service';
import { Section4IrasComponent } from '../intake-form-sections/section4-iras/section4-iras.component';

@Component({
  selector: 'app-intake-form',
  templateUrl: './intake-form.component.html',
  styleUrls: ['./intake-form.component.scss'],
})
export class IntakeFormComponent implements OnInit {
  @ViewChild(Section1Component) section1!: Section1Component;
  @ViewChild(Section2Component) section2!: Section2Component;
  @ViewChild(SectionCasefileDetailsComponent)
  sectioncasefiledetail!: SectionCasefileDetailsComponent;
  @ViewChild(Section3RaComponent) section3ra!: Section3RaComponent;
  @ViewChild(Section3IrasComponent) section3iras!: Section3IrasComponent;
  @ViewChild(Section4RaComponent) section4ra!: Section4RaComponent;
  @ViewChild(Section4IrasComponent) section4iras!: Section4IrasComponent;
  @ViewChild(SectionAssignRaComponent)
  sectionassignra!: SectionAssignRaComponent;
  @ViewChild(Section5IrasComponent) section5!: Section5IrasComponent;
  @ViewChild(NgForm) parentForm!: NgForm;

  caseFile = BlankCaseFile();
  meetingFormats: any[] = [];
  meetingAttendees: casefileMeetingAttendees[] = [];
  action: string = 'create';
  casefileid: number = 0;
  submitButtonVisibility: boolean = true;
  editButtonVisibility: boolean = true;
  saveandcloseButtonVisibility: boolean = true;
  maId1: number = 0;
  maId2: number = 0;
  caseFileSections: caseFileSections = defaultCaseFileSectionValues();
  caseFileStatus: CaseFileStatus = CaseFileStatus.Create;
  cancelButtonLink: string = '';
  validationErrors: string[];
  apiCalled: boolean = false;
  modalSub!: Subscription;

  modalOpen = this._modalservice.getModalState();
  modalContent = '';
  modalTitle = '';
  modalOkButtonValue = 'OK';
  modalCancelButtonValue = 'CANCEL';
  modalOkButtonText = 'Ok';
  modalCancelButtonText = 'Cancel';
  saveButtonText = 'Save';
  allowSaveOnceConfirmed: boolean = true;

  formSubmitted: boolean = false;
  currentRole: ApplicationRoles = ApplicationRoles.NO_ROLE;
  showBackLink: boolean = false;

  ICSaveAndCloseButtonDisbled=true;
  ICisSaveAndCloseClicked=false;
  ICSaveClicked=false;
  ICSaveDisabled=false;

  list1: any[] = [];
  list2: any[] = [];

  constructor(
    private casefileService: CaseFileService,
    private router: Router,
    private route: ActivatedRoute,
    private _raservice: RightsAdvisorService,
    private _authservice: AuthService,
    private _modalservice: ModalService,
    private _toastService: ToastService,
    private datepipe: DatePipe,
    private _cmhaagentservice: CMHAAgentService
  ) {
    this.validationErrors = [];
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      //this.action = params['action'];
      this.action = this.route.snapshot.data['action'];
      const caseFileId = params['casefileid'];
      if (caseFileId === undefined) {
        this.casefileid = 0;
      } else {
        this.casefileid = caseFileId;
      }
      this.caseFile.id = this.casefileid;
      this.currentRole = this._authservice.getRole();
      if (
        this.currentRole != ApplicationRoles.NO_ROLE &&
        this.currentRole != ApplicationRoles.DESIGNATED_FACILITY
      ) {
        this.showBackLink = true;
      }
    });

    this.afterConfirmModalOps();

    this.casefileService.getCaseFileSections(this.casefileid ?? 0).subscribe({
      next: (response) => {
        this.caseFileSections = response;
        this.caseFileStatus = response.caseFileStatus;
        this.setSaveButtonText();
      },
      error: (errorMessage) => {
        console.log('Error:' + errorMessage);
      },
    });

    this._raservice.getActiveRightsAdvisorsList().subscribe({
      next: (res) => {
        this.section3ra.raList = res;
        this.sectionassignra.raList = res;
      },
      error: (e) => {
        console.error('Error:' + e);
      },
      complete: () => {},
    });

    switch (this.action) {
      case 'view':
        this.casefileService.getCaseFileDetails(this.casefileid).subscribe({
          next: (response) => {
            this.caseFile = response;
            this.fillCaseFileDetails(response);
            this.fillFacilityUnitData(response);
            this.meetingAttendeesRadSelect();
            this.fillMeetingAttendees();
            this.fillSection3IRAS(response);
            // This should probably come from 'getCaseFileDetails'.
          },
          error: (e) => {
            if (e.error !== '') {
              this._toastService.showMessage(e.error);
              this.router.navigateByUrl('/dashboard-cmha');
            }
          },
          complete: () => {
            this.fillSection4Details();
            if (this.caseFile.rightsAdvisorVersionId) {
              this.caseFileSections.section3IRASEnabled = false;
              this.caseFileSections.section4IRASEnabled = false;
            }
          },
        });
        break;

      case 'create':
        this.submitButtonVisibility = true;
        this.editButtonVisibility = false;
        this.saveandcloseButtonVisibility = false;
        break;
    }
  }

  ngAfterViewInit(): void {}
  //#region casefileoperations
  addCaseFile() {
    if (this.section1.meetingAttendeeFlag == false) {
      this.addAttendee(
        this.maId1,
        this.section1.maFirstName1,
        this.section1.maRelationship1,
        this.section1.maEmail1,
        this.section1.maNotes1
      );
      if (this.section1.maFirstName2.trim() != '') {
        this.addAttendee(
          this.maId2,
          this.section1.maFirstName2,
          this.section1.maRelationship2,
          this.section1.maEmail2,
          this.section1.maNotes2
        );
      }
    }
    this.formSubmitted = true;
    if (!this.section1.validateSection1()) {
      this._toastService.showMessage(
        'Errors on form. Please verify your answers.'
      );
      return;
    }
    if (!this.section2.validateSection2()) {
      this._toastService.showMessage(
        'Errors on form. Please verify your answers.'
      );
      return;
    }

    this.caseFile.meetingAttendees = this.meetingAttendees;
    let role = this.currentRole;

    switch (role) {
      case ApplicationRoles.CMHA_INTAKE:
        this.saveIntakeFormCMHA();
        break;

      case ApplicationRoles.CMHA_ADMIN:
        this.saveIntakeFormCMHA();
        break;

      case ApplicationRoles.DESIGNATED_FACILITY:
        this.askConfirmation(
          'Submit',
          'SAVEINTAKEFORMDF',
          'Cancel',
          'CANCEL',
          'Are you sure you want to submit this form?  You will not be able to make changes online after you submit this form',
          'Submit Form'
        );

        break;
    }
  }

  saveIntakeFormCMHA(): void {
    this.casefileService.saveIntakeFormCMHA(this.caseFile).subscribe({
      next: (resp) => {
        this.casefileid = resp.caseFileId;
        this.router.navigate(['/dashboard-cmha']).catch(() => {
          console.log('Navigation Failure');
        });
      },
      error: (error) => {
        console.log(error, 'Unable to connect to CaseFile API Endpoint');
      },
      complete: () => {},
    });
  }

  saveIntakeFormDF(): void {
    this.casefileService.saveIntakeFormDF(this.caseFile).subscribe({
      next: (resp) => {
        this.casefileid = resp.caseFileId;
        this.router.navigate(['/thankyou/' + this.casefileid]).catch(() => {
          console.log('Navigation Failure');
        });
      },
      error: (error) => {
        if (error.status === HttpStatusCode.BadRequest) {
          this.validationErrors = [];
          let validationErrorDictionary = error.error;

          this.section1.submittedErrors = error.error;
          this.section2.submittedErrors = error.error;

          for (let fieldName in validationErrorDictionary) {
            if (validationErrorDictionary.hasOwnProperty(fieldName)) {
              this.validationErrors.push(validationErrorDictionary[fieldName]);
            }
          }
        } else {
          console.log(error, 'Unable to connect to CaseFile API Endpoint');
        }
      },
      complete: () => {},
    });
  }

  checkMeetingAttendees(): boolean {
    for (let i = 0; i < this.caseFile.meetingAttendees.length; i++) {
      this.list1.push(
        this.caseFile.meetingAttendees[i].maFirstName,
        this.caseFile.meetingAttendees[i].maRelationship,
        this.caseFile.meetingAttendees[i].maEmail,
        this.caseFile.meetingAttendees[i].maNotes
      );
    }

    if (this.section1.maFirstName1 != '') {
      this.list2.push(
        this.section1.maFirstName1,
        this.section1.maRelationship1,
        this.section1.maEmail1,
        this.section1.maNotes1
      );
    }
    if (this.section1.maFirstName2 != '') {
      this.list2.push(
        this.section1.maFirstName2,
        this.section1.maRelationship2,
        this.section1.maEmail2,
        this.section1.maNotes2
      );
    }
    if (this.list1.length !== this.list2.length) {
      return false; // If lengths are different, arrays are not equal
    }

    for (let i = 0; i < this.list1.length; i++) {
      if (this.list1[i] !== this.list2[i]) {
        return false; // If any element differs at the same index, arrays are not equal
      }
    }

    return true; // Arrays are equal if they have the same length and elements
  }

  updateCaseFile() {

    if (!this.checkMeetingAttendees()) {
      if (this.section1.meetingAttendeeFlag == false) {
        this.addAttendee(
          this.maId1,
          this.section1.maFirstName1,
          this.section1.maRelationship1,
          this.section1.maEmail1,
          this.section1.maNotes1
        );
        if (this.section1.maFirstName2.trim() != '') {
          this.addAttendee(
            this.maId2,
            this.section1.maFirstName2,
            this.section1.maRelationship2,
            this.section1.maEmail2,
            this.section1.maNotes2
          );
        }
      } else this.caseFile.meetingAttendees = [];
    }
    let role = this.currentRole;
    this.caseFile.id = this.casefileid;
 
    switch (true) {
      case this.caseFileStatus === CaseFileStatus.ForReview &&
        role === ApplicationRoles.CMHA_INTAKE:
        this.ICSaveClicked = true;
        if(!this.section1.validateSection1())
        {
          this._toastService.showMessage("Error on case file. Please verify your answers")
          return;
        }
        this.casefileService.updateCaseFileIRAS(this.caseFile).subscribe({
          next: () => {
            this._toastService.showMessage('The case has been saved.');
            this.router.navigate(['/dashboard-cmha']);
          },
           
        });
        break;

      case this.caseFileStatus === CaseFileStatus.RAAssigned &&
        role === ApplicationRoles.CMHA_INTAKE:
        this.casefileService
          .updateCaseFileIRASAndRAAssigned(this.caseFile)
          .subscribe({
            next: () => {
              this._toastService.showMessage('The case has been saved.');
              this.router.navigate(['/dashboard-cmha']);
            },
          });
        break;

      case this.caseFileStatus === CaseFileStatus.RAAssigned &&
        role === ApplicationRoles.RIGHTS_ADVISOR:
        this.formSubmitted = true;
        if(!this.section3ra.validateSection3RA())
        {
          this._toastService.showMessage('Errors on form. Please verify your answers.');
          return ;
        }
        if(!this.section4ra.validateSection4RA())
        {
          this._toastService.showMessage('Errors on form. Please verify your answers.');
          return;
        }
        
        this.askConfirmation(
          'Save and Close',
          'SAVEANDCLOSERA',
          'Cancel',
          'CANCEL',
          'Are you sure you want to save and close this case? You will not be able to make changes to this case once it is closed.',
          'Save and Close Case'
        );

        break;
      default:
        break;
    }
  }

  closeCaseFileIRAS() {
    this.ICisSaveAndCloseClicked=true;
    this.ICSaveClicked=true;
    if(!this.section1.validateSection1())
    {
      this._toastService.showMessage('Errors on form.Please verify your answers.')
      return;
    }
    if(!this.section2.validateSection2())
    {
      this._toastService.showMessage('Errors on form.Please verify your answers.')
      return;
    }
    if(!this.section3iras.validateSection3IRAS())
    {
      this._toastService.showMessage('Errors on form.Please verify your answers.')
      return;
    }
    if(!this.section4iras.validateSection4IRAS())
    {
      this._toastService.showMessage('Errors on form.Please verify your answers.')
      return;
    }
    this.askConfirmation(
      'Save and Close',
      'SAVEANDCLOSEIRAS',
      'Cancel',
      'CANCEL',
      'Are you sure you want to save and close this case? You will not be able to make changes to this case once it is closed.',
      'Save and Close Case'
    );
  }

  issueNewPDF() {
    this.askConfirmation(
      'Issue New Link',
      'ISSUENEWPDF',
      'Cancel',
      'CANCEL',
      'Are you sure you want to issue a new link to this completed Request for Rights Advice form?',
      'Issue New Link'
    );
  }
  //#endregion

  //#region otherOperations
  setSaveButtonText() {
    let role = this.currentRole;

    this.allowSaveOnceConfirmed = true;
    if (this.caseFileSections.section5IRASEnabled) {
      this.allowSaveOnceConfirmed = this.section5.confirmationOfChanges;
    }

    switch (this.caseFileStatus && role) {
      case CaseFileStatus.ForReview && ApplicationRoles.CMHA_INTAKE:
        this.saveButtonText = 'Save';
        break;

      case CaseFileStatus.RAAssigned && ApplicationRoles.CMHA_INTAKE:
        this.saveButtonText = 'Save';
        break;

      case CaseFileStatus.RAAssigned && ApplicationRoles.RIGHTS_ADVISOR:
        this.saveButtonText = 'Save and Close';
        break;

      default:
        break;
    }
  }

  addAttendee(
    id: number,
    mafirstname: string,
    maRelationship: string,
    maEmail: string,
    maNotes: string
  ) {
    this.meetingAttendees.push({
      id: id,
      maFirstName: mafirstname,
      maLastName: '',
      maEmail: maEmail,
      maPhoneNumber: '',
      maRelationship: maRelationship,
      maNotes: maNotes,
    });
    this.caseFile.meetingAttendees = this.meetingAttendees;
  }

  meetingAttendeesRadSelect(): void {
    if (this.caseFile.meetingAttendees.length > 0) {
      this.section1.maradiobuttonvalue = '2';
      this.section1.meetingAttendeeFlag = false;
    } else {
      this.section1.maradiobuttonvalue = '1';
      this.section1.meetingAttendeeFlag = true;
    }
  }

  fillMeetingAttendees(): void {
    this.section1.maFirstName1 =
      this.caseFile.meetingAttendees[0] == null
        ? ''
        : this.caseFile.meetingAttendees[0].maFirstName;
    this.section1.maRelationship1 =
      this.caseFile.meetingAttendees[0] == null
        ? ''
        : this.caseFile.meetingAttendees[0].maRelationship;
    this.section1.maEmail1 =
      this.caseFile.meetingAttendees[0] == null
        ? ''
        : this.caseFile.meetingAttendees[0].maEmail;
    this.section1.maNotes1 =
      this.caseFile.meetingAttendees[0] == null
        ? ''
        : this.caseFile.meetingAttendees[0].maNotes;
    this.section1.maId1 =
      this.caseFile.meetingAttendees[0] == null
        ? 0
        : this.caseFile.meetingAttendees[0].id;

    this.section1.maFirstName2 =
      this.caseFile.meetingAttendees[1] == null
        ? ''
        : this.caseFile.meetingAttendees[1].maFirstName;
    this.section1.maRelationship2 =
      this.caseFile.meetingAttendees[1] == null
        ? ''
        : this.caseFile.meetingAttendees[1].maRelationship;
    this.section1.maEmail2 =
      this.caseFile.meetingAttendees[1] == null
        ? ''
        : this.caseFile.meetingAttendees[1].maEmail;
    this.section1.maNotes2 =
      this.caseFile.meetingAttendees[1] == null
        ? ''
        : this.caseFile.meetingAttendees[1].maNotes;
    this.section1.maId2 =
      this.caseFile.meetingAttendees[1] == null
        ? 0
        : this.caseFile.meetingAttendees[1].id;
  }

  fillFacilityUnitData(response: any): void {
    this.section2.facilityid = response.facilityVersionId;
    this.section2.facilityCity = response.facilityCity;
    this.section2.drpfacilitychange();
    this.caseFile.unitVersionId = response.unitVersionId;
    this.section2.unitaddress = response.unitAddress;
  }

  fillCaseFileDetails(response: any): void {
    this.sectioncasefiledetail.caseFileId = this.casefileid.toString();
    this.sectioncasefiledetail.rightsAdvisorName = response.rightsAdvisorName;
    this.sectioncasefiledetail.requestdatetime = this.convertToLongDateFormat(
      response.cmhaDateRequestReceived + ' ' + response.cmhaTimeRequestReceived
    );
  }

  fillSection3IRAS(response: any): void {
    let casefilestatus = response.isCaseClosed;
    this._cmhaagentservice.getIntakeAgents().subscribe({
      next: (res) => {
        this.section3iras.cmhaAgentsList = res;
        switch (casefilestatus) {
          case true:
            this.caseFile.cmhaIntakeAgentVersionId =
              response.cmhaIntakeAgentVersionId;
            break;
          case false:
            let cmhausername = this._authservice.getUserLoginData().agentName;
            const selectedItem = this.section3iras.cmhaAgentsList.find(
              (item) => item.cmhaIntakeAgentName == cmhausername
            );
            if (selectedItem) {
              this.caseFile.cmhaIntakeAgentVersionId =
                selectedItem.cmhaIntakeAgentVersionId;
            }
            break;
        }
      },
      error: (e) => {
        console.error('Error:' + e);
      },
      complete: () => {
        this.section3iras.intakeAgentDDDisabled = true;
      },
    });
  }

  convertToLongDateFormat(datestring: string): string {
    const dateObj = new Date(datestring);
    return this.datepipe.transform(dateObj, 'EEEE, yyyy-MM-dd hh:mm a')!;
  }

  fillSection4Details(): void {
    this.section4ra.currentMeetingStatusType =
      this.caseFile.raAdditionalInformationMeetingStatusVersionId;
    this.section4ra.caseFile = this.caseFile;

    // Have to toggle this here otherwise section 4 won't show the panel
    this.section4ra.toggleMeetingStatusChange();
    this.section4ra.toggleDefaultOptionsOnLoad();
  }

  goback() {
    let role = this.currentRole;

    switch (role) {
      case ApplicationRoles.CMHA_ADMIN:
        this.router.navigate(['/dashboard-cmha']);
        break;

      case ApplicationRoles.CMHA_INTAKE:
        this.router.navigate(['/dashboard-cmha']);
        break;

      case ApplicationRoles.RIGHTS_ADVISOR:
        this.router.navigate(['/dashboard-ra']);
        break;

      case ApplicationRoles.DESIGNATED_FACILITY:
        this.askConfirmation(
          'Ok',
          'OK',
          'Cancel',
          'CANCEL',
          'Are you sure you want to cancel this form? This form will not be submitted if you choose to cancel it.',
          'Close Form'
        );
        break;

      default:
        //write code for back button
        window.history.back();
    }
  }

  askConfirmation(
    okButtonText: string,
    okButtonValue: string,
    cancelButtonText: string,
    cancelButtonValue: string,
    content: string,
    title: string
  ) {
    this.modalContent = content;
    this.modalTitle = title;
    this.modalOkButtonValue = okButtonValue;
    this.modalCancelButtonValue = cancelButtonValue;
    this.modalOkButtonText = okButtonText;
    this.modalCancelButtonText = cancelButtonText;

    this._modalservice.openModal();
  }

  afterConfirmModalOps() {
    this.modalSub = this._modalservice
      .getButtonClicked()
      .subscribe((button) => {
        switch (button) {
          case 'OK':
            this._authservice.logout();
            this.router.navigate(['/login']);
            break;

          case 'SAVEANDCLOSEIRAS':
            this.caseFile.isCaseClosed = true;
            this.casefileService
              .updateAndCloseCaseFileIRAS(this.caseFile)
              .subscribe({
                next: () => {
                  this._toastService.showMessage('This case has been closed');
                  this.router.navigate(['/dashboard-cmha']);
                  this.apiCalled = true;
                },
                error: (error) => {
                  if (error.status === HttpStatusCode.BadRequest) {
                    this.validationErrors = [];
                    let validationErrorDictionary = error.error;
          
                    this.section1.submittedErrors = error.error;
                    this.section2.submittedErrors = error.error;
          
                    for (let fieldName in validationErrorDictionary) {
                      if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                        this.validationErrors.push(validationErrorDictionary[fieldName]);
                      }
                    }
                    console.log("valdation errors")
                    console.log(this.validationErrors)
                  } else {
                    console.log(error, 'Unable to connect to CaseFile API Endpoint');
                  }
                }
              });
            break;

          case 'SAVEANDCLOSERA':
            this.caseFile.isCaseClosed = true;
            this.casefileService.updateCaseFileRA(this.caseFile).subscribe({
              next: () => {
                this._toastService.showMessage('This case has been closed ');
                this.router.navigate(['/dashboard-ra']);
                this.apiCalled = true;
              },error: (error) => {
                if (error.status === HttpStatusCode.BadRequest) {
                  this.validationErrors = [];
                  let validationErrorDictionary = error.error;
        
                  this.section1.submittedErrors = error.error;
                  this.section2.submittedErrors = error.error;
        
                  for (let fieldName in validationErrorDictionary) {
                    if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                      this.validationErrors.push(validationErrorDictionary[fieldName]);
                    }
                  }
                } else {
                  console.log(error, 'Unable to connect to CaseFile API Endpoint');
                }
              },
            });
            break;

          case 'ISSUENEWPDF':
            console.log(this.caseFile.id);
            this.casefileService.issueNewPDFLink(this.casefileid).subscribe({
              next: (data) => {
                this._toastService.showMessage(
                  'A New PDF link has been issued'
                );
                this.router.navigate(['/dashboard-cmha']);
              },
              error: (err) => {
                this._toastService.showMessage(
                  'Error occured in issuing a new link. Please try again'
                );
                this.router.navigate(['/dashboard-cmha']);
              },
            });
            break;

          case 'SAVEINTAKEFORMDF':
            this.saveIntakeFormDF();
            break;

          case 'CANCEL':
            this._modalservice.closeModal();
            break;

          case null:
            this._modalservice.closeModal();
            break;

          default:
            this._modalservice.closeModal();
        }
      });
  }

  onRAChange(event: MatSelectChange) {
    if (event.value != 0) {
      this.caseFileSections.section3IRASEnabled = false;
      this.caseFileSections.section4IRASEnabled = false;
      this.ICSaveAndCloseButtonDisbled = true;
    }
  }

  onCMHASection3And4Change(event: any) {
    console.log("event");
    console.log(event);
    if (event != 0) {
      this.caseFileSections.sectionAssignRAEnabled = false;
      this.ICSaveAndCloseButtonDisbled=false;
      this.ICSaveDisabled=true;
    }
    else
    {
      this.ICSaveDisabled=false;
    }
    if(this.caseFile.cmhaMeetingStatusVersionId ==null && this.caseFile.cmhaAdditionalInformationMeetingStatusVersionId ==null && this.caseFile.cmhaNotes == '')
    {
      this.ICSaveAndCloseButtonDisbled=true;
    }
  }
  //#endregion

  ngOnDestroy(): void {
    if (this.modalSub) {
      this.modalSub.unsubscribe();
    }
  }
}
