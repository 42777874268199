import { Component, OnInit } from '@angular/core';
import { UnitService } from 'src/app/services/unit-service';
import { Units, defaultUnitValues } from 'src/app/components/unit/unit';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CaseFileService } from 'src/app/services/case-file.service';
import { CaseFileCount } from 'src/app/components/case-file/case-file-count';
import { Location } from '@angular/common';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
  selector: 'app-editunits',
  templateUrl: './editunits.component.html',
  styleUrls: ['./editunits.component.scss']
})
export class EditUnitsComponent implements OnInit {

  unitObj = defaultUnitValues();
  facilityList: any = [];
  closedCaseFileCount: number = 0;
  openCaseFileCount: number = 0;
  filteredResponse: CaseFileCount[] = [];
  unitDeactivationLine: string = "";
  deactivateButton: boolean = false;
  generatecodeButton: boolean = false;
  checkboxFlag: boolean = false;
  editfacilityurl: string | null = "";
  constructor(
    public unitService: UnitService, 
    public router: Router, 
    public route: ActivatedRoute, 
    private caseFileService: CaseFileService, 
    private toastService: ToastService,
    public location: Location
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.unitObj.unitVersionId = params['id'];
    }
    );

    this.unitService.getUnitDetailsByUnitId(this.unitObj.unitVersionId).subscribe({
      next: (data) => {
        this.unitObj = data;
        this.editfacilityurl = '/editfacility/' + this.unitObj.facilityVersionId;
      }
    })

    this.caseFileService.getCaseFileCountByUnit(this.unitObj.unitVersionId).subscribe({
      next: (response) => {
        this.filteredResponse = response;
        this.closedCaseFileCount = response.filter(x => x.isCaseClosed)[0]?.caseFileCount == null ? 0 : response.filter(x => x.isCaseClosed)[0].caseFileCount;
        this.openCaseFileCount = response.filter(x => !x.isCaseClosed)[0]?.caseFileCount == null ? 0 : response.filter(x => !x.isCaseClosed)[0].caseFileCount;

        this.checkCaseFileCountConditions();
      },
      error: (e) => { console.log("Error getting case file count") },
    })
  }

  checkCaseFileCountConditions(): void {
    if (this.openCaseFileCount > 0) {
      this.unitDeactivationLine = "This facility unit cannot be deactivated because there are open cases using this record."
    }
    else {
      this.unitDeactivationLine = "This facility unit can be deactivated."
    }
  }

  updateUnit(): void {
    this.unitService.updateUnit(this.unitObj).subscribe({
      next: (data) => {
        this.router.navigate([this.editfacilityurl]).catch(() => {
          console.log('Navigation Failure');
        });
      },
      error: (error) => {
        console.log(error, 'Unable to connect to unit API Endpoint');
      },
      complete: () => {
        this.toastService.showMessage('The unit has been saved.');
      }
    });
  }

  disableUnit(): void {
    this.unitService.disableUnit(this.unitObj.unitVersionId).subscribe({
      next: () => {
        this.router.navigate([this.editfacilityurl]);
      },
      error: (err) => { console.log(err) },
      complete: () => {
        this.toastService.showMessage('The unit has been deactivated.');
      }
    })
  }

  checkboxchecked(): void {
    this.generatecodeButton = this.checkboxFlag
    if (this.openCaseFileCount > 0 && this.checkboxFlag) {
      this.deactivateButton = false
    }
    else if (this.openCaseFileCount == 0 && this.checkboxFlag) {
      this.deactivateButton = true
    }
    else {
      this.deactivateButton = false;
    }
  }
}
