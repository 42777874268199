<div class="form-section-header">
    <div class="container">
      <h3><em>Section 3 -</em> TO BE COMPLETED BY RIGHTS ADVISOR</h3>
    </div>
  </div>
  <div class="form-subsection-header">
    <div class="container">
      <h4>Meeting Summary</h4>
    </div>
  </div>
  <div class="form-subsection-content">
    <div class="container">
     
      <fieldset>
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Rights Advisor Name</mat-label>
          <mat-select placeholder="Select One" name="drpRAsection3RA" [(ngModel)]="caseFile.rightsAdvisorVersionId" [disabled]="true">
            <mat-option *ngFor="let ra of raList" [value]="ra.rightsAdvisorVersionId">{{ ra.advisorName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Date Request Received</mat-label>
            <input matInput [matDatepicker]="dpdaterequest" [(ngModel)]="caseFile.raRequestReceivedDate" name="drrsection3RA" placeholder="DD/MM/YYYY" [disabled]="true">
            
            <mat-datepicker-toggle matIconSuffix [for]="dpdaterequest" [disabled]="true"></mat-datepicker-toggle>
            <mat-datepicker #dpdaterequest disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" floatLabel="always" class="timepicker-control">
            <mat-label>Time Request Received</mat-label>
            <input matInput hidden="true" />
                <ngx-timepicker-field [defaultTime]="'10:10 am'" style="border-bottom: 0px;"
                    [(ngModel)]="caseFile.raRequestReceivedTime" [disabled]="true" name="trrsection3ra"></ngx-timepicker-field>
          </mat-form-field> 
      </fieldset>

      <fieldset>
       <div class="form-section-validator-controls w-48">
        <mat-form-field appearance="outline" floatLabel="always" class="w-100">
            <mat-label>Meeting Format</mat-label>
            <mat-select placeholder="Select One" name="drpMeetinFormatsection3RA" [(ngModel)]="caseFile.raMeetingFormatVersionId" 
            [disabled]="isDisabled" #drpmeetingFormatSection3RA="ngModel" required>
              <mat-option *ngFor="let mf of meetingFormats" [value]="mf.id">{{ mf.meetingFormatName}}</mat-option>
            </mat-select>
           </mat-form-field>
           <mat-error *ngIf="parentSubmitted && caseFile.raMeetingFormatVersionId  == null" class="error">Select Meeting Format</mat-error>
          </div>
        
          <div class="form-section-validator-controls w-48">
        <mat-form-field appearance="outline" floatLabel="always" class="w-100">
            <mat-label>Meeting Status</mat-label>
            <mat-select placeholder="Select One" name="drpMeetinStatussection3RA" [(ngModel)]="caseFile.raMeetingStatusVersionId" 
            [disabled]="isDisabled" >
              <mat-option *ngFor="let ms of rameetingStatus" [value]="ms.id">{{ ms.raMeetingStatusName}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="parentSubmitted && caseFile.raMeetingStatusVersionId  == null" class="error">Select Meeting Status</mat-error>
        </div>
      </fieldset>
     <fieldset>
      <div class="form-section-validator-controls w-48">
        <mat-form-field appearance="outline" floatLabel="always" class="w-100 lh-2em">
            <mat-label>Meeting Date</mat-label>
            <input matInput [matDatepicker]="dpmeetingdate" [(ngModel)]="caseFile.raMeetingDate" name="meetingdatesection3RA" placeholder="DD/MM/YYYY" [disabled]="isDisabled">
            <mat-datepicker-toggle matIconSuffix [for]="dpmeetingdate"></mat-datepicker-toggle>
            <mat-datepicker #dpmeetingdate [disabled]="isDisabled"></mat-datepicker>
            
          </mat-form-field>
          <mat-error *ngIf="parentSubmitted && caseFile.raMeetingDate == ''" class="error">Enter Meeting Date</mat-error>
        </div>
        <div class="form-section-validator-controls w-48">
          <mat-form-field appearance="outline" floatLabel="always" class="w-100 timepicker-control" >
            <mat-label>Meeting Time</mat-label>
            <input matInput hidden="true" />
            <ngx-timepicker-field [(ngModel)]="caseFile.raMeetingTime" [disabled]="isDisabled" name="meetintimesection3ra"></ngx-timepicker-field>
          </mat-form-field>
          <mat-error  *ngIf="parentSubmitted && caseFile.raMeetingTime==''" class="error">Enter Meeting Time</mat-error>
        </div>
     </fieldset>
      <fieldset>
        <div class="information-board">
          <div class="container">
          <div class="warning-sign"><img src="/assets/normal_u130.svg" height="34px" width="37px"></div>
          <div class="warning-text"> PRIVACY NOTICE: Notes in this box will be incorporated into the completed 
            Request for Rights Advice form and shared with the designated facility.
             Do not include any personal information in this box unless the person receiving rights advice has 
             specifically agreed to disclosure.
          </div>
          </div>
        </div>
        <div class="clear-10"></div>
      </fieldset>
     <fieldset>
      <div class="form-section-validator-controls w-100">
      <mat-form-field appearance="outline" class="w-100" floatLabel="always">
        <mat-label>Notes</mat-label>
        <textarea matInput class="form-control-textbox w-100" name="facilitynotessection3RA" [(ngModel)]="caseFile.raNotes" [disabled]="isDisabled"></textarea>
      </mat-form-field>
      <mat-error  *ngIf="parentSubmitted && caseFile.raNotes == ''" class="error">Please Input your notes</mat-error> 
    </div>
     </fieldset>
    
    </div>
  </div>