<app-topnav></app-topnav>

<div class="page-heading">
  <div class="container"><h1>Rights Advisors</h1></div></div>
<div class="container">
  <div class="content" role="main">
    <div class="facility-heading">Add Rights Advisor</div>
    <div class="designatedFacilityForm">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          [(ngModel)]="rightsAdvisor.advisorName"
          class="form-control-textbox"
          name="facilityName"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          [(ngModel)]="rightsAdvisor.advisorEmail"
          class="form-control-textbox"
          name="city"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Health Region</mat-label>
        <mat-select placeholder="Select Health Region" name="healthRegion" [(ngModel)]="rightsAdvisor.advisorHealthRegionVersionId" >
          <mat-option *ngFor="let hr of healthRegions" [value]="hr.healthRegionId" >{{ hr.healthRegionName }}</mat-option>
        </mat-select>
        
      </mat-form-field>

      <div class="form-buttons">
        <input
          type="submit"
          (click)="addRightsAdvisor()"
          class="submit-form-button" value="Submit"
        />
        <a routerLink="/rights-advisors" class="submit-form-button cancel-button"
          >Cancel</a
        >
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>