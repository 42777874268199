import { PaginationQuery } from "../pagination/pagination-query"

export type EmailQueueQuery = PaginationQuery & {
    Search: string,
    Status: string,
}

export function defaultEmailQueueQueryValues(): EmailQueueQuery {
    return {
        Search: '',
        Status: 'ALL',
        OrderByDirection: '',
        OrderByProperty: '',
        PageNumber: 0,
        PageSize: 25
    }
}