
<div class="form-section-header">
    <div class="container">
      <h3 class="padding-left-10">Section 3 </h3>
    </div>
  </div>
  <div class="container">
  <div class="form-subsection-header">
       <h4 class="padding-left-10">Additional Information</h4>
    </div>
  </div>
  <div class="form-subsection-content">
    <div class="container">
        <fieldset>
            <label id="label-natureofinquiry">What was the nature of the inquiry? Select all that apply.</label>
          </fieldset>
          <hr/>
        <fieldset>
            <div class="rfi-section-3-checkbox-display-vertical">
             <div *ngFor="let noi of natureOfInquiry" class="with-width">
               <label>
                 <input type="checkbox" [value]="noi" (change)="onNOICheckboxChange(noi)" class="intake-form-radio-button" 
                 [checked]="isItemChecked(noi,requestforinformation.requestForInformationNatureOfInquiryVersion)" [disabled]="isDisabled">
                   {{ noi.natureOfInquiry }}
                 </label>
               </div>
               
            </div>
           </fieldset>
           <div class="clear-10"></div>
           <fieldset>
             <mat-form-field appearance="outline" class="w-100" floatLabel="always" [hidden]="!noiOthersVisible" >
                 
                 <input matInput [(ngModel)]="requestforinformation.requestForInformationNatureOfInquiryOtherText" class="form-control-textbox"
                  name="noiOther" placeholder="If Other, Please specify"/>
               </mat-form-field>
           </fieldset>

           <div class="clear"></div>
           <fieldset>
            <label>Was assistance provided related to rights under the Mental Health Act?</label>
           </fieldset>
           <hr>
           <fieldset>
            <mat-radio-group name="radAssistanceProvided" class="intake-form-radio-group display-vertical" [disabled]="isDisabled" 
            [(ngModel)]="requestforinformation.assistanceProvided" (change)="radAssistanceProvided(requestforinformation.assistanceProvided)">
        <mat-radio-button name="radAssistanceProvidedYes" class="intake-form-radio-button" [value]="true">Yes</mat-radio-button>
        <div class="rfi-section-3-checkbox-display-vertical" [hidden]="!chkboxAssistanceProvidedVisible">
          <div *ngFor="let ap of assistanceProvided" class="with-padding with-width">
            <label>
              <input type="checkbox" [value]="ap" (change)="onAPCheckboxChange(ap)" class="intake-form-radio-button" 
              [checked]="isItemChecked(ap,requestforinformation.requestForInformationAssistanceProvidedVersion)" [disabled]="isDisabled">
                {{ ap.assistanceProvided }}
              </label>
            </div>
            <mat-form-field appearance="outline" class="w-100" floatLabel="always" [hidden]="!apOtherVisible" >
                 
              <input matInput [(ngModel)]="requestforinformation.requestForInformationAssistanceProvidedOtherText" class="form-control-textbox"
               name="apOther" placeholder="If Other, Please specify"/>
            </mat-form-field> 
         </div>
        <mat-radio-button name="radAssistanceProvidedNo" class="intake-form-radio-button" [value]="false">No</mat-radio-button>
      </mat-radio-group>  
           </fieldset>

           <div class="clear"></div>
           <fieldset>
            <label>Were other referrals to community resources made?</label>
           </fieldset>
           <hr/>
           <fieldset>
            <mat-radio-group name="radReferralMade" class="intake-form-radio-group display-vertical" [disabled]="isDisabled" 
            [(ngModel)]="requestforinformation.referralsMade" (change)="radReferralMade(requestforinformation.referralsMade)">
        <mat-radio-button name="radReferralMadeYes" class="intake-form-radio-button" [value]="true">Yes</mat-radio-button>
        <div class="rfi-section-3-checkbox-display-vertical" [hidden]="!chkboxReferralMadeVisible">
          <div *ngFor="let rm of referralMade" class="with-padding with-width">
            <label>
              <input type="checkbox" [value]="rm" (change)="onRMCheckboxChange(rm)" class="intake-form-radio-button" 
              [checked]="isItemChecked(rm,requestforinformation.requestForInformationReferralsMadeVersion)" [disabled]="isDisabled">
                {{ rm.referralMade }}
              </label>
            </div>
            <mat-form-field appearance="outline" class="w-100" floatLabel="always" [hidden]="!rmOtherVisible" >
                 
              <input matInput [(ngModel)]="requestforinformation.requestForInformationReferralsMadeOtherText" class="form-control-textbox"
               name="apOther" placeholder="If Other, Please specify"/>
            </mat-form-field> 
         </div>
        <mat-radio-button name="radReferralMadeNo" class="intake-form-radio-button" [value]="false">No</mat-radio-button>
      </mat-radio-group>  
           </fieldset>

           <div class="clear"></div>
           <fieldset>
            <label id="label-followup">Is a follow up needed by someone? Select all that apply. (Optional)</label>
          </fieldset>
          <hr/>
        <fieldset>
            <div class="rfi-section-3-checkbox-display-vertical">
             <div *ngFor="let fp of followUpNeeded" class="with-width">
               <label>
                 <input type="checkbox" [value]="fp" (change)="onFUNCheckboxChange(fp)" class="intake-form-radio-button" 
                 [checked]="isItemChecked(fp,requestforinformation.requestForInformationFollowUpNeededVersion)" [disabled]="isDisabled">
                   {{ fp.followUpNeeded }}
                 </label>
               </div>
               
            </div>
           </fieldset>
           <div class="clear-10"></div>
           <fieldset>
             <mat-form-field appearance="outline" class="w-100" floatLabel="always" [hidden]="!funOthersVisible" >
                 
                 <input matInput [(ngModel)]="requestforinformation.requestForInformationFollowUpNeededOtherText" class="form-control-textbox"
                  name="funOther" placeholder="If Other, Please specify"/>
               </mat-form-field>
           </fieldset>
           <div class="clear"></div>
           <fieldset>
            <mat-form-field appearance="outline" class="w-100" floatLabel="always">
              <mat-label>Notes (optional)</mat-label>
              <textarea matInput class="form-control-textbox w-100" name="facilitynotes" [(ngModel)]="requestforinformation.notes" [disabled]="isDisabled"></textarea>
            </mat-form-field>
           </fieldset>
           <div class="clear"></div>
       </div>
  </div>
