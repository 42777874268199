import { Component, Input } from '@angular/core';
import { BlankCaseFile, CaseFile } from '../../case-file/case-file';

@Component({
  selector: 'app-section-request-received-info',
  templateUrl: './section-request-received-info.component.html',
  styleUrls: ['./section-request-received-info.component.scss']
})
export class SectionRequestReceivedInfoComponent {
@Input() caseFile:CaseFile=BlankCaseFile();
@Input() isDisabled:boolean=false;
}
