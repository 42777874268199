import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast-service.service';

@Component({
  selector: 'app-changepassword-snackbar',
  templateUrl: './changepassword-snackbar.component.html',
  styleUrls: ['./changepassword-snackbar.component.scss']
})
export class ChangePasswordSnackbarComponent {

  message: string = '';

  
 constructor(private _toastService:ToastService,private router:Router){} 
closesnackbar() {
this._toastService.dismissSnackbar();
}
 changePassword(){
  this.closesnackbar();
  this.router.navigate(['/change-password']);
  
 }

 setMessageFromService(message: string) {
  this.message = message;
}
}
