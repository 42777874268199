<div class="clear"></div>
<div class="dfTableContainer allFilesContainer">
    <div class="filterForm">
        <fieldset>
            <mat-form-field appearance="outline" floatLabel="always" class="w-65-5">
                <mat-label>Search</mat-label>
                <input matInput class="form-control-textbox" name="filterSearch" [(ngModel)]="queryFilter.Search" />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Status</mat-label>
                <mat-select placeholder="Select" name="filterStatus" [(ngModel)]="queryFilter.Status">
                    <mat-option value="ALL">Select</mat-option>
                    <mat-option value="Queued">Queued</mat-option>
                    <mat-option value="Sent">Sent</mat-option>
                    <mat-option value="Failed">Failed</mat-option>
                </mat-select>
            </mat-form-field>
        </fieldset>
        <div class="form-buttons">
            <div class="filterButtons">
                <button class="submit-form-button" (click)="search()" #submitButton>Search</button>
                <button class="submit-form-button cancel-button no-margin" (click)="resetFilters()">Reset</button>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="tableContents">
        <ng-container #emailQueueContainer *ngTemplateOutlet="emailQueueTable; context: {data: emailMessages, headerVisible: true}"></ng-container>
        <mat-paginator #paginator [length]="this.totalRecords" [pageSize]="25" [hidden]="this.totalRecords === 0"
            [pageSizeOptions]="[10, 25, 50]" (page)="handlePageEvent($event)" ariaLabel=''></mat-paginator>
    </div>
</div>
<ng-template #emailQueueTable let-data="data" let-headerVisible="headerVisible">
    <app-no-results #noResults></app-no-results>

    <table mat-table class="fileList primary" [dataSource]="data" [hidden]="data.length === 0">
        <ng-container matColumnDef="Recipient">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Name">Recipient</th>
            <td mat-cell *matCellDef="let emailMessage">
                <a>{{emailMessage.toName}} &lt;{{emailMessage.toEmailAddress}}&gt;</a>
            </td>
        </ng-container>
        <ng-container matColumnDef="Subject">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Subject">Subject</th>
            <td mat-cell *matCellDef="let emailMessage">
                <a routerLink="/email-queue/view/{{emailMessage.emailMessageId}}">
                    {{emailMessage.subject}}
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="Created">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Status">Created</th>
            <td mat-cell *matCellDef="let emailMessage">
                {{ emailMessage.createdDate | date : "dd/MM/yyyy HH:mm" }}
            </td>
        </ng-container>
        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef class="roundedLeft" sortActionDescription="Sort by Status">Status</th>
            <td mat-cell *matCellDef="let emailMessage">
                <app-status-bubble [status]="emailMessage.status"></app-status-bubble>
            </td>
        </ng-container>
        <ng-container *ngIf="headerVisible">
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="tableHeader"></tr>
        </ng-container>
        <tr mat-row *matRowDef="let rightsadvisor; columns: columnsToDisplay"></tr>
    </table>
    <div class="clear"></div>
    <i class="fa fa-regular fa-trash-can"></i>
</ng-template>