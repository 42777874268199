    
    <div class="login-fail toast-no-padding" >
        <img src="/assets/normal_u30.svg">
        <span>
          <h2>Password Expiration<button class="cross" (click)="closesnackbar()"></button></h2>
          <p>{{message}}</p>
          
          <div class="form-buttons">
            <button class="submit-form-button black-bg" (click)="changePassword()">Change Password</button>
          </div>
        </span>
       
      </div>
