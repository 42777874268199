import { Component, OnInit, Query } from '@angular/core';
import { DesignatedFacilityService } from 'src/app/services/designated-facility.service';
import { DesignatedFacility, defaultValues } from 'src/app/components/designated-facility/designated-facility';
import { Router, ɵafterNextNavigation } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CityService } from 'src/app/services/city-service';
import { HealthRegionService, HealthRegion, HealthAuthority } from 'src/app/services/healthregion-service';
import { CaseFileCount } from 'src/app/components/case-file/case-file-count';
import { CaseFileService } from 'src/app/services/case-file.service';
import { DomSanitizer } from '@angular/platform-browser'
import { ToastService } from 'src/app/services/toast-service.service';
import { UnitService } from 'src/app/services/unit-service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-editfacility',
	templateUrl: './editfacility.component.html',
	styleUrls: ['./editfacility.component.scss']
})
export class EditDesignatedFacilityComponent implements OnInit {
	designatedFacility: DesignatedFacility = defaultValues();
	facilityid = 0;
	cityList: any = [];
	healthAuthorities: HealthAuthority[] = [];
	healthRegions: HealthRegion[] = [];
	currentHealthAuthority: HealthAuthority = {} as HealthAuthority;
	cityVersionId: number = 0;
	closedCaseFileCount: number = 0;
	openCaseFileCount: number = 0;
	filteredResponse: CaseFileCount[] = [];
	aboutThisRecordLines: string[] = [];
	deactivateButton: boolean = false;
	checkboxFlag: boolean = false;
	generatecodeButton: boolean = false;
	totalUnits: number = 0;

	constructor(
		private designatedFacilityService: DesignatedFacilityService,
		private router: Router,
		private route: ActivatedRoute,
		private cityService: CityService,
		private healthRegionService: HealthRegionService,
		private caseFileService: CaseFileService,
		private unitService: UnitService,
		private sanitizer: DomSanitizer,
		private toastService: ToastService,
		private authservice:AuthService) { }

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.facilityid = params['id'];
			// this.designatedFacility.facilityName = decodeURIComponent(params['name']);
			// this.designatedFacility.healthAuthorityVersionId = +params['healthAuthorityVersionID'];
			// this.designatedFacility.healthRegionVersionId = +params['healthregionversionid'];
			// this.cityVersionId = +params['cityversionid']
		});

		this.healthRegionService.getHealthRegions().subscribe({
			next: (data) => { this.healthRegions = data },
			error: (err) => console.log(err),
			complete: () => { }
		});

		this.healthRegionService.getHealthAuthorities().subscribe({
			next: (data) => { this.healthAuthorities = data; },
			error: (error) => { console.log(error); },
			complete: () => { }
		});

		this.cityService.getCities().subscribe({
			next: (data) => {
				this.cityList = data;
			},
			error: (err) => console.log(err),
			complete: () => { }
		});

		this.designatedFacilityService.getFacility(this.facilityid).subscribe({
			next: (data) => {
				this.designatedFacility = data;
		},
			error: (err) => console.log(err),
			complete: () => {
				this.setCurrentHealthAuthority();
			}
		});

		// This isn't an ideal way to get the total units - WHEN we refactor this page to not be awful and actually
		// pull the Facility info via api rather than querystring, this can be gutted.
		this.unitService.getFilteredUnitbyFacility(this.facilityid).subscribe({
			next: (data) => { this.totalUnits = data.filter(x => x.isActive).length; },
			error: (err) => console.log("Error", err),
		});

		this.caseFileService.getCaseFileCountByFacility(this.facilityid).subscribe({
			next: (response) => {
				this.filteredResponse = response;
				this.closedCaseFileCount = response.filter(x => x.isCaseClosed)[0]?.caseFileCount == null ? 0 : response.filter(x => x.isCaseClosed)[0].caseFileCount;
				this.openCaseFileCount = response.filter(x => !x.isCaseClosed)[0]?.caseFileCount == null ? 0 : response.filter(x => !x.isCaseClosed)[0].caseFileCount;
				//console.log(response.filter(x => x.isCaseClosed)[0]?.caseFileCount == null ? 0 : response.filter(x => x.isCaseClosed)[0].caseFileCount);
				this.checkCaseFileCountConditions();
			},
			error: (e) => { console.log("Error getting case file count") },

		})
	}

	setCurrentHealthAuthority(): void {
		const currentHealthAuthority = this.healthAuthorities.find(item => item.healthAuthorityId == this.designatedFacility.healthAuthorityVersionId);
		if (currentHealthAuthority !== undefined)
			this.currentHealthAuthority = currentHealthAuthority;
	}

	setHealthRegion(): void {
		this.setCurrentHealthAuthority();

		if (this.currentHealthAuthority !== undefined) {
			if (this.currentHealthAuthority.requireHealthRegionSelection) {
				this.designatedFacility.healthRegionVersionId = 0;
			}
			else {
				const matchingRegion = this.healthRegions.find(item => item.healthRegionName == this.currentHealthAuthority.healthAuthorityName);
				if (matchingRegion !== undefined) {
					this.designatedFacility.healthRegionVersionId = matchingRegion.healthRegionId;
				}
			}
		}
	}

	decodeHtmlEntities(html: string): string {
		return this.sanitizer.bypassSecurityTrustHtml(html).toString();
	}

	updateFacility() {
		this.designatedFacility.userDetails.createdBy = this.designatedFacility.userDetails.updatedBy = this.authservice.getUserLoginData().username;
		this.designatedFacility.userDetails.createdByUserId = this.designatedFacility.userDetails.updatedByUserId = this.authservice.getUserLoginData().userDbId.toString();
		this.designatedFacilityService.updateFacility(this.designatedFacility).subscribe({
			next: (data) => {
				this.toastService.showMessage('The facility has been saved.')
				this.router.navigate(['/facilities'])
			},
			error: (error) => {
				console.log(error);
			},
			complete: () => {
			}
		});
	}

	checkCaseFileCountConditions(): void {
		if (this.openCaseFileCount > 0) {
			this.aboutThisRecordLines.push("This facility unit cannot be deactivated because there are open cases using this record.");
		}

		if (this.totalUnits > 0) {
			this.aboutThisRecordLines.push("This facility cannot be deactivated because there are active units allocated to this record.");
		}

		if (this.aboutThisRecordLines.length === 0) {
			this.aboutThisRecordLines.push("This facility can be deactivated.");
		}
	}

	compareCity(object1: any, object2: any) {
		return object1 && object2 && object1.cityVersionId == object2.cityVersionId;
	}

	compareHealthRegion(object1: any, object2: any) {
		return object1 && object2 && object1.healthregionversionid == object2.healthregionversionid;
	}

	disableFacility(): void {
		this.designatedFacilityService.disableFacility(this.designatedFacility.designatedFacilityVersionId).subscribe({
			next: () => {
				this.toastService.showMessage('The facility has been saved.')
				this.router.navigate(['/facilities'])
			},
			error: (err) => { console.log(err) }
		})
	}

	checkboxchecked(): void {
		this.generatecodeButton = this.checkboxFlag;

		if ((this.openCaseFileCount > 0 || this.totalUnits > 0) && this.checkboxFlag) {
			this.deactivateButton = false;
		}
		else if (this.openCaseFileCount == 0 && this.totalUnits == 0 && this.checkboxFlag) {
			this.deactivateButton = true;
		}
		else {
			this.deactivateButton = false;
		}
	}
}
