<div class="form-subsection-header">
	<div class="container">
		<h4>Rights Advisor Information</h4>
	</div>
</div>
<div class="form-subsection-content">
	<div class="container">
		<fieldset>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Rights Advisor Name</mat-label>
				<mat-select placeholder="Select One" name="drpRAsectionassignRA"
					[(ngModel)]="caseFile.rightsAdvisorVersionId" [disabled]="isDisabled"
					(selectionChange)="OnRaChange($event)">
					<mat-option *ngFor="let ra of raList" [value]="ra.rightsAdvisorVersionId">{{
						ra.advisorName}}</mat-option>
				</mat-select>
			</mat-form-field>
		</fieldset>
		<fieldset>
			<mat-form-field appearance="outline" class="w-100" floatLabel="always">
				<mat-label>IRAS Notes to Rights Advisor</mat-label>
				<textarea matInput class="form-control-textbox w-100" name="facilitynotessectionassignRA"
					[(ngModel)]="caseFile.irasNotesToRightsAdvisor" [disabled]="isDisabled"></textarea>
			</mat-form-field>
		</fieldset>

	</div>
</div>