import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardRaComponent } from './pages/dashboard-ra/dashboard-ra.component';

import { LoginComponent } from './pages/login/login.component';
import { DfLoginComponent } from './pages/df-login/df-login.component';
import { CmhaLoginComponent } from './pages/cmha-login/cmha-login.component';
import { RaLoginComponent } from './pages/ra-login/ra-login.component';
import { ChangePasswordComponent } from './components/changepassword/changepassword.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

import { FacilitiesComponent } from './pages/designatedfacility/list/facilities.component';
import { AddDesignatedFacilityComponent } from './pages/designatedfacility/add/designatedfacility.component';
import { EditDesignatedFacilityComponent } from './pages/designatedfacility/edit/editfacility.component';
import { DisableFacilityComponent } from './pages/designatedfacility/disable/disable-facility.component';
import { GenerateFacilityAccessCodeComponent } from './pages/designatedfacility/generatefacilityaccesscode/generatefacilityaccesscode.component';
import { AddUnitComponent } from './pages/designatedfacility/addunit/addunit.component';
import { EditUnitsComponent } from './pages/designatedfacility/editunits/editunits.component';
import { GenerateUnitAccessCodeComponent } from './pages/designatedfacility/generateunitaccesscode/generateunitaccesscode.component';
import { UnitAccessCodeComponent } from './pages/designatedfacility/unitaccesscode/unitaccesscode.component';

import { RightsAdvisorsComponent } from './pages/rightsadvisors/list/rightsadvisors.component';
import { AddRightsAdvisorComponent } from './pages/rightsadvisors/add/addrightsadvisor.component';
import { EditRightsAdvisorComponent } from './pages/rightsadvisors/edit/editrightsadvisor.component';
import { ActivateRightsAdvisorComponent } from './pages/rightsadvisors/activate/activate-ra.component';
import { DisableRightsAdvisorComponent } from './pages/rightsadvisors/disable/disablerightsadvisor.component';

import { EmailQueueComponent } from './pages/email-queue/email-queue.component';
import { EmailQueueViewComponent } from './pages/email-queue/email-queue-view.component';

import { TreatmentFormComponent } from './pages/treatment-form/treatment-form.component';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';

import { ClosedCasePdfDownloadComponent } from './pages/closed-case-pdf-download/closed-case-pdf-download.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';

import { PageNotFoundComponent } from './pages/page-not-found-component/page-not-found-component.component';
import { RequestForInformationFormComponent } from './pages/request-for-information-form/request-for-information-form.component';
import { RequestForInformationPublicFormComponent } from './components/request-for-information/request-for-information-public-form/request-for-information-public-form.component';
import { RequestForInformationThankyouComponent } from './components/request-for-information/request-for-information-thankyou/request-for-information-thankyou.component';
import { CasefilereportComponent } from './pages/reports/casefilereport/casefilereport.component';
import { ReportsHomeComponent } from './pages/reports/reports-home/reports-home.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'df-login/:casefileid', component: DfLoginComponent },
  { path: 'cmha-login', component: CmhaLoginComponent },
  { path: 'ra-login', component: RaLoginComponent },
  { path: 'dashboard-cmha', component: DashboardComponent },
  { path: 'dashboard-cmha/:tabid', component: DashboardComponent },
  { path: 'dashboard-ra', component: DashboardRaComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'forgot-password/:usertype', component: ForgotPasswordComponent },
  { path: 'reset-password/:guid', component: ResetPasswordComponent },
  { path: 'profile', component: ProfilePageComponent },
  { path: 'facilities', component: FacilitiesComponent },
  { path: 'addfacility', component: AddDesignatedFacilityComponent },
  { path: 'editfacility/:id', component: EditDesignatedFacilityComponent },
  { path: 'disablefacility/:id/:name/:city', component: DisableFacilityComponent },
  { path: 'generatecode/:id/:name/:city', component: GenerateFacilityAccessCodeComponent },
  { path: 'rights-advisors', component: RightsAdvisorsComponent },
  { path: 'add-rights-advisor', component: AddRightsAdvisorComponent },
  { path: 'edit-rights-advisor/:id', component: EditRightsAdvisorComponent },
  { path: 'activate-ra/:guid/:email', component: ActivateRightsAdvisorComponent },
  { path: 'disable-rights-advisor/:id/:name', component: DisableRightsAdvisorComponent },
  { path: 'addunit/:facilityid', component: AddUnitComponent },
  { path: 'unitaccesscode/:id', component: UnitAccessCodeComponent },
  { path: 'editunit/:id', component: EditUnitsComponent },
  { path: 'generateunitcode/:id', component: GenerateUnitAccessCodeComponent },
  { path: 'closedcasepdfdownload/:casefileid', component: ClosedCasePdfDownloadComponent },
  { path: 'email-queue', component: EmailQueueComponent },
  { path: 'email-queue/view/:id', component: EmailQueueViewComponent },
  { path: 'treatment-form/view/:casefileid', component: TreatmentFormComponent, data: { action: 'view' } },
  { path: 'treatment-form/create', component: TreatmentFormComponent, data: { action: 'create' } },
  { path: 'thankyou/:casefileid', component: ThankyouComponent },
  { path: 'request-for-information-form/create', component: RequestForInformationFormComponent, data: { action: 'create' } },
  { path: 'request-for-information-form/view/:formid', component: RequestForInformationFormComponent, data: { action: 'view' } },
  { path: 'reachout-form', component: RequestForInformationPublicFormComponent },
  { path: 'reachout-form-thankyou', component: RequestForInformationThankyouComponent },
  {path:'case-file-report',component:CasefilereportComponent},
  {path:'report-home',component:ReportsHomeComponent},
  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
  // This catches anything that falls through the routing table
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
