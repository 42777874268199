<app-authorize></app-authorize>
<app-topnav></app-topnav>

<div class="page-heading"><div class="container"><h1>Dashboard</h1></div></div>
<div class="container">
  <div class="content" role="main">
    <app-case-file-list [usertype]="usertype"></app-case-file-list>
  </div>
</div>

<app-footer></app-footer>