<app-authorize></app-authorize>
<app-topnav></app-topnav>

<div class="page-heading">
	<div class="container">
		<h1>Request For Rights Advice in Facility</h1>
	</div>
</div>

<div class="content" role="main">
	<app-intake-form></app-intake-form>
</div>

<app-footer></app-footer>